import { useContext } from 'react';
import { media, styled } from '@nx-kit/styling';
import { useQuery } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { DomainContext } from 'contexts/domain/DomainContext';
import { BreakpointContext } from 'contexts/breakpoint/BreakpointContext';
import { formatDateForQuery } from 'helpers/date';
import getHeadingTag from 'helpers/getHeadingTag';
import { fetchEvents } from 'prismic/api/events';
import { useLinkResolver } from 'prismic/helpers/useLinkResolver';
import { mapEdgeItemToEventBoxItem } from 'components/molecules/EventSliderItem/EventSliderItem.helper';
import EventSliderItem from 'components/molecules/EventSliderItem/EventSliderItem';
import Container from 'components/layout/Grid/Container';
import Slider from 'components/molecules/Slider/Slider';
import Column from 'components/layout/Grid/Column';
import { Heading } from 'components/atoms/Heading';
import Link from 'components/atoms/Link/Link';
import { Loading } from 'components/atoms/Loading';
import { EventSliderProps } from './EventSlider.types';
import getHeadingSkin from 'helpers/getHeadingSkin';

const HeadingStyled = styled(Heading)`
  margin: 0;
`;

const HeadlineCol = styled(Column)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px;

  ${media('md')} {
    margin: 0 0 20px;
  }
`;

const ColumnLinkStyled = styled(Column)`
  margin: 0;
`;

const StyledMobileLink = styled(Link)`
  font-size: ${({ theme }) => theme.global.fontSize.medium}px;
`;

const Wrapper = styled(Container)`
  overflow: hidden;
  margin-bottom: -30px;
  padding-bottom: 30px;
`;

export const EventSlider = ({ className, primary }: EventSliderProps) => {
  const { t } = useTranslation('common');

  const { headline = null, link = null, link_text = '' } = primary;
  const hasHeadline = headline?.[0]?.text || link;

  const bp = useContext(BreakpointContext);
  const isMobile = bp === 'xs' || bp === 'sm';

  const { locale } = useLinkResolver();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const fetchEventsQuery = async () => {
    const today = formatDateForQuery(new Date());
    const query = `?startDate=${today}&locale=${locale}`;
    return fetchEvents({ domain, query });
  };

  const { data, error, isLoading } = useQuery(['events'], fetchEventsQuery);

  const getError = (error: unknown): string => {
    console.error('[EventSlider]', error);
    return t('apiErrors.errorDefaultMessage');
  };

  const items =
    data?.data?.items.slice(0, 9).map((edgeItem) => mapEdgeItemToEventBoxItem(locale, edgeItem)) ??
    [];

  return (
    <div className={className}>
      <Wrapper>
        {hasHeadline && (
          <HeadlineCol span={12}>
            {headline?.[0]?.text && (
              <HeadingStyled
                skin={getHeadingSkin(headline[0].type)}
                elementType={getHeadingTag(headline[0].type)}
              >
                {headline[0].text}
              </HeadingStyled>
            )}
            {link && !isMobile && (
              <Link skin="tertiaryBig" url={link}>
                {link_text || link.url}
              </Link>
            )}
          </HeadlineCol>
        )}
        <Column span={12}>
          {error && <div>{getError(error)}</div>}
          {isLoading && <Loading />}

          <Slider
            slidesPerView={{
              xs: 1.05,
              sm: 1.5,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
              xxxl: 3,
            }}
            showOverflow
          >
            {items.map((item, index) => (
              <EventSliderItem
                key={`schedule-slider-item-${index}`}
                link={item.link}
                image={item.image}
                text={item.text}
                date={item.date}
                time={item.time}
              />
            ))}
          </Slider>
        </Column>
        <ColumnLinkStyled span={12}>
          {link && isMobile && (
            <StyledMobileLink skin="tertiaryBig" url={link}>
              {link_text || link.url}
            </StyledMobileLink>
          )}
        </ColumnLinkStyled>
      </Wrapper>
    </div>
  );
};
