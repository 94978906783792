import { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useQuery } from '@tanstack/react-query';
import { fetchTagesplan } from 'prismic/api/tagesplan';
import { useLinkResolver } from 'prismic/helpers/useLinkResolver';
import { DomainContext } from 'contexts/domain/DomainContext';
import { Heading } from 'components/atoms/Heading';
import { Loading } from 'components/atoms/Loading';
import Container from 'components/layout/Grid/Container';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';
import { formatDateForQuery } from 'helpers/date';
import { NextEventsHandwerkProps } from './NextEventsHandwerk.types';
import {
  ColumnStyled,
  DayContainer,
  InfoRow,
  IconStyled,
  IconWrapperStyled,
  TodayStyled,
  ComposedLinkStyled,
} from './NextEventsHandwerk.styles';

export const NextEventsHandwerk: React.FC<NextEventsHandwerkProps> = ({ primary, className }) => {
  const { t } = useTranslation('common');
  const { locale, linkResolver, documentRelations } = useLinkResolver();
  const {
    state: { domain },
  } = useContext(DomainContext);
  const eventId = primary.linked_tagesplanitem?.id ?? null;
  // only fetch data when link exists
  const enabled = !!eventId && primary.linked_tagesplanitem?.isBroken === false;

  const { data, error, isLoading } = useQuery(
    ['fetchTagesplanItems', eventId],
    () => {
      const date = formatDateForQuery(new Date());
      const query = `?eventId=${eventId}&inFuture=true&limit=3&date=${date}&locale=${locale}`;
      return fetchTagesplan({ domain, query });
    },
    { enabled }
  );

  if (!enabled) return null;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const urlToHouse = (houseNr?: number | null) => {
    if (!houseNr) {
      return '/';
    }
    return linkResolver(
      { link_type: 'Document', uid: houseNr.toString(), lang: locale },
      documentRelations
    );
  };

  const dateString = new Date().toLocaleDateString(locale, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });

  if (data) {
    const { items } = data.data;

    return (
      <Container className={className}>
        <ColumnStyled start={{ xs: 1, lg: 3 }} span={{ xs: 12, lg: 8 }}>
          <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
            {t('nextShowings')}
          </Heading>

          <TodayStyled>{`${t('today')}, ${dateString}`}</TodayStyled>

          {items &&
            items.map((item, index) => {
              return (
                <DayContainer key={`termin_${index}`}>
                  {item.termin?.timeslot && (
                    <InfoRow>
                      <IconWrapperStyled>
                        <IconStyled name="CLOCK" />
                      </IconWrapperStyled>
                      <div>{item.termin.timeslot}:&nbsp; </div>
                    </InfoRow>
                  )}
                  {item.termin?.location && (
                    <ComposedLinkStyled href={urlToHouse(item.termin.location?.house_nr)}>
                      <InfoRow>
                        <IconWrapperStyled>
                          <IconStyled name="MAPCURSOR" />
                        </IconWrapperStyled>
                        <div>
                          {`${item.termin.location?.house_nr ? t('houseNr') : ''} ${
                            item.termin.location?.house_nr
                          }, ${item.termin.location?.name}`}
                        </div>
                      </InfoRow>
                    </ComposedLinkStyled>
                  )}
                </DayContainer>
              );
            })}
        </ColumnStyled>
      </Container>
    );
  }

  return null;
};
