import { FormSuccessNotificationProps } from './FormSuccessNotification.types';
import Icon from 'components/atoms/Icon/Icon';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';
import useTranslation from 'next-translate/useTranslation';
import {
  ButtonStyled,
  HeadingStyled,
  SuccessText,
  TextAndIcons,
} from './FormSuccessNotification.styles';
import { RichText } from 'components/atoms/RichText';
import { useEffect, useRef } from 'react';

export const FormSuccessNotification: React.FC<FormSuccessNotificationProps> = ({
  onClickReturn,
  titleNotification,
  textNotification,
}) => {
  const { t } = useTranslation('common');
  const div = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    // TODO: parent.parent.parent... ersetzen. Vllt. in der Forms.tsx in der section eine ID einfügen und dann zu der hochspringen
    div?.current?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.scrollIntoView(
      { behavior: 'smooth', block: 'start' }
    );
  }, []);

  return (
    <>
      <TextAndIcons ref={div}>
        <Icon name="CHECKCIRCLEDICON" />
        <HeadingStyled skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
          {titleNotification?.[0]?.text}
        </HeadingStyled>
      </TextAndIcons>
      <SuccessText>
        <RichText rich_text={textNotification} />
      </SuccessText>
      <ButtonStyled onClick={onClickReturn} skin="primary">
        {t('forms.backAndReset')}
      </ButtonStyled>
    </>
  );
};
