import { ResponseCourseItem } from 'pages/api/courseApi.types';
import { KursSliderItemProps } from './KursSliderItem.types';

export const mapKursSliderItem = (
  locale: string,
  item: ResponseCourseItem
): KursSliderItemProps => {
  let link = null;

  if (item._meta.uid) {
    link = {
      link_type: 'Document',
      uid: item._meta.uid,
      lang: locale,
    };
  }

  let mappedImage;

  if (item.image && item.image.thumbnail) {
    const { thumbnail } = item.image;
    mappedImage = { ...thumbnail };
  }

  return {
    text: item.title || '',
    image: mappedImage,
    slot1: item.date || '',
    slot2: item.time || '',
    link,
  };
};
