import { PrismicExternalLink, PrismicLink } from 'prismic/types';

export enum VideoSize {
  small = 'small',
  big = 'big',
}

export interface VideoYoutubeSlice {
  youtube_link: PrismicLink | PrismicExternalLink;
  video_size: VideoSize.small | VideoSize.big;
}

export interface VideoYoutubeProps {
  className?: string;
  sliceName?: string;
  primary: VideoYoutubeSlice;
}

export interface VideoYoutubeMocks {
  [key: string]: VideoYoutubeSlice;
}
