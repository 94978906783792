import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import { DefaultForm } from 'components/molecules/DefaultForm';
import Column from 'components/layout/Grid/Column';
import { FormColumn, FormError, InputSubmit } from 'components/organisms/FormsSlice/Forms/Forms';
import { GoennerFlmFormInputTypes, FormParentProps } from './GoennerFlmForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { Wrapper } from './GoennerFLMForm.styles';
import { RadioInput } from 'components/atoms/RadioInput';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { DomainContext } from 'contexts/domain/DomainContext';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';

export const GoennerFlmForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<GoennerFlmFormInputTypes>();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: GoennerFlmFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormColumn span={12}>
                <Wrapper>
                  <RadioInput
                    id="patronageSinglePerson"
                    name="patronage"
                    register={register}
                    translation="goennerForm.labels.patronage.singlePerson"
                    value="Einzelperson"
                  />
                  <RadioInput
                    id="patronageFamily"
                    name="patronage"
                    register={register}
                    translation="goennerForm.labels.patronage.family"
                    value="Familie"
                  />
                  <RadioInput
                    id="patronageCompany"
                    name="patronage"
                    register={register}
                    translation="goennerForm.labels.patronage.company"
                    value="Firma"
                    validationOptions={validationOptions.patronage}
                  />
                </Wrapper>
                {errors.patronage && isSubmitted && (
                  <FormError className="patronageError">
                    {t('goennerForm.errors.patronageRequiredFLM')}
                  </FormError>
                )}
              </FormColumn>
              <DefaultForm
                errors={errors}
                register={register}
                isSubmitted={isSubmitted}
                isBusiness={true}
              />
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('goennerForm.labels.familyConditions')} />
              </FormColumn>
              <input type="hidden" value="15-Goenner-FLM" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
