import { media, styled } from '@nx-kit/styling';
import Column from 'components/layout/Grid/Column';
import Icon from 'components/atoms/Icon/Icon';
import { ComposedLink } from 'components/atoms/Link/Link';

export const ColumnStyled = styled(Column)`
  background-color: ${(props) => props.theme.global.color.gray50};
  border-radius: 8px;
  padding: 28px;
  ${media('md')} {
    padding: 52px;
  }
`;
export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.global.color.gray600};
  }
`;

export const InfoRow = styled.div`
  display: flex;
`;

export const TodayStyled = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;

export const ComposedLinkStyled = styled(ComposedLink)`
  color: ${(props) => props.theme.global.color.gray900};
  text-decoration: underline;
`;

export const IconWrapperStyled = styled.div`
  width: 35px;
  min-width: 35px;
  text-align: center;
`;

export const IconStyled = styled(Icon)`
  color: ${(props) => props.theme.global.color.primary};
  width: 24px;
  height: 24px;
  margin-right: 4px;
  margin-bottom: -5px;
`;
