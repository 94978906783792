import { media, styled } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';
import Column from 'components/layout/Grid/Column';
import Container from 'components/layout/Grid/Container';

export const TitleBox = styled(Column)<{ theme: Theme }>`
  position: relative;
  z-index: ${({ theme }) => theme.global.zIndex.one};
  align-self: start !important;

  padding: 24px 28px;
  background-color: ${({ theme }) => theme.global.color.white};
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  ${media('md')} {
    margin: 80px 0;
    padding: 44px 50px 56px;
    box-shadow: 0 10px 20px -13px rgba(0, 0, 0, 0.2);
  }
`;

export const AccordionContentContainer = styled(Container)`
  padding: 0;
`;

export const AccordionColumn = styled(Column)<{ theme: Theme }>`
  position: relative;
  padding: 85px 28px 60px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.global.color.gray50};
  border-radius: 0;
  margin: ${({ theme }) => `-70px -${theme.grid.sideGap.xs}px 0`};

  ${media('md')} {
    border-radius: 8px;
    margin: 0;
  }
`;
