import useTranslation from 'next-translate/useTranslation';
import { useQuery } from '@apollo/client';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';
import { Contact_PersonConnectionEdge } from 'types/generated/graphql';
import { allContactPersonsQuery } from 'prismic/graphQLQueries/allContactPersons';
import Container from 'components/layout/Grid/Container';
import Picture from 'components/atoms/Picture/Picture';
import { Heading } from 'components/atoms/Heading';
import { Loading } from 'components/atoms/Loading';
import {
  ColumnStyled,
  ContactInfo,
  ContactInfoItem,
  ContactPersonColumn,
  ContactPersonStyled,
  ContainerStyled,
  ContentWrapper,
  Divider,
  PersonalInfo,
  PictureWrapper,
  StyledCopytext,
  StyledHeading,
  StyledIcon,
  StyledLink,
  TitleBox,
} from './ContactElement.styles';
import { ContactElementEntry, ContactElementProps } from './ContactElement.types';
import { useRouter } from 'next/router';

export const ContactElement: React.FC<ContactElementProps> = ({ className, primary, items }) => {
  const { headline_alignment, headline, link, link_text } = primary;
  const { t } = useTranslation('common');
  const router = useRouter();

  const contactPersonUids = items.map(({ contact_person }) => contact_person.uid);

  const { data, loading, error } = useQuery(allContactPersonsQuery, {
    variables: { contactPersonUids, language: router.locale },
  });

  if (loading) return <Loading />;
  if (error) return <p>${error.message}</p>;

  const contactPersonsArray: ContactElementEntry[] =
    data.allContact_persons.edges?.map(
      (person: Contact_PersonConnectionEdge): ContactElementEntry => ({
        image: person.node.image,
        language: person.node.language || 'de',
        order: person.node.order || 99,
        first_name: person.node.first_name || '',
        last_name: person.node.last_name || '',
        job_description: person.node.job_description || '',
        phone_number: person.node.phone_number || '',
        email_address: person.node.email_address || '',
      })
    ) ?? [];
  // @ts-ignore

  contactPersonsArray.sort((a, b) => a.order - b.order);

  return (
    <Container className={className}>
      <TitleBox
        start={
          headline_alignment === 'right'
            ? { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }
            : { xs: 1, sm: 1, md: 11, lg: 9, xl: 9, xxl: 9 }
        }
        span={
          headline_alignment === 'right'
            ? { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }
            : { xs: 12, sm: 12, md: 10, lg: 4, xl: 4 }
        }
        row={1}
      >
        <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
          {headline?.[0]?.text ?? ''}
        </Heading>

        {(link.url || link.link_type === 'Document') && (
          <StyledLink skin="tertiaryBig" url={link}>
            {link_text || link.url}
          </StyledLink>
        )}
      </TitleBox>
      <ContactPersonColumn
        start={
          headline_alignment === 'right'
            ? { xs: 1, sm: 1, md: 3, lg: 3, xl: 3, xxl: 3 }
            : { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }
        }
        span={{ xs: 12, sm: 12, md: 12, lg: 10, xl: 10, xxl: 10 }}
        row={{ xs: 2, md: 1 }}
      >
        <ContainerStyled>
          <ColumnStyled
            start={headline_alignment === 'right' ? { xs: 1, md: 5 } : { xs: 1, md: 1 }}
            span={
              headline_alignment === 'right'
                ? { xs: 12, md: 10, lg: 10, xl: 10, xxl: 9 }
                : { xs: 12, md: 10, lg: 8, xl: 9, xxl: 8 }
            }
          >
            {contactPersonsArray.map((person: ContactElementEntry, index: number) => (
              <ContactPersonStyled key={`person-${index}`}>
                <ContentWrapper>
                  <PictureWrapper>
                    <Picture image={person.image} noMinHeight objectFit />
                  </PictureWrapper>
                  <PersonalInfo>
                    {(person.first_name || person.last_name) && (
                      <StyledHeading
                        skin={getHeadingSkin('heading4')}
                        elementType={getHeadingTag('heading4')}
                      >
                        {person.first_name} {person.last_name}
                      </StyledHeading>
                    )}

                    {person.job_description && (
                      <StyledCopytext textstring={person.job_description} />
                    )}
                    <ContactInfo>
                      {person.phone_number && (
                        <ContactInfoItem href={`tel:${person.phone_number}`}>
                          <StyledIcon name="PHONE" />
                          {person.phone_number}
                        </ContactInfoItem>
                      )}
                      {person.email_address && (
                        <ContactInfoItem href={`mailto:${person.email_address}`}>
                          <StyledIcon name="MAIL_ALTERNATIVE" />
                          {t('sendEmail')}
                        </ContactInfoItem>
                      )}
                    </ContactInfo>
                  </PersonalInfo>
                </ContentWrapper>
                <Divider />
              </ContactPersonStyled>
            ))}
          </ColumnStyled>
        </ContainerStyled>
      </ContactPersonColumn>
    </Container>
  );
};
