import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import { DefaultForm } from 'components/molecules/DefaultForm';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  FormError,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { LeihgabenFormInputTypes, FormParentProps } from './LeihgabenForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { Heading } from 'components/atoms/Heading';
import { RadioInput } from 'components/atoms/RadioInput';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { styled } from '@nx-kit/styling';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import { DomainContext } from 'contexts/domain/DomainContext';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const WrapperRBUsage = styled.div`
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }
  }
`;

export const LeihgabenForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<LeihgabenFormInputTypes>();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: LeihgabenFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('leihgabenForm.labels.usage.title')}
                </Heading>
                <WrapperRBUsage>
                  <RadioInput
                    id="useWithoutProfit"
                    name="usage"
                    register={register}
                    translation="leihgabenForm.labels.usage.useWithoutProfit"
                    value="öffentliche Nutzung ohne Gewinn"
                  />
                  <RadioInput
                    id="commercialUse"
                    name="usage"
                    register={register}
                    translation="leihgabenForm.labels.usage.commercialUse"
                    value="Kommerzielle Nutzung"
                  />
                  <RadioInput
                    id="personalUse"
                    name="usage"
                    register={register}
                    translation="leihgabenForm.labels.usage.personalUse"
                    value="Persönliche Nutzung"
                    validationOptions={validationOptions.usage}
                  />
                </WrapperRBUsage>
                {errors.usage && isSubmitted && (
                  <FormError className="usageError">
                    {t('leihgabenForm.errors.usageRequired')}
                  </FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="loansObject">
                  {t('leihgabenForm.labels.loansObject')}
                </HeadlineLabel>
                <InputTextArea
                  hasError={errors.loansObject !== undefined}
                  {...register('loansObject', { required: true })}
                  id="loansObject"
                  placeholder={t('leihgabenForm.placeholders.loansObject')}
                />
                {errors.loansObject !== undefined && (
                  <FormError>{t('leihgabenForm.errors.loansObjectRequired')}</FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="rentalPeriod">
                  {t('leihgabenForm.labels.rentalPeriod')}
                </HeadlineLabel>
                <InputTextArea
                  hasError={errors.rentalPeriod !== undefined}
                  {...register('rentalPeriod', { required: true })}
                  id="rentalPeriod"
                  placeholder={t('leihgabenForm.placeholders.rentalPeriod')}
                />
                {errors.rentalPeriod !== undefined && (
                  <FormError>{t('leihgabenForm.errors.rentalPeriodRequired')}</FormError>
                )}
              </FormColumn>
              <DefaultForm
                errors={errors}
                register={register}
                isSubmitted={isSubmitted}
                phoneLabel="leihgabenForm.labels.phone"
              />
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                    onClick={() => {
                      register('phone', { required: true });
                    }}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input type="hidden" value="09-Leihgaben" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
