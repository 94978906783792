import { styled } from '@nx-kit/styling';
import { SeparatorProps } from './Separator.types';

const Wrapper = styled.div<{ withLine: boolean }>`
  width: 100%;
  height: 1px;
  padding-top: ${({ withLine }) => (withLine ? '62px' : 0)};
  margin-bottom: ${({ withLine }) => (withLine ? '112px' : '70px')};
`;

const StyledHr = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.global.color.gray500};
`;

export const Separator: React.FC<SeparatorProps> = ({ className, primary }) => {
  const { horizontal_line } = primary;
  return (
    <Wrapper className={className} withLine={horizontal_line}>
      {horizontal_line && <StyledHr />}
    </Wrapper>
  );
};
