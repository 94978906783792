import { useContext } from 'react';
import { styled, media } from '@nx-kit/styling';
import useTranslation from 'next-translate/useTranslation';
import { useQuery } from '@tanstack/react-query';
import compact from 'lodash/compact';
import zipObject from 'lodash/zipObject';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { fetchTagesplan } from 'prismic/api/tagesplan';
import { LOCALES_MAP } from 'prismic/config';
import { DomainContext } from 'contexts/domain/DomainContext';
import { Heading } from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon/Icon';
import { Loading } from 'components/atoms/Loading';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';
import {
  GebaeudeEventTermin,
  GebaeudeEventsDayProps,
  NextEventsGebaeudeProps,
} from './NextEventsGebaeude.types';

const ColumnStyled = styled(Column)`
  background-color: ${(props) => props.theme.global.color.gray50};
  border-radius: 8px;
  padding: 28px;
  ${media('md')} {
    padding: 52px;
  }
`;

const IconWrapperStyled = styled.div`
  width: 35px;
  min-width: 35px;
  text-align: center;
`;

const IconStyled = styled(Icon)`
  color: ${(props) => props.theme.global.color.primary};
  width: 24px;
  height: 24px;
  margin-right: 4px;
  margin-bottom: -5px;
`;

const DayContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.global.color.gray600};
  padding-bottom: 15px;
  padding-top: 10px;
`;

const TerminContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const Time = styled.p`
  font-weight: 500;
  display: inline;
  white-space: nowrap;
`;

const Location = styled.p`
  display: inline;
  padding-top: 5px;
`;

const fetchTagesplanQuery = async (domain: string, houseNr: number) => {
  const query = `?houseNr=${houseNr}&inFuture=true&limit=3`;
  return fetchTagesplan({ domain, query });
};

const GebaeudeEventsDay: React.FC<GebaeudeEventsDayProps> = ({ termine, isToday }) => {
  const { t, lang } = useTranslation('common');
  const locale = Object.keys(LOCALES_MAP).find((key) => LOCALES_MAP[key] === lang);
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  };

  const dateString = termine?.[0]?.timeStamp?.toLocaleDateString(locale, dateOptions);

  return (
    <DayContainer>
      <Heading className="text" skin="500" elementType="div">
        {isToday && `${t('today')}, `}
        {dateString}
      </Heading>
      {termine.map((termin) => (
        <TerminContainer key={termin.timeStamp.toUTCString()}>
          <IconWrapperStyled>
            <IconStyled name="CLOCK" />
          </IconWrapperStyled>
          <Time>{termin.timeslot}:&nbsp; </Time>
          <Location>{termin.display_name}</Location>
        </TerminContainer>
      ))}
    </DayContainer>
  );
};

export const NextEventsGebaeude: React.FC<NextEventsGebaeudeProps> = ({ primary, className }) => {
  const { t } = useTranslation('common');

  const {
    state: { domain },
  } = useContext(DomainContext);
  const houseNr = primary.gebaeudenummer;
  // only fetch data when link exists
  const enabled = houseNr !== null;

  const { data, error, isLoading } = useQuery(
    ['fetchTagesplanItems', houseNr],
    () => fetchTagesplanQuery(domain, houseNr),
    { enabled }
  );

  if (!enabled) return null;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data) {
    const { items } = data.data;

    const termine = items.map((item) => ({
      timeStamp: new Date(item._dateToSort || ''),
      display_name: item.display_name,
      uid: item._meta.uid,
      ...item.termin,
    }));
    const days: string[] = uniq(compact(termine.map((item) => item?.date)));
    const termineByDay = zipObject(
      days,
      map(days, (day) => termine.filter((entry) => entry?.date === day) as GebaeudeEventTermin[])
    );

    const today = new Date().toISOString().split('T')[0];

    return (
      <Container className={className}>
        <ColumnStyled start={{ xs: 1, lg: 3 }} span={{ xs: 12, lg: 8 }}>
          <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
            {t('nextEvents')}
          </Heading>
          {map(termineByDay, (value, key) => (
            <GebaeudeEventsDay termine={value} isToday={today === key} />
          ))}
        </ColumnStyled>
      </Container>
    );
  }

  return null;
};
