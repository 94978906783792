import { media, styled } from '@nx-kit/styling';
import { InputText } from 'components/organisms/FormsSlice/Forms/Forms';

export const StyledInputText = styled(InputText)`
  &:focus {
    border-color: ${({ theme }) => theme.global.color.gray180};
  }
`;

export const WrapperText = styled.div`
  width: 100%;
  ${media('md')} {
    width: 66%;
  }
  > * {
    display: flex;
  }
`;

export const StyledFieldDescription = styled.p`
  margin: 24px 0 12px 12px;
  width: 100%;
`;
