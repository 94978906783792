import { TagesplanItem } from 'pages/api/tagesplanApi.types';
import { TagesplanSliderItemProps } from 'components/molecules/TagesplanSliderItem/TagesplanSliderItem.types';
import { format } from 'date-fns';

function convertToObjectToStartDateInput(obj: { date: any; timeslot: any }) {
  const { date, timeslot } = obj;

  const pattern = /^\d+:\d\d$/;

  if (pattern.test(timeslot)) {
    return {
      start: null,
      end: null,
    };
  }

  const [startTime, endTime] = timeslot.split(' - ');
  const [startHour, startMinute] = startTime.includes('.')
    ? startTime.split('.')
    : startTime.split(':');
  const [endHour, endMinute] = endTime.includes('.') ? endTime.split('.') : endTime.split(':');

  const [year, month, day] = date.split('-');

  try {
    const startDate = new Date(year, month - 1, day, startHour, startMinute);
    const endDate = new Date(year, month - 1, day, endHour, endMinute);

    return {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    };
  } catch (error) {
    console.log(error);
    return {
      start: null,
      end: null,
    };
  }
}

export const mapEdgeItemToTagesplanBoxItem = (
  locale: string,
  item: TagesplanItem
): TagesplanSliderItemProps => {
  let link = null;

  if (item._linkType && item._meta.uid) {
    link = {
      link_type: item._linkType,
      uid: item._meta.uid,
      lang: locale,
    };
  }

  const itemDateSt = item.termin?.date || '';
  const itemTimeSt = item.termin?.timeslot || '';

  const time = convertToObjectToStartDateInput({
    date: itemDateSt,
    timeslot: itemTimeSt,
  });

  let startTime;
  let endTime;

  if (time.end) {
    startTime = new Date(time.start);
    endTime = new Date(time.end);
  }

  return {
    text: item?.display_name || '',
    image: item.image,
    subtext:
      time.end && startTime && endTime
        ? `${format(startTime, 'kk:mm') + ' - ' + format(endTime, 'kk:mm')}`
        : null,
    link,
  };
};
