import { BreakpointKey, Breakpoints, LiteralOrBreakpoints } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';

const breakpointsOrdered: Array<BreakpointKey<Theme>> = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl',
];

export function getClosestBreakpoint<K>(
  value: LiteralOrBreakpoints<K, Theme>,
  breakpoint: BreakpointKey<Theme> = 'xs'
): K | undefined {
  if (typeof value !== 'object') {
    return value;
  }

  let returnValue: K | undefined = undefined;
  let valueHasBpKey = false;
  breakpointsOrdered.forEach((bp, index) => {
    if (value && bp in value) {
      valueHasBpKey = true;
      if (breakpointsOrdered.indexOf(breakpoint) >= index) {
        returnValue = (value as Breakpoints<K, Theme>)[bp];
      }
    }
  });

  if (!valueHasBpKey) {
    return value as K;
  }

  return returnValue;
}
