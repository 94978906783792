import uniqueId from 'lodash/uniqueId';
import { LiteralOrBreakpoints, media, styled } from '@nx-kit/styling';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import { Heading } from 'components/atoms/Heading';
import Teaser from 'components/molecules/Teaser/Teaser';
import { Theme } from 'themes/themeTypes';
import { TeaserSize, TeasersProps } from './Teasers.types';
import useTranslation from 'next-translate/useTranslation';

const HeadlineCol = styled(Column)`
  margin: 0 0 -10px 0;

  ${media('md')} {
    margin: 0 0 -5px 0;
  }
`;

const StyledContainer = styled(Container)`
  grid-row-gap: 15px;
`;

const aspectRatios: { [K in TeaserSize]: LiteralOrBreakpoints<number, Theme> } = {
  small: {
    xs: 1,
  },
  medium: {
    xs: 2 / 4,
    md: 1 / 2,
  },
  big: {
    xs: 2 / 3,
    md: 5 / 4,
  },
};

const columns: { [K in TeaserSize]: LiteralOrBreakpoints<number, Theme> } = {
  small: {
    xs: 12,
    sm: 6,
    md: 3,
  },
  medium: {
    md: 6,
  },
  big: {
    xs: 12,
    md: 4,
  },
};

const Teasers: React.FC<TeasersProps> = ({
  className,
  primary: { headline, teaser_size },
  items,
}) => {
  const { t } = useTranslation('common');
  const linkLabel = t('links.discoverNow');
  const id = uniqueId();

  return (
    <>
      <StyledContainer className={className}>
        {headline.length > 0 && headline[0].text && (
          <HeadlineCol span={12}>
            <Heading skin="500" elementType="h2">
              {headline[0].text}
            </Heading>
          </HeadlineCol>
        )}
        {items.map((item, index) => (
          <Column
            span={columns[teaser_size]}
            // eslint-disable-next-line react/no-array-index-key
            key={`teaser-${id}-${index}`}
          >
            <Teaser
              text={item.link_text}
              url={item.link}
              image={item.image}
              subtext={linkLabel}
              aspectRatio={aspectRatios[teaser_size]}
            />
          </Column>
        ))}
      </StyledContainer>
    </>
  );
};

export default Teasers;
