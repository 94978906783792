import uniqueId from 'lodash/uniqueId';
import useTranslation from 'next-translate/useTranslation';
import { styled, media } from '@nx-kit/styling';
import Picture from 'components/atoms/Picture/Picture';
import { Heading } from 'components/atoms/Heading';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import Link from 'components/atoms/Link/Link';
import Icon from 'components/atoms/Icon/Icon';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';
import { GastronomyOverviewProps } from './GastronomyOverview.types';

const ColumStyled = styled(Column)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
`;

const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  padding: 0;
  background-color: ${(props) => props.theme.global.color.white};
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms ${({ theme }) => theme.global.easing.snappy};
  &:hover {
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2);
  }

  ${media('md')} {
    flex-direction: row;
  }
`;

const ImageStyled = styled.div`
  width: 100%;
  height: 184px;

  & img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
  }

  ${media('md')} {
    width: 240px;
    min-height: 240px;
    height: 100%;
    & img {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0;
    }
  }
`;

const InfoBoxStyled = styled.div`
  padding: 34px;
  display: flex;
  flex-direction: column;
`;

const MoreLinkStyled = styled(Link)`
  margin-top: auto;
`;
const InfoRowStyled = styled.div`
  display: flex;
`;

const IconWrapperStyled = styled.div`
  width: 30px;
`;

const IconStyled = styled(Icon)`
  color: ${(props) => props.theme.global.color.primary};
  width: 24px;
  height: 24px;
  margin-right: 4px;
  margin-top: 2px;
`;

export const GastronomyOverview: React.FC<GastronomyOverviewProps> = ({
  className,
  sliceName,
  items,
  primary,
}) => {
  const { t } = useTranslation('common');
  const linkLabel = t('links.discoverNow');

  return (
    <>
      <Container className={className} data-slice-name={sliceName}>
        <Column span={12}>
          <Heading skin={getHeadingSkin('heading2')} elementType={getHeadingTag('heading2')}>
            {primary.title}
          </Heading>
        </Column>
      </Container>
      <Container className={className} data-slice-name={sliceName}>
        {items.map((item) => {
          return (
            <ColumStyled span={{ xs: 12, md: 12, lg: 6 }} key={uniqueId()}>
              <BoxStyled>
                <ImageStyled>
                  {item.image.dimensions && (
                    <Picture noMinHeight image={item.image} objectFit></Picture>
                  )}
                </ImageStyled>
                <InfoBoxStyled>
                  <Heading skin="500" elementType="h4">
                    {item.title}
                  </Heading>
                  <InfoRowStyled>
                    <IconWrapperStyled>
                      <IconStyled name="CLOCK" />
                    </IconWrapperStyled>
                    <div>{item.time}</div>
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <IconWrapperStyled>
                      <IconStyled name="MAPCURSOR" />
                    </IconWrapperStyled>{' '}
                    <div>{item.place}</div>
                  </InfoRowStyled>
                  {item.link_reservation && (
                    <MoreLinkStyled
                      url={item.link_reservation}
                      text={linkLabel}
                      title={linkLabel}
                      skin="tertiarySmall"
                    />
                  )}
                </InfoBoxStyled>
              </BoxStyled>
            </ColumStyled>
          );
        })}
      </Container>
    </>
  );
};
