import { styled } from '@nx-kit/styling';

export const WrapperRBProbate = styled.div`
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }
  }
`;
