import { styled, media } from '@nx-kit/styling';
import useTranslation from 'next-translate/useTranslation';
import uniqueId from 'lodash/uniqueId';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import { Heading } from 'components/atoms/Heading';
import { RichText } from 'components/atoms/RichText';
import Link from 'components/atoms/Link/Link';
import { JobsOverviewProps } from './JobsOverview.types';

const ColumStyled = styled(Column)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
`;

const JobBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 246px;
  border-radius: 8px;
  padding: 26px 30px 30px;

  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms ${({ theme }) => theme.global.easing.snappy};
  &:hover {
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2);
  }

  ${media('md')} {
    width: 416px;
    min-height: 290px;
  }
`;

const TitleStyled = styled(Heading)`
  margin-bottom: 10px;
`;

const DescriptionStyled = styled(RichText)``;

const MoreLinkStyled = styled(Link)`
  margin-top: auto;
`;

export const JobsOverview: React.FC<JobsOverviewProps> = ({ className, items, sliceName }) => {
  const { t } = useTranslation('common');
  const linkLabel = t('links.learnMore');

  return (
    <Container className={className} data-slice-name={sliceName}>
      {items.map((jobItem) => {
        let link;
        let linkTarget = '_self';

        // Links could be: Documents, internal or external Links
        if (jobItem.job_document?.url) {
          link = jobItem.job_document;
          linkTarget = '_blank';
        } else {
          link = jobItem.job_link;

          if (jobItem.job_link?.link_type === 'Web') {
            linkTarget = '_blank';
          }
        }

        return (
          <ColumStyled span={{ xs: 12, md: 6, lg: 4 }} key={uniqueId()}>
            <JobBoxStyled>
              <TitleStyled skin="500" elementType="h4">
                {jobItem.job_title}
              </TitleStyled>
              <DescriptionStyled rich_text={jobItem.job_description} />
              {link && (
                <MoreLinkStyled
                  url={link}
                  text={linkLabel}
                  title={linkLabel}
                  skin="tertiarySmall"
                  target={linkTarget}
                />
              )}
            </JobBoxStyled>
          </ColumStyled>
        );
      })}
    </Container>
  );
};
