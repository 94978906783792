import uniqueId from 'lodash/uniqueId';
import { styled } from '@nx-kit/styling';
import Link from 'components/atoms/Link/Link';
import { FooterLinksBlockProps } from 'components/organisms/Footer/Footer.types';

const LinkStyled = styled(Link)`
  color: ${(props) => props.theme.global.color.white};
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 284px;
`;

export const FooterLinksBlock: React.FC<FooterLinksBlockProps> = ({ items }) => {
  return (
    <Wrapper>
      {items &&
        items.map((item) => (
          <LinkStyled
            key={`footer-links_${uniqueId()}`}
            text={item['footer-link-text']}
            url={item['footer-link']}
          />
        ))}
    </Wrapper>
  );
};
