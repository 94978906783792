import { media, styled } from '@nx-kit/styling';
import Link from 'components/atoms/Link/Link';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.global.color.gray900};
  color: ${(props) => props.theme.global.color.white};
  align-items: flex-end;
  background-position: -25px 150px;
  background-image: url('/footer/footer-background.png');
  background-repeat: no-repeat;
  background-size: 587px auto;
  min-height: 450px;
  padding: 50px ${({ theme }) => theme.grid.sideGap.xs}px;
  margin-top: 80px;

  ${media('md')} {
    margin-top: 120px;
    padding: ${({ theme }) => theme.grid.sideGap.md}px;
    background-size: auto;
    background-position: 25% center;
  }
`;

export const FooterRowStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media('sm')} {
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      flex: 50%;

      &:not(:last-child) {
        margin-bottom: 60px;
      }
    }
  }

  ${media('lg')} {
    flex-direction: row;
    > * {
      flex: 1;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
`;

export const SecondRowStyled = styled.div`
  margin-top: 60px;
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media('sm')} {
    margin-top: 80px;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      flex: 50%;
    }
  }

  ${media('lg')} {
    flex-direction: row;
    margin-top: 0;
    > * {
      flex: none;
    }
  }
`;

export const Logo1Styled = styled.div`
  order: 2;
  overflow: hidden;
  height: 71px;
  margin-bottom: 40px;

  ${media('lg')} {
    margin-bottom: 0;
    margin-right: 87px;
  }

  ${media('lg')} {
    margin-left: auto;
    order: 2;
  }
`;

export const Logo2Styled = styled.div`
  order: 3;
  height: fit-content;
`;

export const Logo3Styled = styled.div`
  order: 1;
  height: fit-content;
  margin-bottom: 40px;
  ${media('lg')} {
    margin-bottom: 0;
    margin-right: 87px;
  }
`;

export const LinkStyled = styled(Link)`
  color: ${(props) => props.theme.global.color.white};
  font-weight: 400;

  &:not(:last-child) {
    margin-right: 25px;
  }

  ${media('lg')} {
    margin-right: 40px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const SecondaryLinksBlockStyled = styled.div`
  order: 4;
  display: flex;
  font-size: 11px;
  width: 100%;
  margin-top: 50px;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media('sm')} {
    justify-content: flex-start;
    font-size: 12px;
  }

  ${media('lg')} {
    order: 1;
    margin-top: 0;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }
`;
