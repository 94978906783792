import { media, styled } from '@nx-kit/styling';
import Link from 'components/atoms/Link/Link';
import { IconMap, IconType } from 'components/atoms/Icon/iconMap';
import { FooterSocialLink, FooterSocialLinksProps } from 'components/organisms/Footer/Footer.types';

const Wrapper = styled.div`
  margin-top: 40px;
  ${media('md')} {
    margin-top: 0;
  }
  ${media('lg')} {
    margin-top: 0;
    max-width: 150px;
  }
`;

const SocialHeadlineStyled = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
`;

const LinkStyled = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #303030;
  width: 50px;
  height: 50px;
  border-radius: 25px;

  color: white;

  ${media('lg')} {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  &:hover {
    background: ${(props) => props.theme.global.color.primary};
  }
`;

const SocialLinksStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
`;

function cmsIconNameToMap(iconName: string): IconType {
  if (iconName in IconMap) {
    return iconName as IconType;
  }
  return 'DEFAULTCHANNEL';
}

export const FooterSocialLinks: React.FC<FooterSocialLinksProps> = ({ primary, items }) => {
  return (
    <Wrapper>
      <SocialHeadlineStyled>{primary['social-links-headline']}</SocialHeadlineStyled>
      <SocialLinksStyled>
        {items.map((socialItem: FooterSocialLink) => {
          const socialName = socialItem['social-name'];
          return (
            <LinkStyled
              aria-label={socialName
                .replace(/_/g, ' ')
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')}
              url={socialItem['social-link']}
              key={`footer-social-${socialName}`}
              icon={cmsIconNameToMap(socialName)}
            ></LinkStyled>
          );
        })}
      </SocialLinksStyled>
    </Wrapper>
  );
};
