export enum FormsCategories {
  cat1 = '01-Kontakt',
  cat2 = '02-Ballenberg-Friends',
  cat3 = '03-Nachlass',
  cat4 = '04-Prospektversand',
  cat6 = '06-Schulen',
  cat7 = '07-Foerdervereine',
  cat8 = '08-Patenschaften',
  cat9 = '09-Leihgaben',
  cat10 = '10-TischreservationenIndividual',
  cat11 = '11-GruppenReservationen',
  cat12 = '12-Goenner-KBB',
  cat13 = '13-KursprogrammBestellung',
  cat14 = '14-GutscheinSchenken',
  cat15 = '15-Goenner-FLM',
  cat17 = '17-Sammlung',
  cat18 = '18-KursanmeldungSuccess',
  cat19 = '19-KursanmeldungWarteliste',
  cat20 = '20-Handwerkspatenschaft',
}
