import { getHost } from 'helpers/host';
import { BlogEntriesResponse } from 'pages/api/blogApi.types';
import isClient from 'helpers/isClient';

export interface FetchProps {
  domain: string;
  query: string;
}

export interface ApiFetchBlogResponse {
  data: BlogEntriesResponse;
}

export const fetchBlogs = async ({ domain, query }: FetchProps): Promise<ApiFetchBlogResponse> => {
  const url = `${getHost(domain)}/api/blogApi${query}`;

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'cache-control': 'no-cache',
    },
  };

  if (!isClient()) {
    // @ts-ignore
    options.headers.Authorization =
      'Basic ' +
      Buffer.from('ballenberg' + ':' + process.env.BASIC_AUTH_PASSWORD).toString('base64');
  }

  try {
    const blogsResponse = await fetch(url, options);
    return await blogsResponse.json();
  } catch (error) {
    console.info('🚨' + JSON.stringify({ url, options }, null, 2));
    throw { name: 'APiPrismicError', error };
  }
};
