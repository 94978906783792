import { RichText } from 'components/atoms/RichText';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import { RichTextSliceProps } from './RichTextSlice.types';

export const RichTextSlice: React.FC<RichTextSliceProps> = ({ primary }) => {
  return (
    <Container>
      <Column start={{ xs: 1, lg: 3 }} span={{ xs: 12, lg: 8 }}>
        <RichText {...primary} />
      </Column>
    </Container>
  );
};
