import { css, styled } from '@nx-kit/styling';

export const Wrapper = styled.div<{ hasError?: boolean }>`
  position: relative;
  padding: 16px 20px;
  margin: 6px 0;
  user-select: none;
  z-index: ${({ theme }) => theme.global.zIndex.dropdowns};
  background-color: ${(props) => props.theme.global.color.white};
  border-radius: 4px;
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.global.color.primary : theme.global.color.gray500)};
`;

export const ArrowStyled = styled.i<{ isOpen: boolean }>`
  pointer-events: none;
  &:after {
    content: '';
    will-change: transform;
    transition: transform 300ms ${({ theme }) => theme.global.easing.default};
    position: absolute;
    transform-origin: center;
    right: 20px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid ${({ theme }) => theme.global.color.primary};
    border-right: 2px solid ${({ theme }) => theme.global.color.primary};

    ${({ isOpen }) =>
      isOpen
        ? css`
            top: calc(50% - 2px);
            transform: rotate3d(1, 0, 0, 0) rotate3d(0, 0, 1, -135deg);
          `
        : css`
            top: calc(50% - 7px);
            transform: rotate3d(1, 0, 0, 0) rotate3d(0, 0, 1, 45deg);
            border-bottom: 2px solid ${({ theme }) => theme.global.color.gray900};
            border-right: 2px solid ${({ theme }) => theme.global.color.gray900};
          `};
  }
`;

export const CurrentStyled = styled.div`
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.global.color.gray180};

  ${ArrowStyled} {
    margin-left: 30px;
  }
`;

export const SelectContainerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SelectStyled = styled.select`
  appearance: none;
  border: none;
  border-radius: 0;
  font-size: 1em;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  color: transparent;
  padding: 20px;

  option {
    color: ${({ theme }) => theme.global.color.gray180};
    background-color: ${(props) => props.theme.global.color.white};
    text-align: left;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.global.color.primary};
    }
  }

  option:first-child {
    display: none;
  }
`;
