import Container from 'components/layout/Grid/Container';
import { VideoYoutubeProps, VideoSize } from './VideoYoutube.types';
import { VideoWrapper, ColumnStyled } from './VideoYoutube.styles';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

export const VideoYoutube: React.FC<VideoYoutubeProps> = ({ className, primary, sliceName }) => {
  if (!primary.youtube_link?.url) {
    return null;
  }

  const isBig = primary.video_size === VideoSize.big;

  return (
    <Container className={className} data-slice-name={sliceName}>
      <ColumnStyled start={{ xs: 1, lg: isBig ? 1 : 3 }} span={{ xs: 12, lg: isBig ? 12 : 8 }}>
        <ReactPlayer
          config={
          primary.youtube_link.url.includes('vimeo') ? {
            vimeo: {
              playerOptions: {
                controls: 1,
              },
            },
          } : {
            youtube: {
              playerVars: {
                controls: 1,
              },
            },
          }
        }
          height="100%"
          url={primary.youtube_link.url}
          width="100%"
          wrapper={VideoWrapper}
        />
      </ColumnStyled>
    </Container>
  );
};
