import { styled, createGlobalStyle } from '@nx-kit/styling';
import { forwardRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Icon from 'components/atoms/Icon/Icon';
import { format } from 'date-fns';
import { de, enGB, it, fr } from 'date-fns/locale';
import { FormDatePickerProps, RefPropsType } from './FormDatePicker.types';
import useTranslation from 'next-translate/useTranslation';
import { FormError } from 'components/organisms/FormsSlice/Forms/Forms';
import React from 'react';

const Wrapper = styled.div<{ hasError?: boolean }>`
  label {
    display: block;
    padding: 14px 20px;
    margin: 6px 0;
    border-radius: 4px;
    border: 1px solid
      ${({ hasError, theme }) =>
        hasError ? theme.global.color.primary : theme.global.color.gray500};
    color: ${(props) => props.theme.global.color.gray900};
    cursor: pointer;

    &::placeholder {
      color: ${({ theme }) => theme.global.color.gray180};
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.global.color.gray900};
      transition: border-color 0.6s ease;
    }
  }
`;

const IconStyled = styled(Icon)`
  position: absolute;
  right: 5px;
  width: 28px;
  height: 28px;
  color: ${(props) => props.theme.global.color.primary};
`;

export const DatePickerGlobalStyle = createGlobalStyle`
  .datepicker-popper {
    z-index:${(props) => props.theme.global.zIndex.datePicker};
  }
`;

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  placeholder,
  onChange,
  hasError,
  errorMessageRequired,
  validationOptions,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const stateDateFormat = 'yyyy-MM-dd';
  const displayDateFormat = 'dd.MM.yyyy';
  const { t, lang } = useTranslation('common');

  if (lang === 'de') {
    registerLocale('de', de);
  } else if (lang === 'fr') {
    registerLocale('fr', fr);
  } else if (lang === 'it') {
    registerLocale('it', it);
  } else {
    registerLocale('en', enGB);
  }

  const CustomInputComponent = forwardRef<HTMLLabelElement, RefPropsType>(
    ({ value, onClick }, ref) => {
      let selected;
      let today;

      if (value) {
        selected = format(new Date(value), displayDateFormat);
        today = format(new Date(), displayDateFormat);
      }

      return (
        <Wrapper>
          <label onClick={onClick} ref={ref}>
            {selected === today ? t('today') : selected}
            <IconStyled name="CALENDAR" />
          </label>
        </Wrapper>
      );
    }
  );
  CustomInputComponent.displayName = 'CustomInput';

  return (
    <>
      <DatePicker
        wrapperClassName="formDatePicker"
        popperClassName="datepicker-popper"
        selected={selectedDate}
        locale={lang}
        customInput={<CustomInputComponent />}
        dateFormat={stateDateFormat}
        placeholderText={t(placeholder!)}
        onChange={(date: Date, event) => {
          setSelectedDate(date);
          if (onChange) {
            onChange(date, event);
          }
        }}
        {...props}
      />
      {hasError && errorMessageRequired && <FormError>{t(errorMessageRequired)}</FormError>}
    </>
  );
};
