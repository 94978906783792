import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import Column from 'components/layout/Grid/Column';
import { FormColumn, FormError, InputSubmit } from 'components/organisms/FormsSlice/Forms/Forms';
import { NewsletterFormInputTypes, FormParentProps } from './NewsletterForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { newsletterSubmitHandler } from './Newsletter.helper';
import { TextInput } from 'components/atoms/TextInput';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { useContext, useState } from 'react';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { DomainContext } from 'contexts/domain/DomainContext';
import { Wrapper } from './NewsletterForm.styles';
import { RadioInput } from 'components/atoms/RadioInput';
import { Heading } from 'components/atoms/Heading';
import { SalutationEnum } from 'helpers/forms/forms.types';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

export const NewsletterForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<NewsletterFormInputTypes>();

  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);

  const submitHandler = async (data: NewsletterFormInputTypes) => {
    try {
      const response = await newsletterSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
      console.log(error);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('newsletterForm.labels.newsletterSubscription')}
                </Heading>
                <Wrapper>
                  <RadioInput
                    id="salutationFemale"
                    name="salutation"
                    register={register}
                    translation="contactForm.labels.salutation.female"
                    value={SalutationEnum.female}
                  />
                  <RadioInput
                    id="salutationMale"
                    name="salutation"
                    register={register}
                    translation="contactForm.labels.salutation.male"
                    value={SalutationEnum.male}
                    validationOptions={validationOptions.salutation}
                  />
                </Wrapper>
                {errors.salutation && isSubmitted && (
                  <FormError className="salutationError">
                    {t('contactForm.errors.salutationRequired')}
                  </FormError>
                )}
              </FormColumn>

              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="contactForm.errors.firstNameRequired"
                  hasError={errors.firstName !== undefined}
                  label="newsletterForm.labels.firstName"
                  name="firstName"
                  placeholder="newsletterForm.placeholders.firstName"
                  register={register}
                  validationOptions={validationOptions.firstName}
                />
              </FormColumn>

              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="contactForm.errors.lastNameRequired"
                  hasError={errors.lastName !== undefined}
                  label="newsletterForm.labels.lastName"
                  name="lastName"
                  placeholder="newsletterForm.placeholders.lastName"
                  register={register}
                  validationOptions={validationOptions.lastName}
                />
              </FormColumn>

              <FormColumn span={12}>
                <TextInput
                  errorMessagePattern="newsletterForm.errors.eMailInvalid"
                  errorMessageRequired="newsletterForm.errors.eMailRequired"
                  hasError={errors.eMail !== undefined}
                  label="newsletterForm.labels.eMail"
                  name="eMail"
                  placeholder="newsletterForm.placeholders.eMail"
                  register={register}
                  validationOptions={validationOptions.eMail}
                />
              </FormColumn>

              <input type="hidden" value={lang} {...register('locale')} />
              <input type="hidden" value={domain} {...register('domain')} />

              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
            </form>
          </Column>
        </>
      )}
    </>
  );
};
