import { styled } from '@nx-kit/styling';

export const Wrapper = styled.div`
  .rhap_main-controls-button {
    color: ${({ theme }) => theme.global.color.gray200};
  }

  .rhap_play-pause-button {
    font-size: 60px;
    width: 60px;
    height: 60px;

    &[aria-label='Pause'] {
      color: ${({ theme }) => theme.global.color.primary};
    }
  }
  .rhap_progress-filled {
    background-color: ${({ theme }) => theme.global.color.gray200};
  }

  .rhap_progress-filled,
  .rhap_progress-indicator,
  .rhap_volume-indicator {
    background-color: ${({ theme }) => theme.global.color.primary};
  }
`;
