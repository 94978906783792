import { RichText } from 'components/atoms/RichText';
import { FilterSearchPageHeadingProps } from './FilterSearchPageHeading.types';

export const FilterSearchPageHeading: React.FC<FilterSearchPageHeadingProps> = ({
  className,
  slices,
}) => {
  if (!slices) {
    return null;
  }

  const slice = slices.find((sliceItem) => sliceItem.slice_type === 'filtersucheseite-heading');

  if (!slice) {
    return null;
  }
  return <RichText className={className} rich_text={slice.primary.content} />;
};
