import Link from 'components/atoms/Link/Link';
import { Heading } from 'components/atoms/Heading';
import Picture from 'components/atoms/Picture/Picture';
import { EventSliderItemProps } from './EventSliderItem.types';
import {
  ImageStyled,
  StyledBoldText,
  StyledIcon,
  StyledText,
  TextWrapper,
} from './EventSliderItem.styles';

const EventSliderItem = ({ link, image, text, date, time, className }: EventSliderItemProps) => {
  if (!link) {
    return null;
  }

  return (
    <Link skin="newsSliderItem" url={link} className={className}>
      <ImageStyled>{image && <Picture noMinHeight image={image} objectFit />}</ImageStyled>
      <TextWrapper>
        <Heading className="text" styles={{ marginBottom: 10 }} skin="500" elementType="div">
          {text}
        </Heading>
        {date.length ? (
          <StyledBoldText elementType="div" styles={{ marginBottom: 5, color: 'gray900' }}>
            <StyledIcon name="CALENDAR" />
            {date}
          </StyledBoldText>
        ) : null}
        {time.length ? (
          <StyledText elementType="div" styles={{ color: 'gray900' }}>
            <StyledIcon name="CLOCK" />
            {time}
          </StyledText>
        ) : null}
      </TextWrapper>
    </Link>
  );
};

export default EventSliderItem;
