import { EventSlider } from 'components/organisms/EventSlider';
import { Hero } from 'components/organisms/Hero';
import { ImageText } from 'components/organisms/ImageText';
import { NewsSlider } from 'components/organisms/NewsSlider';
import Teasers from 'components/organisms/Teasers/Teasers';
import { TagesplanSlider } from 'components/organisms/TagesplanSlider';
import { RichTextSlice } from 'components/organisms/RichTextSlice';
import { FooterOpeningHours } from 'components/organisms/Footer/partials/FooterOpeningHours';
import { FooterContact } from 'components/organisms/Footer/partials/FooterContact';
import { FooterLinksBlock } from 'components/organisms/Footer/partials/FooterLinksBlock';
import { FooterSocialLinks } from 'components/organisms/Footer/partials/FooterSocialLinks';
import { AccordionSlice } from 'components/organisms/AccordionSlice';
import { Table } from 'components/molecules/Table';
import { Separator } from 'components/organisms/Separator';
import { DownloadList } from 'components/organisms/DownloadList';
import { FactBox } from 'components/organisms/FactBox';
import { FormsSlice } from 'components/organisms/FormsSlice';
import { NoRender } from 'components/atoms/NoRender';
import { ImageSlider } from 'components/organisms/ImageSlider';
import { VideoYoutube } from 'components/atoms/VideoYoutube';
import { JobsOverview } from 'components/organisms/JobsOverview';
import { GastronomyOverview } from 'components/organisms/GastronomyOverview';
import { ContactElement } from 'components/organisms/ContactElement';
import { CompactTeaser } from 'components/molecules/CompactTeaser';
import { AudioPlayer } from 'components/organisms/AudioPlayer';
import { NextEventsHandwerk } from 'components/organisms/NextEventsHandwerk';
import { FilterSearchPageHeading } from 'components/molecules/FilterSearchPageHeading';
import { NextEventsGebaeude } from 'components/organisms/NextEventsGebaeude';
import { KurseSammlung } from 'components/organisms/KurseSammlung';

const SLICES_MAP = {
  accordion: AccordionSlice,
  audioplayer: AudioPlayer,
  contact_element: ContactElement,
  compact_teaser: CompactTeaser,
  bilderslider: ImageSlider,
  blog_uebersicht_content_header: NoRender,
  download_list: DownloadList,
  eventslider: EventSlider,
  events_uebersicht_content_header: NoRender,
  event_datetime: NoRender,
  fact_box: FactBox,
  fliesstext_block: RichTextSlice,
  'footer-social-links': FooterSocialLinks,
  gastronomy_overview: GastronomyOverview,
  hero: Hero,
  image_gallery: ImageSlider,
  image_text: ImageText,
  jobs_overview: JobsOverview,
  kontakt: FooterContact,
  kontakt_formular: FormsSlice,
  kurse_sammlung: KurseSammlung,
  /* SLICE_MAP_FORMS_IMPORT_PLOP_INJECT */
  handwerkspatenschaft: FormsSlice,
  sammlung: FormsSlice,
  newsletter: FormsSlice,
  gonner_flm_formular: FormsSlice,
  kursanmeldung: FormsSlice,
  prospektversand: FormsSlice,
  gutschein: FormsSlice,
  kursprogramm_bestellung: FormsSlice,
  gonner_formular: FormsSlice,
  gruppenreservierungen: FormsSlice,
  tischreservierung_individualgaeste: FormsSlice,
  nachlass: FormsSlice,
  schulen: FormsSlice,
  foerdervereine: FormsSlice,
  patenschaften: FormsSlice,
  leihgaben: FormsSlice,
  ballenberg_friends: FormsSlice,
  'links-block': FooterLinksBlock,
  nachste_veranstaltungen_handwerk: NextEventsHandwerk,
  next_events_gebaeude: NextEventsGebaeude,
  newsslider: NewsSlider,
  'opening-hours': FooterOpeningHours,
  rich_text: RichTextSlice,
  scheduleslider: TagesplanSlider,
  separator: Separator,
  table_2_cols: Table,
  table_3_cols: Table,
  'tagesplan-pdf': NoRender,
  teasers: Teasers,
  termin: NoRender,
  text: RichTextSlice,
  video_youtube: VideoYoutube,
  'filtersucheseite-heading': FilterSearchPageHeading,
};

export default SLICES_MAP;
