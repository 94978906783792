import { LiteralOrBreakpoints } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';
import { PrismicResponsiveImage } from 'prismic/types';
import { LinkProps } from 'components/atoms/Link/Link';
import { LinkImage, LinkWrapper, StyledIcon, TextWrapper, Text, Subtext } from './Teaser.styles';

type TeaserProps = LinkProps & {
  image: PrismicResponsiveImage;
  text: string | null;
  subtext: string;
  className?: string;
  aspectRatio?: LiteralOrBreakpoints<number, Theme>;
};

const Teaser = ({ url, image, text, subtext, aspectRatio, className }: TeaserProps) => (
  <LinkWrapper skin="teaser" url={url} className={className} aspectRatio={aspectRatio}>
    {'url' in image && <LinkImage className="image" image={image} objectFit size="container" />}
    <TextWrapper>
      <Text>{text}</Text>
      <Subtext>
        {subtext}
        <StyledIcon className="arrow" name="ARROW" />
      </Subtext>
    </TextWrapper>
  </LinkWrapper>
);

export default Teaser;
