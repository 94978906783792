import { styled } from '@nx-kit/styling';
import Picture from 'components/atoms/Picture/Picture';
import { Heading } from 'components/atoms/Heading';
import Link from 'components/atoms/Link/Link';
import { KursSliderItemComponentProps } from './KursSliderItem.types';

const ImageStyled = styled.div`
  min-height: 184px;
  height: 184px;
  width: 100%;
  margin-bottom: 12px;

  img {
    border-radius: 8px;
  }
`;

const Slot1Styled = styled.div`
  color: ${({ theme }) => theme.global.color.gray900};
`;

const Slot2Styled = styled.div`
  color: ${({ theme }) => theme.global.color.gray180};
`;

const KursSliderItem = ({ data, className }: KursSliderItemComponentProps) => {
  const { link, image, text, slot1, slot2 } = data;
  if (!link) {
    return null;
  }
  return (
    <Link skin="scheduleSliderItem" url={link} className={className}>
      <ImageStyled>{image && <Picture noMinHeight image={image} objectFit />}</ImageStyled>
      <Heading className="text" skin="500" elementType="span" styles={{ marginBottom: 5 }}>
        {text}
      </Heading>
      <Slot1Styled>{slot1}</Slot1Styled>
      <Slot2Styled>{slot2}</Slot2Styled>
    </Link>
  );
};

export default KursSliderItem;
