import { styled } from '@nx-kit/styling';
import { Text } from '@nx-kit/text';
import Icon from 'components/atoms/Icon/Icon';

export const ImageStyled = styled.div`
  min-height: 184px;
  height: 184px;
`;

export const TextWrapper = styled.div`
  padding: 20px 30px 30px;
`;

export const StyledText = styled(Text)`
  display: flex;
`;

export const StyledBoldText = styled(StyledText)`
  font-weight: 700;
`;

export const StyledIcon = styled(Icon)`
  font-size: 25px;
  color: ${({ theme }) => theme.global.color.primary};
  margin-right: 8px;
`;
