/*
 * This component builds different types of tables:
 *  - 3 Columns Slice: header row,  all 3 columns contains data,  30% width
 *  - 3 Columns Slice: header row, last (3th) column cells are empty,  50% width
 *  - 2 Columns Slice: no header row, 50% width
 * */

import uniqueId from 'lodash/uniqueId';
import { RichText } from 'components/atoms/RichText';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import { TableItem, TablePrimary, TableProps } from './Table.types';
import {
  Cellx2,
  Cellx3,
  FooterStyled,
  HeaderRow,
  RichTextStyled,
  Row,
  TableStyled,
} from './Table.styles';

const checkIsTableWithHeader = (primary: TablePrimary): boolean => {
  // Contains a "title column" field
  return 'title_column_1' in primary;
};

const checkIfHaveAccent = (items: TableItem, index: number): boolean => {
  return index === 0 && Object.entries(items).length <= 2;
};

const checkIfLastColumnIsEmpty = (items: TableItem[]): boolean => {
  const numRows = items.length;
  let numEmptyLastColumn = 0;

  items.forEach((item) => {
    if (item.column_3?.length === 0) {
      numEmptyLastColumn += 1;
    }
  });

  return numRows === numEmptyLastColumn;
};

export const Table: React.FC<TableProps> = ({ className, items, primary }) => {
  const isTablewithHeader = checkIsTableWithHeader(primary);

  const isLastColumnEmpty = checkIfLastColumnIsEmpty(items);

  return (
    <Container className={className}>
      <Column start={{ xs: 1, lg: 3 }} span={{ xs: 12, lg: 8 }}>
        <RichText rich_text={primary.table_title} />
        <TableStyled>
          {isTablewithHeader ? (
            <>
              <HeaderRow>
                <Cellx3>
                  <RichText rich_text={primary.title_column_1} />
                </Cellx3>
                <Cellx3>
                  <RichText rich_text={primary.title_column_2} />
                </Cellx3>
                {primary.title_column_3 && primary.title_column_3.length > 0 && (
                  <Cellx3>
                    <RichText rich_text={primary.title_column_3} />
                  </Cellx3>
                )}
              </HeaderRow>

              {items.map((rowItem) => (
                <Row key={uniqueId()}>
                  {Object.entries(rowItem).map(([, cellItem]) => (
                    <Cellx3 key={uniqueId()} as2Columns={isLastColumnEmpty}>
                      <RichText rich_text={cellItem} />
                    </Cellx3>
                  ))}
                </Row>
              ))}
            </>
          ) : (
            <>
              {items.map((rowItem) => (
                <Row key={uniqueId()}>
                  {Object.entries(rowItem).map(([, cellItem], cellIndex) => (
                    <Cellx2 key={uniqueId()} asTableHeader={checkIfHaveAccent(rowItem, cellIndex)}>
                      <RichText rich_text={cellItem} />
                    </Cellx2>
                  ))}
                </Row>
              ))}
            </>
          )}
        </TableStyled>
        <FooterStyled>{<RichTextStyled rich_text={primary.table_footer} />}</FooterStyled>
      </Column>
    </Container>
  );
};
