import { Children, ReactNode } from 'react';
import { Lazy, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/lazy';
import uniqueId from 'lodash/uniqueId';
import { SwiperProps, SwiperSlide } from 'swiper/react';
import { LiteralOrBreakpoints, useBreakpointsSorted } from '@nx-kit/styling';
import { getClosestBreakpoint } from 'helpers/breakpoints';
import { Theme } from 'themes/themeTypes';
import { SwiperWrapper } from './Slider.styles';

export type ItemSliderProps = Omit<SwiperProps, 'slidesPerView'> & {
  className?: string;
  children: ReactNode;
  slidesPerView?: LiteralOrBreakpoints<number | 'auto', Theme>;
  onSwiper?: any;
  showOverflow?: boolean;
  itemRef?: ItemRef;
};

export type ItemRef = any;

const Slider = ({
  className,
  children,
  slidesPerView = 'auto',
  showOverflow = false,
  navigation,
  itemRef,
}: ItemSliderProps) => {
  const bp = useBreakpointsSorted();

  const breakpoints: any = {};

  bp.forEach(({ min, breakpoint }) => {
    breakpoints[min] = {
      slidesPerView: getClosestBreakpoint(slidesPerView, breakpoint as any),
    };
  });

  const id = uniqueId();

  return (
    <SwiperWrapper
      // @ts-ignore
      ref={itemRef}
      breakpoints={breakpoints}
      className={`${className} swipper_${id}`}
      grabCursor
      modules={[Navigation, Lazy]}
      mousewheel
      navigation={navigation || false}
      slidesPerView={getClosestBreakpoint(slidesPerView)}
      spaceBetween={16}
      style={showOverflow ? { overflow: 'visible' } : {}}
      wrapperTag="ul"
    >
      {Children.map(children, (child, ind) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide tag="li" key={`slider-${id}_item-${ind}`}>
          {child}
        </SwiperSlide>
      ))}
    </SwiperWrapper>
  );
};

export default Slider;
