import { FormError, StyledLabel } from 'components/organisms/FormsSlice/Forms/Forms';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import {
  ArrowStyled,
  CurrentStyled,
  SelectContainerStyled,
  SelectStyled,
  Wrapper,
} from './SelectInput.styles';
import { SelectInputProps, Option } from './SelectInput.types';

export const SelectInput: React.FC<SelectInputProps> = ({
  className,
  options,
  name,
  label,
  placeholder,
  register,
  hasError,
  errorMessageRequired,
  validationOptions,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const { t } = useTranslation('common');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  function switchOpen() {
    setIsOpen((prevState) => !prevState);
  }

  // Need empty str workaround
  const emptyOptionValue = '';

  return (
    <>
      <StyledLabel htmlFor={name}>{t(label)}</StyledLabel>
      <Wrapper className={className} id="cta-dropdown" hasError={hasError}>
        <CurrentStyled id={name}>
          {selectedOption ? selectedOption.label : t(placeholder)}
          <ArrowStyled isOpen={isOpen} />
        </CurrentStyled>

        <SelectContainerStyled>
          <SelectStyled
            onClick={switchOpen}
            hasError={hasError}
            {...register(name, validationOptions)}
            onChange={(e) => {
              const selectedOption = options.find((option) => option.value === e.target.value);
              setSelectedOption(selectedOption || null);
            }}
            defaultValue={selectedOption}
          >
            <option value={selectedOption?.value}>{emptyOptionValue}</option>
            {options?.map((option) => (
              <option key={`cta-option_${option.label}`} value={String(option.value)}>
                {option.label}
              </option>
            ))}
          </SelectStyled>
        </SelectContainerStyled>
      </Wrapper>
      {hasError && errorMessageRequired && <FormError>{t(errorMessageRequired)}</FormError>}
    </>
  );
};
