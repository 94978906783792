import { styled } from '@nx-kit/styling';
import Column from 'components/layout/Grid/Column';

export const VideoWrapper = styled.div`
  background: ${({ theme }) => theme.global.color.gray150};
  border-radius: 8px;
  border: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ColumnStyled = styled(Column)`
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
`;
