import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import { DefaultForm } from 'components/molecules/DefaultForm';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  FormError,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { FoerdervereineFormInputTypes, FormParentProps } from './FoerdervereineForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { Heading } from 'components/atoms/Heading';
import { RadioInput } from 'components/atoms/RadioInput';
import { styled } from '@nx-kit/styling';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { DomainContext } from 'contexts/domain/DomainContext';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const WrapperRBMember = styled.div`
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }
  }
`;

export const FoerdervereineForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<FoerdervereineFormInputTypes>();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: FoerdervereineFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('foerdervereineForm.labels.supportAssociationTitle')}
              </Heading>
              <FormColumn span={12}>
                <WrapperRBMember>
                  <RadioInput
                    id="association01"
                    name="association"
                    register={register}
                    translation="foerdervereineForm.labels.supportAssociation.association01"
                    value="VFB"
                  />
                  <RadioInput
                    id="association04"
                    name="association"
                    register={register}
                    translation="foerdervereineForm.labels.supportAssociation.association04"
                    value="FFMB"
                    validationOptions={validationOptions.association}
                  />
                </WrapperRBMember>
                {errors.association && isSubmitted && (
                  <FormError className="associationError">
                    {t('foerdervereineForm.errors.associationRequired')}
                  </FormError>
                )}
              </FormColumn>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('foerdervereineForm.labels.memberTitle')}
              </Heading>
              <FormColumn span={12}>
                <WrapperRBMember>
                  <RadioInput
                    id="memberTyp01"
                    name="member"
                    register={register}
                    translation="foerdervereineForm.labels.member.memberTyp01"
                    value="Einzel 45/Jahr"
                  />
                  <RadioInput
                    id="memberTyp02"
                    name="member"
                    register={register}
                    translation="foerdervereineForm.labels.member.memberTyp02"
                    value="Einzel PLUS 90/Jahr"
                  />
                  <RadioInput
                    id="memberTyp03"
                    name="member"
                    register={register}
                    translation="foerdervereineForm.labels.member.memberTyp03"
                    value="Familie 75/Jahr"
                  />
                  <RadioInput
                    id="memberTyp04"
                    name="member"
                    register={register}
                    translation="foerdervereineForm.labels.member.memberTyp04"
                    value="Familie PLUS 120/Jahr"
                  />
                  <RadioInput
                    id="memberTyp05"
                    name="member"
                    register={register}
                    translation="foerdervereineForm.labels.member.memberTyp05"
                    value="Kollektiv für CHF 160.00/Jahr"
                  />
                  <RadioInput
                    id="memberTyp06"
                    name="member"
                    register={register}
                    translation="foerdervereineForm.labels.member.memberTyp06"
                    value="Kollektiv PLUS für CHF 240.00/Jahr"
                  />
                </WrapperRBMember>
                {errors.member && isSubmitted && (
                  <FormError className="memberError">
                    {t('foerdervereineForm.errors.memberRequired')}
                  </FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="recruited">
                  {t('foerdervereineForm.labels.recruited')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('recruited', { required: true })}
                  id="recruited"
                  placeholder={t('foerdervereineForm.placeholders.recruited')}
                  hasError={errors.recruited !== undefined}
                />
                {errors.recruited !== undefined && (
                  <FormError>{t('foerdervereineForm.errors.recruitedRequired')}</FormError>
                )}
              </FormColumn>
              <DefaultForm errors={errors} register={register} isSubmitted={isSubmitted} />
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('contactForm.labels.yourMessage')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('message')}
                  id="message"
                  placeholder={t('contactForm.placeholders.message')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('foerdervereineForm.labels.familyConditions')} />
              </FormColumn>
              <input type="hidden" value="07-Foerdervereine" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
