import { courseTag } from 'prismic/config';

export const isDomainCourse = (domain: string): boolean => {
  return domain.indexOf(courseTag) >= 0;
};

export const getHost = (domain: string): string => {
  const isCourses = isDomainCourse(domain);

  // Live, main host(s): ballenberg.ch, ballenbergkurse.ch
  if (process.env.NEXT_PUBLIC_USE_MAIN_HOST === 'true') {
    return `https://${domain}`;
  }

  // Preview, main host(s): development.ballenberg.ch, development.ballenbergkurse.ch
  if (process.env.NEXT_PUBLIC_USE_PREVIEW_HOST === 'true') {
    return `https://development.${domain}`;
  }

  // Development
  if (process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_USE_DEV_HOST === 'true') {
    if (isCourses) {
      return 'http://ballenbergkurse.localhost:3000';
    }
    return 'http://ballenberg.localhost:3000';
  }

  // Vercel deployment(s)
  if (isCourses) {
    return 'https://ballenbergkurse-relaunch.vercel.app';
  }
  return 'https://ballenberg-relaunch.vercel.app';
};
