import { css, media, styled } from '@nx-kit/styling';
import { RichText } from 'components/atoms/RichText';

export const TableStyled = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

export const FooterStyled = styled.div`
  font-size: 11px;
  margin-top: 10px;
  ${media('md')} {
    margin-top: 20px;
  }
`;

export const Row = styled.div`
  display: table-row;
  border-bottom: 1px solid #dfdfdf;
`;

export const Cellx3 = styled.div<{ as2Columns?: boolean }>`
  display: table-cell;
  width: 30%;
  padding: 16px 0;

  ${(props) =>
    props.as2Columns &&
    css`
      width: 50%;
    `};
`;

export const Cellx2 = styled.div<{ asTableHeader?: boolean }>`
  display: table-cell;
  width: 50%;
  padding: 16px 0;

  ${(props) =>
    props.asTableHeader &&
    css`
      font-weight: bold;
    `};
`;

export const RichTextStyled = styled(RichText)`
  line-height: 20px;
`;

export const HeaderRow = styled.div`
  display: table-header-group;
  border-bottom: 1px solid #dfdfdf;
`;
