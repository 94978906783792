/* eslint-disable jsx-a11y/alt-text */
import { css, media, styled } from '@nx-kit/styling';
import { Heading } from 'components/atoms/Heading';
import Picture from 'components/atoms/Picture/Picture';
import Link from 'components/atoms/Link/Link';
import { RichText } from 'components/atoms/RichText';
import { Theme } from 'themes/themeTypes';
import getHeadingTag from 'helpers/getHeadingTag';
import Column from 'components/layout/Grid/Column';
import Container from 'components/layout/Grid/Container';
import { ImageTextProps } from './ImageText.types';
import getHeadingSkin from 'helpers/getHeadingSkin';

const Image = styled(Picture)`
  min-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Box = styled(Column)<{ isBox: boolean; theme: Theme }>`
  position: relative;
  z-index: ${({ theme }) => theme.global.zIndex.one};
  align-self: center !important;
  ${({ isBox, theme }) =>
    isBox &&
    css`
      padding: 24px 28px;
      background-color: ${theme.global.color.white};
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
      border-radius: 8px;

      ${media('md')} {
        margin: 40px 0;
        padding: 44px 50px 56px;
        box-shadow: 0 10px 20px -13px rgba(0, 0, 0, 0.2);
      }
    `}
`;

const ImageColumn = styled(Column)<{ isBig: boolean; theme: Theme }>`
  position: relative;
  padding-bottom: calc(100% / 1.4);
  overflow: hidden;
  border-radius: 0;

  ${({ isBig, theme }) =>
    isBig
      ? css`
          margin: 0 -${theme.grid.sideGap.xs}px -70px;
        `
      : css`
          margin: 0 0 20px;
          border-radius: 8px;
        `}
  ${media('md')} {
    border-radius: 8px;
    margin: 0;
  }
`;

const Subheadline = styled(Heading)<{ theme: Theme }>`
  display: block;
  margin: 0 0 5px;
  ${media('md')} {
    margin: 0 0 15px;
  }
  color: ${({ theme }) => theme.global.color.primary};
`;

const ImpliedDash = () => <span className="visually-hidden">{' – '}</span>;

const StyledRichText = styled(RichText)`
  margin-top: 15px;
  ${media('md')} {
    margin-top: 20px;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 20px;
  ${media('md')} {
    margin-top: 30px;
  }
`;

export const ImageText: React.FC<ImageTextProps> = ({ className, primary }) => {
  const { headline, subheadline, image, text, alignment, size, link, link_text, link_skin } =
    primary;
  const imageWidth = size === 'large' ? { xs: 12, md: 10 } : { xs: 12, md: 6 };

  const getLinkSkin = (name: string): string => {
    if (name === 'button') {
      return 'primary';
    }
    return 'tertiaryBig';
  };

  return (
    <Container className={className}>
      <Box
        start={alignment === 'right' ? 1 : { xs: 1, md: size === 'large' ? 7 : 8 }}
        span={{ xs: 12, md: size === 'large' ? 6 : 5 }}
        row={{ xs: 2, md: 1 }}
        isBox={size === 'large'}
      >
        {headline.length > 0 && (
          <Heading
            skin={getHeadingSkin(headline?.[0]?.type)}
            elementType={getHeadingTag(headline?.[0]?.type ?? 'h1')}
          >
            {subheadline && (
              <>
                <Subheadline elementType="span" skin="400">
                  {subheadline}
                </Subheadline>
                <ImpliedDash />
              </>
            )}
            {headline?.[0]?.text ?? ''}
          </Heading>
        )}
        {text.length > 0 && <StyledRichText rich_text={text} />}
        {(link.url || link.link_type === 'Document') && (
          <StyledLink skin={getLinkSkin(link_skin)} url={link}>
            {link_text || link.url}
          </StyledLink>
        )}
      </Box>
      <ImageColumn
        start={alignment === 'right' ? { xs: 1, md: 12 - imageWidth.md + 1 } : 1}
        span={imageWidth}
        row={1}
        isBig={size === 'large'}
      >
        {'url' in image && <Image objectFit image={image} size="full" />}
      </ImageColumn>
    </Container>
  );
};
