import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import { SalutationEnum } from 'helpers/forms/forms.types';
import { FormsCategories } from 'pages/api/nodemailer.helpers/nodemailer.types';
import { KursanmeldungFormInputTypes } from './KursanmeldungForm.types';

export interface GenericSubmitHandlerProps {
  data: KursanmeldungFormInputTypes;
}
export interface FetchSendFormResponse {
  data: any;
}

export const courseRegistrationSubmitHandler = async ({
  data,
}: GenericSubmitHandlerProps): Promise<any> => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch('/api/courseRegisterApi', options);
  const registrationData = await response.json();

  if (registrationData.data.IsOnWarteliste) {
    data.category = FormsCategories.cat19;
  } else {
    data.category = FormsCategories.cat18;
  }

  if (data.anredeIdCourseRegistration == 1) {
    data.salutation = SalutationEnum.male;
  } else {
    data.salutation = SalutationEnum.female;
  }

  data.firstName = data.Vorname || '';
  data.lastName = data.Nachname || '';

  if (registrationData.successfulRegistration) {
    await genericSubmitHandler({ data });
  }

  return registrationData;
};
