import { DEFAULT_DOMAIN } from 'prismic/config';

const getNewsletterURL = (domain: string, lang: string) => {
  if (domain === DEFAULT_DOMAIN) {
    if (lang === 'en') {
      return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERG_EN;
    } else if (lang === 'fr') {
      return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERG_FR;
    } else if (lang === 'it') {
      return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERG_IT;
    }
  } else {
    if (lang === 'de') {
      return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERGKURSE_DE;
    } else if (lang === 'en') {
      return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERGKURSE_EN;
    } else if (lang === 'fr') {
      return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERGKURSE_FR;
    } else if (lang === 'it') {
      return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERGKURSE_IT;
    }
  }

  return process.env.NEXT_PUBLIC_URL_NEWSLETTER_BALLENBERG_DE;
};

export default getNewsletterURL;
