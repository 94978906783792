/* eslint-disable react/jsx-no-comment-textnodes */
import { media, styled } from '@nx-kit/styling';
import Container from 'components/layout/Grid/Container';
import { FormWrapper, StyledColumn } from './Forms/Forms';
import { FormsSliceProps } from './FormsSlice.types';
import { ContactForm } from './Forms/ContactForm/ContactForm';
/* FORMS_IMPORT_PLOP_INJECT */
import { HandwerkspatenschaftForm } from './Forms/HandwerkspatenschaftForm/HandwerkspatenschaftForm';
import { SammlungForm } from './Forms/SammlungForm/SammlungForm';
import { NewsletterForm } from './Forms/NewsletterForm/NewsletterForm';
import { GoennerFlmForm } from './Forms/GoennerFlmForm/GoennerFlmForm';
import { KursanmeldungForm } from './Forms/KursanmeldungForm/KursanmeldungForm';
import { ProspektversandForm } from './Forms/ProspektversandForm/ProspektversandForm';
import { GutscheinForm } from './Forms/GutscheinForm/GutscheinForm';
import { KursprogrammBestellungForm } from './Forms/KursprogrammBestellungForm/KursprogrammBestellungForm';
import { GoennerKBBForm } from './Forms/GoennerKBBForm/GoennerKBBForm';
import { GruppenReservationForm } from './Forms/GruppenReservationForm/GruppenReservationForm';
import { TischreservationenIndividualForm } from './Forms/TischreservationenSingleForm/TischreservationenSingleForm';
import { NachlassForm } from './Forms/NachlassForm/NachlassForm';
import { SchulenForm } from './Forms/SchulenForm/SchulenForm';
import { FoerdervereineForm } from './Forms/FoerdervereineForm/FoerdervereineForm';
import { PatenschaftenForm } from './Forms/PatenschaftenForm/PatenschaftenForm';
import { LeihgabenForm } from './Forms/LeihgabenForm/LeihgabenForm';
import { BallenbergFriendsForm } from './Forms/BallenbergFriendsForm/BallenbergFriendsForm';

const StyledColumnFix = styled(StyledColumn)`
  padding: 30px;
  ${media('md')} {
    padding: 50px 60px;
  }
`;

export const FormsSlice: React.FC<FormsSliceProps> = ({ className, primary, sliceName }) => {
  const {
    rich_text,
    title_success_email_dispatch,
    text_success_email_dispatch,
    title_successful_course_registration,
    successful_course_registration,
    title_registration_mit_warteliste,
    registration_waiting_list,
  } = primary;

  return (
    <FormWrapper className={className}>
      <Container>
        <StyledColumnFix
          span={{ xs: 12, lg: 10, xl: 8 }}
          start={{ xs: 1, lg: 2, xl: 3 }}
          className={sliceName}
        >
          {sliceName === 'kontakt_formular' && (
            <ContactForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {/* FORMS_SLICE_IMPORT_PLOP_INJECT */}
          {sliceName === 'handwerkspatenschaft' && (
            <HandwerkspatenschaftForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'sammlung' && (
            <SammlungForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'newsletter' && (
            <NewsletterForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'kursanmeldung' && (
            <KursanmeldungForm
              introRichText={rich_text}
              titleSuccessRegistration={title_successful_course_registration}
              textSuccessRegistration={successful_course_registration}
              titleRegistrationWithWaitingList={title_registration_mit_warteliste}
              textRegistrationWithWaitingList={registration_waiting_list}
            />
          )}
          {sliceName === 'prospektversand' && (
            <ProspektversandForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'gutschein' && (
            <GutscheinForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'kursprogramm_bestellung' && (
            <KursprogrammBestellungForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {/* Gönner-Formular für Ballenberg.ch */}
          {sliceName === 'gonner_flm_formular' && (
            <GoennerFlmForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {/* Gönner-Formular für Ballenbergkurse */}
          {sliceName === 'gonner_formular' && (
            <GoennerKBBForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'gruppenreservierungen' && (
            <GruppenReservationForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'tischreservierung_individualgaeste' && (
            <TischreservationenIndividualForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'nachlass' && (
            <NachlassForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'schulen' && (
            <SchulenForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'foerdervereine' && (
            <FoerdervereineForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'patenschaften' && (
            <PatenschaftenForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'leihgaben' && (
            <LeihgabenForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
          {sliceName === 'ballenberg_friends' && (
            <BallenbergFriendsForm
              introRichText={rich_text}
              titleNotification={title_success_email_dispatch}
              textNotification={text_success_email_dispatch}
            />
          )}
        </StyledColumnFix>
      </Container>
    </FormWrapper>
  );
};
