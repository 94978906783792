import { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { DocumentRelationsContext } from 'contexts/documentRelations/DocumentRelationsContext';
import { LOCALES_MAP } from 'prismic/config';
import linkResolver from './linkResolver';

export function useLinkResolver(): any {
  const documentRelations = useContext(DocumentRelationsContext);
  const { lang } = useTranslation('common');

  const foundLocale = Object.entries(LOCALES_MAP).find((entryItem) => {
    const [key] = entryItem;
    return key === lang;
  });

  return {
    documentRelations,
    lang,
    locale: foundLocale && foundLocale[1],
    linkResolver,
  };
}
