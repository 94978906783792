import { NewsSliderItemProps } from 'components/molecules/NewsSliderItem/NewsSliderItem.types';
import { BlogItem } from 'pages/api/blogApi.types';
import { Blog_ItemConnectionEdge } from 'types/generated/graphql';
import { getFormattedDateAndTime } from 'helpers/date';

export const mapEdgeItemToNewsBoxItem = (
  locale: string,
  edgeItem: BlogItem | Blog_ItemConnectionEdge
): NewsSliderItemProps => {
  const node = edgeItem.node;

  let link = null;

  if (node._linkType && node._meta.uid) {
    link = {
      link_type: node._linkType,
      uid: node._meta.uid,
      lang: locale,
    };
  }

  const { dateStr } = getFormattedDateAndTime(node.date, locale, true);

  return {
    image: node?.thumbnail,
    link,
    subtext: dateStr,
    text: node?.display_name?.[0]?.text || '',
  };
};
