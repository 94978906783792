import { styled, media } from '@nx-kit/styling';
import Icon from 'components/atoms/Icon/Icon';
import { Heading } from 'components/atoms/Heading';
import { DownloadListProps } from './DownloadList.types';
import { getLinkInfo } from './DownloadList.helper';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';

const ColumnStyled = styled(Column)`
  background-color: ${(props) => props.theme.global.color.gray50};
  border-radius: 8px;
  padding: 28px;
  ${media('md')} {
    padding: 52px;
  }
`;

const HeadingStyled = styled(Heading)`
  margin-bottom: 0;
  ${media('md')} {
    margin-bottom: 10px;
  }
`;

const IconStyled = styled(Icon)<{ disabled?: boolean }>`
  color: ${(props) =>
    props.disabled ? props.theme.global.color.gray180 : props.theme.global.color.primary};
  margin-right: 16px;
  ${media('md')} {
    margin-right: 19px;
  }
`;

const LabelStyled = styled.div<{ disabled?: boolean }>`
  font-size: 18px;
  line-height: 28px;
  margin-right: 10px;
  color: ${(props) => props.disabled && props.theme.global.color.gray180};
  ${media('md')} {
    font-size: 20px;
    line-height: 30px;
    margin-right: 15px;
  }
`;

const TextInfoStyled = styled.div`
  color: ${(props) => props.theme.global.color.gray180};
  font-size: 11px;
  line-height: 20px;
  ${media('md')} {
    font-size: 13px;
    line-height: 20px;
  }
`;

const ItemListStyled = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.global.color.gray600};
  align-items: baseline;
  padding: 20px 0;
  ${media('md')} {
    padding: 15px 0;
  }
`;

const LinkStyled = styled.a`
  &:link,
  &:visited {
    text-decoration: none;
    color: ${(props) => props.theme.global.color.gray900};
  }

  &:hover {
    color: ${(props) => props.theme.global.color.primary};
  }
`;

export const DownloadList: React.FC<DownloadListProps> = ({ className, items, primary }) => {
  function deleteAllSearchParams(url: string) {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);

    for (const param of params.keys()) {
      params.delete(param);
    }

    urlObj.search = params.toString();
    return urlObj.toString();
  }

  return (
    <Container className={className}>
      <ColumnStyled start={{ xs: 1, lg: 3 }} span={{ xs: 12, lg: 8 }}>
        {primary.list_title && (
          <HeadingStyled skin="600" elementType="h3">
            {primary.list_title}
          </HeadingStyled>
        )}
        <ul>
          {items.map((item) => {
            const itemLabel = item.link_label;
            if (!itemLabel) {
              return null;
            }

            const isDisabled = !item.link_file.url;
            const ListItemContent = (
              <ItemListStyled>
                <IconStyled disabled={isDisabled} name="DOWNLOAD" />
                <LabelStyled disabled={isDisabled}>{itemLabel}</LabelStyled>
                <TextInfoStyled>{getLinkInfo(item.link_file)}</TextInfoStyled>
              </ItemListStyled>
            );

            let downloadLink = item.link_file.url;

            if (item.link_file.kind === 'image' && item.link_file.url) {
              downloadLink = deleteAllSearchParams(item.link_file.url);
            }

            return (
              <li key={itemLabel}>
                {isDisabled ? (
                  ListItemContent
                ) : (
                  <LinkStyled rel="noreferrer" href={downloadLink} target="_blank">
                    {ListItemContent}
                  </LinkStyled>
                )}
              </li>
            );
          })}
        </ul>
      </ColumnStyled>
    </Container>
  );
};
