import useTranslation from 'next-translate/useTranslation';
import { FormError, StyledLabel } from 'components/organisms/FormsSlice/Forms/Forms';
import { TextInputProps } from './TextInput.types';
import { StyledFieldDescription, StyledInputText, WrapperText } from './TextInput.styles';

export const TextInput: React.FC<TextInputProps> = ({
  defaultValue,
  errorMessagePattern,
  errorMessageRequired,
  fieldDescription,
  hasError,
  label,
  name,
  placeholder,
  register,
  validationOptions,
  type = 'text',
}) => {
  const { t } = useTranslation('common');

  const isLabelNotEmpty = label?.length > 0 ? true : false;

  return (
    <>
      {isLabelNotEmpty && <StyledLabel htmlFor={name}>{t(label)}</StyledLabel>}
      {!fieldDescription && (
        <StyledInputText
          defaultValue={defaultValue}
          type={type}
          hasError={hasError}
          id={name}
          placeholder={t(placeholder)}
          {...register(name, validationOptions)}
        />
      )}
      {fieldDescription && (
        <WrapperText>
          <StyledInputText
            defaultValue={defaultValue}
            type={type}
            hasError={hasError}
            id={name}
            placeholder={t(placeholder)}
            {...register(name, validationOptions)}
          />
          <StyledFieldDescription>{t(fieldDescription)}</StyledFieldDescription>
        </WrapperText>
      )}
      {hasError && errorMessagePattern && <FormError>{t(errorMessagePattern)}</FormError>}
      {hasError && errorMessageRequired && <FormError>{t(errorMessageRequired)}</FormError>}
    </>
  );
};
