import { keyframes, styled } from '@nx-kit/styling';

const heartbeat = keyframes`
  0%  { transform: scale( 1 ); }
  25% { transform: scale( .75 ); }
  50% { transform: scale( 1 ); }
  75% { transform: scale( .75 ); }
  100%{ transform: scale( 1 ); }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageStyled = styled.img`
  width: 75px;
  height: 64px;

  animation-name: ${heartbeat};
  animation-duration: 4s;
  animation-iteration-count: infinite;
`;
