import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  FormError,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
  StyledLabel,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { GruppenReservationFormInputTypes, FormParentProps } from './GruppenReservationForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { TextInput } from 'components/atoms/TextInput';
import { Heading } from 'components/atoms/Heading';
import { FormDatePicker } from 'components/atoms/FormDatePicker';
import { SelectInput } from 'components/atoms/SelectInput';
import { RadioInput } from 'components/atoms/RadioInput';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import {
  DateGroupStyled,
  WrapperRBArrivalPlace,
  WrapperRBNumberOfEntries,
  WrapperRBPayment,
  WrapperRBSalutations,
} from './GruppenReservationForm.styles';
import { DomainContext } from 'contexts/domain/DomainContext';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import { SalutationEnum } from 'helpers/forms/forms.types';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const defaultValues = {
  payment: 'Bar',
};

export const GruppenReservationForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<GruppenReservationFormInputTypes>({ defaultValues });
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: GruppenReservationFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  const optionsGroupTyp = [
    { value: 'Privat', label: t('groupReservationForm.labels.groupTyp.option1') },
    { value: 'Firma/Verein/Institution', label: t('groupReservationForm.labels.groupTyp.option2') },
  ];

  const optionsReasonVisit = [
    { value: 'Mittagessen', label: t('tableReservationForm.labels.reasonVisit.option1') },
    { value: 'Apéro', label: t('tableReservationForm.labels.reasonVisit.option2') },
    { value: 'Znüni/Zvieri', label: t('tableReservationForm.labels.reasonVisit.option3') },
    {
      value: 'Abendessen (ab 30 Personen)',
      label: t('tableReservationForm.labels.reasonVisit.option4'),
    },
  ];

  const optionsDesiredLocation = [
    {
      value: 'Wirtshaus «Alter Bären»',
      label: t('tableReservationForm.labels.desiredLocation.option1'),
    },
    { value: 'Gasthaus «Degen»', label: t('tableReservationForm.labels.desiredLocation.option2') },
    {
      value: 'Gasthof «Wilerhorn»',
      label: t('tableReservationForm.labels.desiredLocation.option3'),
    },
    { value: 'Osteria Novazzano', label: t('tableReservationForm.labels.desiredLocation.option4') },
    {
      value: 'Im Ballenberg-Gelände',
      label: t('tableReservationForm.labels.desiredLocation.option5'),
    },
  ];

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormColumn span={12}>
                <HeadlineLabel>{t('tableReservationForm.labels.generalInfo')}</HeadlineLabel>
              </FormColumn>
              <FormColumn span={12}>
                <SelectInput
                  options={optionsGroupTyp}
                  label="groupReservationForm.labels.groupTypTitle"
                  placeholder="groupReservationForm.placeholders.selectGroupTyp"
                  name="groupTyp"
                  errorMessageRequired="groupReservationForm.errors.groupTypRequired"
                  hasError={errors.groupTyp !== undefined}
                  register={register}
                  validationOptions={validationOptions.groupTyp}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  hasError={errors.nameGroup !== undefined}
                  label="groupReservationForm.labels.nameGroup"
                  name="nameGroup"
                  placeholder="groupReservationForm.placeholders.nameGroup"
                  errorMessageRequired="groupReservationForm.errors.nameGroupRequired"
                  register={register}
                  validationOptions={validationOptions.nameGroup}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="groupReservationForm.labels.groupStreet"
                  name="groupStreet"
                  placeholder="groupReservationForm.placeholders.groupStreet"
                  register={register}
                  validationOptions={validationOptions.groupStreet}
                  errorMessageRequired="groupReservationForm.errors.groupStreetRequired"
                  hasError={errors.groupStreet !== undefined}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="groupReservationForm.labels.groupZipCode"
                  name="groupZipCode"
                  placeholder="groupReservationForm.placeholders.groupZipCode"
                  register={register}
                  validationOptions={validationOptions.groupZipCode}
                  errorMessageRequired="groupReservationForm.errors.groupZipCodeRequired"
                  hasError={errors.groupZipCode !== undefined}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="groupReservationForm.labels.groupCity"
                  name="groupCity"
                  placeholder="groupReservationForm.placeholders.groupCity"
                  register={register}
                  validationOptions={validationOptions.groupCity}
                  errorMessageRequired="groupReservationForm.errors.groupCityRequired"
                  hasError={errors.groupCity !== undefined}
                />
              </FormColumn>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('groupReservationForm.labels.yourInformation')}
                </Heading>
                <WrapperRBSalutations>
                  <RadioInput
                    id="salutationFemale"
                    name="salutation"
                    register={register}
                    translation="contactForm.labels.salutation.female"
                    value={SalutationEnum.female}
                  />
                  <RadioInput
                    id="salutationMale"
                    name="salutation"
                    register={register}
                    translation="contactForm.labels.salutation.male"
                    value={SalutationEnum.male}
                    validationOptions={validationOptions.salutation}
                  />
                </WrapperRBSalutations>
                {errors.salutation && isSubmitted && (
                  <FormError className="salutationError">
                    {t('contactForm.errors.salutationRequired')}
                  </FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="contactForm.errors.firstNameRequired"
                  hasError={errors.firstName !== undefined}
                  label="contactForm.labels.firstName"
                  name="firstName"
                  placeholder="contactForm.placeholders.firstName"
                  register={register}
                  validationOptions={validationOptions.firstName}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="contactForm.errors.lastNameRequired"
                  hasError={errors.lastName !== undefined}
                  label="contactForm.labels.lastName"
                  name="lastName"
                  placeholder="contactForm.placeholders.lastName"
                  register={register}
                  validationOptions={validationOptions.lastName}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="groupReservationForm.errors.groupPhoneRequired"
                  hasError={errors.phone !== undefined}
                  label="groupReservationForm.labels.groupPhone"
                  name="phone"
                  placeholder="contactForm.placeholders.phone"
                  register={register}
                  validationOptions={validationOptions.phone}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessagePattern="contactForm.errors.eMailInvalid"
                  errorMessageRequired="contactForm.errors.eMailRequired"
                  hasError={errors.eMail !== undefined}
                  label="contactForm.labels.eMail"
                  name="eMail"
                  placeholder="contactForm.placeholders.eMail"
                  register={register}
                  validationOptions={validationOptions.eMail}
                />
              </FormColumn>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('tableReservationForm.labels.detailVisit')}
              </Heading>
              <DateGroupStyled>
                <FormColumn span={12}>
                  <StyledLabel htmlFor={'visitDate'}>
                    {t('tableReservationForm.labels.visitDate')}
                  </StyledLabel>
                  <FormDatePicker
                    name="visitDate"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setValue('visitDate', date!);
                    }}
                    placeholder="tableReservationForm.placeholders.visitDate"
                    validationOptions={validationOptions.visitDate}
                    errorMessageRequired="tableReservationForm.errors.visitDateRequired"
                    hasError={errors.visitDate !== undefined}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="tableReservationForm.errors.visitTimeRequired"
                    hasError={errors.visitTime !== undefined}
                    label="tableReservationForm.labels.visitTime"
                    name="visitTime"
                    placeholder="tableReservationForm.placeholders.visitTime"
                    register={register}
                    validationOptions={validationOptions.visitTime}
                  />
                </FormColumn>
              </DateGroupStyled>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('schulenForm.labels.arrivalPlaceTitle')}
                </Heading>
                <WrapperRBArrivalPlace>
                  <RadioInput
                    id="arrivalPlaceWest"
                    name="arrivalPlace"
                    register={register}
                    translation="schulenForm.labels.arrivalPlace.entranceWest"
                    value="Eingang West"
                  />
                  <RadioInput
                    id="arrivalPlaceEast"
                    name="arrivalPlace"
                    register={register}
                    translation="schulenForm.labels.arrivalPlace.entranceEast"
                    value="Eingang Ost"
                    validationOptions={validationOptions.arrivalPlace}
                  />
                </WrapperRBArrivalPlace>
                {errors.arrivalPlace && isSubmitted && (
                  <FormError className="arrivalPlaceError">
                    {t('schulenForm.errors.arrivalPlaceRequired')}
                  </FormError>
                )}
              </FormColumn>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('schulenForm.labels.numberOfEntries')}
              </Heading>
              <WrapperRBNumberOfEntries>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.adultRequired"
                    hasError={errors.adult !== undefined}
                    label="schulenForm.labels.adult"
                    name="adult"
                    placeholder="schulenForm.placeholders.adult"
                    register={register}
                    validationOptions={validationOptions.adult}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.adultMemberPlusRequired"
                    hasError={errors.adultMemberPlus !== undefined}
                    label="schulenForm.labels.adultMemberPlus"
                    name="adultMemberPlus"
                    placeholder="schulenForm.placeholders.adultMemberPlus"
                    register={register}
                    validationOptions={validationOptions.adultMemberPlus}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.childOver6Required"
                    hasError={errors.childOver6 !== undefined}
                    label="schulenForm.labels.childOver6"
                    name="childOver6"
                    placeholder="schulenForm.placeholders.childOver6"
                    register={register}
                    validationOptions={validationOptions.childOver6}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.childUnder6Required"
                    hasError={errors.childUnder6 !== undefined}
                    label="schulenForm.labels.childUnder6"
                    name="childUnder6"
                    placeholder="schulenForm.placeholders.childUnder6"
                    register={register}
                    validationOptions={validationOptions.childUnder6}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.railAwayRequired"
                    hasError={errors.railAway !== undefined}
                    label="schulenForm.labels.railAway"
                    name="railAway"
                    placeholder="schulenForm.placeholders.railAway"
                    register={register}
                    validationOptions={validationOptions.railAway}
                  />
                </FormColumn>
              </WrapperRBNumberOfEntries>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('groupReservationForm.labels.mealsTitle')}
                </Heading>
                <WrapperRBPayment>
                  <RadioInput
                    id="mealsYes"
                    name="meals"
                    register={register}
                    translation="groupReservationForm.labels.meals.yes"
                    value="Ja"
                  />
                  <RadioInput
                    id="mealsNo"
                    name="meals"
                    register={register}
                    translation="groupReservationForm.labels.meals.no"
                    value="Nein"
                    validationOptions={validationOptions.meals}
                  />
                </WrapperRBPayment>
                {errors.meals && isSubmitted && (
                  <FormError className="mealsError">
                    {t('groupReservationForm.errors.mealsRequired')}
                  </FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <SelectInput
                  options={optionsReasonVisit}
                  label="groupReservationForm.labels.reasonVisitTitle"
                  placeholder="tableReservationForm.placeholders.selectReasonVisit"
                  name="reasonVisit"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <SelectInput
                  options={optionsDesiredLocation}
                  label="groupReservationForm.labels.desiredLocationTitle"
                  placeholder="tableReservationForm.placeholders.desiredLocation"
                  name="desiredLocation"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="groupReservationForm.labels.mealTime"
                  name="mealTime"
                  placeholder="groupReservationForm.placeholders.mealTime"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('tableReservationForm.labels.paymentTitle')}
                </Heading>
                <WrapperRBPayment>
                  <RadioInput
                    id="paymentCash"
                    name="payment"
                    register={register}
                    translation="tableReservationForm.labels.payment.cash"
                    value="Bar"
                  />
                  <RadioInput
                    id="paymentCard"
                    name="payment"
                    register={register}
                    translation="tableReservationForm.labels.payment.card"
                    value="Kreditkarte/Postcard"
                  />
                  <RadioInput
                    id="paymentInvoice"
                    name="payment"
                    register={register}
                    translation="tableReservationForm.labels.payment.invoice"
                    value="Rechnung"
                  />
                </WrapperRBPayment>
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('tableReservationForm.labels.yourMessage')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('message')}
                  id="message"
                  placeholder={t('tableReservationForm.placeholders.yourMessage')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input type="hidden" value={lang} {...register('locale')} />
              <input type="hidden" value="11-GruppenReservationen" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
