import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import { DefaultForm } from 'components/molecules/DefaultForm';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  FormError,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { SammlungFormInputTypes, FormParentProps } from './SammlungForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import { DomainContext } from 'contexts/domain/DomainContext';
import { FormsCategories } from 'pages/api/nodemailer.helpers/nodemailer.types';

export const SammlungForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<SammlungFormInputTypes>();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const checkFile = (event: any) => {
    clearErrors(['uploadPicture1', 'uploadPicture2', 'uploadPicture3']);
    const MAX_FILE_SIZE = 1048576; // binary, 1MB

    const target = event.target;

    if (target.files && target.files.length > 0) {
      const file = target.files[0];

      if (file.size > MAX_FILE_SIZE) {
        setError(event.target.name, {
          type: 'custom',
          message: t('sammlungForm.errors.pictureSize') + ' ' + MAX_FILE_SIZE / 1024 / 1024 + 'MB',
        });
        event.target.value = null;
      }

      if (file.type.indexOf('image/') < 0) {
        setError(event.target.name, { type: 'custom', message: t('sammlungForm.errors.fileTyp') });
        event.target.value = null;
      }
    }
  };

  const submitHandler = async (data: SammlungFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormSuccessNotification
            onClickReturn={onClickReturn}
            titleNotification={titleNotification}
            textNotification={textNotification}
          />
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)} encType="multipart/form-data">
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="collectionItem">
                  {t('sammlungForm.labels.collectionItem')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('collectionItem')}
                  id="collectionItem"
                  placeholder={t('sammlungForm.placeholders.collectionItem')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="origin">{t('sammlungForm.labels.origin')}</HeadlineLabel>
                <InputTextArea
                  {...register('origin')}
                  id="origin"
                  placeholder={t('sammlungForm.placeholders.origin')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="condition">
                  {t('sammlungForm.labels.condition')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('condition')}
                  id="condition"
                  placeholder={t('sammlungForm.placeholders.condition')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="renovated">
                  {t('sammlungForm.labels.renovated')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('renovated')}
                  id="renovated"
                  placeholder={t('sammlungForm.placeholders.renovated')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="reason">{t('sammlungForm.labels.reason')}</HeadlineLabel>
                <InputTextArea
                  {...register('reason')}
                  id="reason"
                  placeholder={t('sammlungForm.placeholders.reason')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="leaveForFree">
                  {t('sammlungForm.labels.leaveForFree')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('leaveForFree')}
                  id="leaveForFree"
                  placeholder={t('sammlungForm.placeholders.leaveForFree')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="delivery">
                  {t('sammlungForm.labels.delivery')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('delivery')}
                  id="delivery"
                  placeholder={t('sammlungForm.placeholders.delivery')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="location">
                  {t('sammlungForm.labels.location')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('location')}
                  id="location"
                  placeholder={t('sammlungForm.placeholders.location')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="uploadPicture1">
                  {t('sammlungForm.labels.uploadPicture1')}
                </HeadlineLabel>
                <div>
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    {...register('uploadPicture1')}
                    name="uploadPicture1"
                    onChange={checkFile}
                  />
                </div>
                {errors.uploadPicture1 && (
                  <FormError>{errors.uploadPicture1.message as string}</FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="uploadPicture2">
                  {t('sammlungForm.labels.uploadPicture2')}
                </HeadlineLabel>
                <div>
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    {...register('uploadPicture2')}
                    name="uploadPicture2"
                    onChange={checkFile}
                  />
                </div>
                {errors.uploadPicture2 && (
                  <FormError>{errors.uploadPicture2.message as string}</FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="uploadPicture3">
                  {t('sammlungForm.labels.uploadPicture3')}
                </HeadlineLabel>
                <div>
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    {...register('uploadPicture3')}
                    name="uploadPicture3"
                    onChange={checkFile}
                  />
                </div>
                {errors.uploadPicture3 && (
                  <FormError>{errors.uploadPicture3.message as string}</FormError>
                )}
              </FormColumn>
              <DefaultForm
                errors={errors}
                register={register}
                isSubmitted={isSubmitted}
                phoneLabel="sammlungForm.labels.phone"
              />
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                    onClick={() => {
                      register('phone', { required: true });
                    }}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input type="hidden" value={FormsCategories.cat17} {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
