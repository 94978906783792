import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  FormError,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
  StyledLabel,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { SchulenFormInputTypes, FormParentProps } from './SchulenForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { TextInput } from 'components/atoms/TextInput';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { RadioInput } from 'components/atoms/RadioInput';
import { Heading } from 'components/atoms/Heading';
import { FormDatePicker } from 'components/atoms/FormDatePicker';
import {
  DateGroupStyled,
  WrapperRBArrivalPlace,
  WrapperRBNumberOfEntries,
  WrapperRBPayment,
  WrapperRBSalutations,
} from './SchulenForm.styles';
import { DomainContext } from 'contexts/domain/DomainContext';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import { SalutationEnum } from 'helpers/forms/forms.types';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const defaultValues = {
  payment: 'Bar',
};

export const SchulenForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<SchulenFormInputTypes>({ defaultValues });
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: SchulenFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormColumn span={12}>
                <HeadlineLabel>{t('schulenForm.labels.generalInfo')}</HeadlineLabel>
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="schulenForm.errors.nameSchoolRequired"
                  hasError={errors.nameSchool !== undefined}
                  label="schulenForm.labels.nameSchool"
                  name="nameSchool"
                  placeholder="schulenForm.placeholders.nameSchool"
                  register={register}
                  validationOptions={validationOptions.nameSchool}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="schulenForm.errors.schoolStreetRequired"
                  hasError={errors.schoolStreet !== undefined}
                  label="schulenForm.labels.schoolStreet"
                  name="schoolStreet"
                  placeholder="schulenForm.placeholders.schoolStreet"
                  register={register}
                  validationOptions={validationOptions.schoolStreet}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="schulenForm.errors.schoolZipCodeRequired"
                  hasError={errors.schoolZipCode !== undefined}
                  label="schulenForm.labels.schoolZipCode"
                  name="schoolZipCode"
                  placeholder="schulenForm.placeholders.schoolZipCode"
                  register={register}
                  validationOptions={validationOptions.schoolZipCode}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="schulenForm.errors.schoolCityRequired"
                  hasError={errors.schoolCity !== undefined}
                  label="schulenForm.labels.schoolCity"
                  name="schoolCity"
                  placeholder="schulenForm.placeholders.schoolCity"
                  register={register}
                  validationOptions={validationOptions.schoolCity}
                />
              </FormColumn>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('schulenForm.labels.yourInformation')}
                </Heading>
                <WrapperRBSalutations>
                  <RadioInput
                    id="salutationFemale"
                    name="salutation"
                    register={register}
                    translation="contactForm.labels.salutation.female"
                    value={SalutationEnum.female}
                  />
                  <RadioInput
                    id="salutationMale"
                    name="salutation"
                    register={register}
                    translation="contactForm.labels.salutation.male"
                    value={SalutationEnum.male}
                    validationOptions={validationOptions.salutation}
                  />
                </WrapperRBSalutations>
                {errors.salutation && isSubmitted && (
                  <FormError className="salutationError">
                    {t('contactForm.errors.salutationRequired')}
                  </FormError>
                )}
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="contactForm.errors.firstNameRequired"
                  hasError={errors.firstName !== undefined}
                  label="contactForm.labels.firstName"
                  name="firstName"
                  placeholder="contactForm.placeholders.firstName"
                  register={register}
                  validationOptions={validationOptions.firstName}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="contactForm.errors.lastNameRequired"
                  hasError={errors.lastName !== undefined}
                  label="contactForm.labels.lastName"
                  name="lastName"
                  placeholder="contactForm.placeholders.lastName"
                  register={register}
                  validationOptions={validationOptions.lastName}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="schulenForm.errors.phoneRequired"
                  hasError={errors.phone !== undefined}
                  label="schulenForm.labels.phone"
                  name="phone"
                  placeholder="contactForm.placeholders.phone"
                  register={register}
                  validationOptions={validationOptions.phone}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessagePattern="contactForm.errors.eMailInvalid"
                  errorMessageRequired="contactForm.errors.eMailRequired"
                  hasError={errors.eMail !== undefined}
                  label="contactForm.labels.eMail"
                  name="eMail"
                  placeholder="contactForm.placeholders.eMail"
                  register={register}
                  validationOptions={validationOptions.eMail}
                />
              </FormColumn>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('schulenForm.labels.detailVisit')}
              </Heading>
              <DateGroupStyled>
                <FormColumn span={12}>
                  <StyledLabel htmlFor={'visitDate'}>
                    {t('schulenForm.labels.visitDate')}
                  </StyledLabel>
                  <FormDatePicker
                    name="visitDate"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setValue('visitDate', date!);
                    }}
                    placeholder="schulenForm.placeholders.visitDate"
                    validationOptions={validationOptions.visitDate}
                    errorMessageRequired="schulenForm.errors.visitDateRequired"
                    hasError={errors.visitDate !== undefined}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.visitTimeRequired"
                    hasError={errors.visitTime !== undefined}
                    label="schulenForm.labels.visitTime"
                    name="visitTime"
                    placeholder="schulenForm.placeholders.visitTime"
                    register={register}
                    validationOptions={validationOptions.visitTime}
                  />
                </FormColumn>
              </DateGroupStyled>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="schulenForm.errors.classAgeRequired"
                  hasError={errors.classAge !== undefined}
                  label="schulenForm.labels.classAge"
                  name="classAge"
                  placeholder="schulenForm.placeholders.classAge"
                  register={register}
                  validationOptions={validationOptions.classAge}
                />
              </FormColumn>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('schulenForm.labels.arrivalPlaceTitle')}
                </Heading>
                <WrapperRBArrivalPlace>
                  <RadioInput
                    id="arrivalPlaceWest"
                    name="arrivalPlace"
                    register={register}
                    translation="schulenForm.labels.arrivalPlace.entranceWest"
                    value="entranceWest"
                  />
                  <RadioInput
                    id="arrivalPlaceEast"
                    name="arrivalPlace"
                    register={register}
                    translation="schulenForm.labels.arrivalPlace.entranceEast"
                    value="entranceEast"
                    validationOptions={validationOptions.arrivalPlace}
                  />
                </WrapperRBArrivalPlace>
                {errors.arrivalPlace && isSubmitted && (
                  <FormError className="arrivalPlaceError">
                    {t('schulenForm.errors.arrivalPlaceRequired')}
                  </FormError>
                )}
              </FormColumn>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('schulenForm.labels.numberOfEntries')}
              </Heading>
              <WrapperRBNumberOfEntries>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.adultRequired"
                    hasError={errors.adult !== undefined}
                    label="schulenForm.labels.adult"
                    name="adult"
                    placeholder="schulenForm.placeholders.adult"
                    register={register}
                    validationOptions={validationOptions.adult}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.adultMemberPlusRequired"
                    hasError={errors.adultMemberPlus !== undefined}
                    label="schulenForm.labels.adultMemberPlus"
                    name="adultMemberPlus"
                    placeholder="schulenForm.placeholders.adultMemberPlus"
                    register={register}
                    validationOptions={validationOptions.adultMemberPlus}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.childOver6Required"
                    hasError={errors.childOver6 !== undefined}
                    label="schulenForm.labels.childOver6"
                    name="childOver6"
                    placeholder="schulenForm.placeholders.childOver6"
                    register={register}
                    validationOptions={validationOptions.childOver6}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.childUnder6Required"
                    hasError={errors.childUnder6 !== undefined}
                    label="schulenForm.labels.childUnder6"
                    name="childUnder6"
                    placeholder="schulenForm.placeholders.childUnder6"
                    register={register}
                    validationOptions={validationOptions.childUnder6}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessageRequired="schulenForm.errors.railAwayRequired"
                    hasError={errors.railAway !== undefined}
                    label="schulenForm.labels.railAway"
                    name="railAway"
                    placeholder="schulenForm.placeholders.railAway"
                    register={register}
                    validationOptions={validationOptions.railAway}
                  />
                </FormColumn>
              </WrapperRBNumberOfEntries>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('schulenForm.labels.paymentTitle')}
                </Heading>
                <WrapperRBPayment>
                  <RadioInput
                    id="paymentnCash"
                    name="payment"
                    register={register}
                    translation="schulenForm.labels.payment.cash"
                    value="Bar"
                  />
                  <RadioInput
                    id="paymentInvoice"
                    name="payment"
                    register={register}
                    translation="schulenForm.labels.payment.invoice"
                    value="Rechnung"
                  />
                  <RadioInput
                    id="paymentCard"
                    name="payment"
                    register={register}
                    translation="schulenForm.labels.payment.card"
                    value="Kreditkarte/Postcard"
                  />
                </WrapperRBPayment>
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('schulenForm.labels.schoolPrograms')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('schoolPrograms')}
                  id="schoolPrograms"
                  placeholder={t('schulenForm.placeholders.schoolPrograms')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('schulenForm.labels.teachingMaterial')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('teachingMaterial')}
                  id="teachingMaterial"
                  placeholder={t('schulenForm.placeholders.teachingMaterial')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input type="hidden" value={lang} {...register('locale')} />
              <input type="hidden" value="06-Schulen" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
