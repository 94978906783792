import { css, LiteralOrBreakpoints, media, styled } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';
import Picture from 'components/atoms/Picture/Picture';
import Icon from 'components/atoms/Icon/Icon';
import Link from 'components/atoms/Link/Link';

export const LinkImage = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 0;
  transition: transform 500ms;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    transition: opacity 500ms;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 65.79%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
`;

export const TextWrapper = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.global.zIndex.one};
  padding: 30px;
  color: ${({ theme }) => theme.global.color.white} !important;
`;

export const StyledIcon = styled(Icon)`
  width: 30px;
  margin-inline-start: 10px;
  transition: transform 500ms;
`;

export const Text = styled.span`
  display: block;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  margin: 0 0 15px;
`;

export const Subtext = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
`;

export const LinkWrapper = styled(Link)<{ aspectRatio?: LiteralOrBreakpoints<number, Theme> }>`
  display: block;
  border-radius: 8px;
  justify-self: stretch;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: ${({ theme }) => theme.global.color.white} !important;

  ${({ aspectRatio }) => {
    const styles: any = [];

    if (aspectRatio) {
      styles.push(
        css`
          height: 0;
        `
      );
    }

    if (typeof aspectRatio === 'number') {
      styles.push(css`
        padding-bottom ${aspectRatio * 100}%;
      `);
    } else if (typeof aspectRatio === 'object') {
      Object.entries(aspectRatio).forEach(([bp, value]) =>
        styles.push(
          css`
          ${media(bp)} {
            padding-bottom ${value * 100}%;
          }
        `
        )
      );
    }
    return styles;
  }}
`;
