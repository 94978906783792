import { PrismicRichtextObject } from 'prismic/types';

export const isRichTextEmpty = (richText: PrismicRichtextObject[]): boolean => {
  if (richText?.length > 0) {
    if (richText?.[0]?.type == 'image') {
      return false;
    }
    if (richText?.[0]?.type == 'embed') {
      return false;
    }
    if (richText?.[0]?.text && richText?.[0].text.length > 0) {
      return false;
    }
  }
  return true;
};
