import { media, styled } from '@nx-kit/styling';

export const WrapperMember = styled.div`
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }
  }
`;

export const WrapperMemberHandcraft = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    align-self: flex-end;
    width: 100%;
    ${media('md')} {
      width: 33%;
    }
  }
`;

export const WrapperMemberAmount = styled.div`
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }
  }
`;
