import ReactAudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import { AudioPlayerProps } from './AudioPlayer.types';
import { Wrapper } from './AudioPlayer.styles';

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ className, items }) => {
  // currently, we only expect 1 file to be added
  const playlist = items.map((entry) => entry.audio_file?.url).filter(Boolean);

  if (playlist.length > 0) {
    return (
      <Container className={className}>
        <Column start={{ xs: 1, lg: 3 }} span={{ xs: 12, lg: 8 }}>
          <Wrapper>
            <ReactAudioPlayer src={playlist[0]} layout="stacked-reverse" />
          </Wrapper>
        </Column>
      </Container>
    );
  }
  return null;
};
