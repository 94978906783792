import { media, styled } from '@nx-kit/styling';
import { Theme } from 'themes/themeTypes';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import Copytext from 'components/molecules/Copytext/Copytext';
import { Heading } from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon/Icon';
import Link from 'components/atoms/Link/Link';

export const TitleBox = styled(Column)<{ theme: Theme }>`
  position: relative;
  z-index: ${({ theme }) => theme.global.zIndex.three};
  align-self: start !important;
  padding: 24px 28px;
  background-color: ${({ theme }) => theme.global.color.white};
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  ${media('md')} {
    margin: 80px 0;
    padding: 44px 50px 56px;
    box-shadow: 0 10px 20px -13px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledLink = styled(Link)`
  margin-top: 20px;
  ${media('md')} {
    margin-top: 30px;
  }
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: 10px;
`;

export const StyledCopytext = styled(Copytext)`
  color: ${(props) => props.theme.global.color.gold};
`;

export const ContactPersonColumn = styled(Column)<{ theme: Theme }>`
  position: relative;
  padding: 85px 24px 60px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.global.color.beige};
  border-radius: 0;
  margin: ${({ theme }) => `-70px -${theme.grid.sideGap.xs}px 0`};

  ${media('md')} {
    border-radius: 8px;
    margin: 0;
    padding: 85px 54px 60px;
  }
`;

export const ContainerStyled = styled(Container)`
  padding: 0;
`;

export const ColumnStyled = styled(Column)`
  background-color: ${(props) => props.theme.global.color.beige};
  border-radius: 8px;
`;

export const ContactPersonStyled = styled.article`
  &:last-child {
    hr {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;

  ${media('md')} {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
  }
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${(props) => props.theme.global.color.gray600};
  margin: 40px 0;
  max-width: 686px;
`;

export const PictureWrapper = styled.div`
  border: 4px solid ${(props) => props.theme.global.color.metaTheme};
  border-radius: 8px;
  width: 156px;
  height: 156px;

  ${media('md')} {
    width: 146px;
    height: 146px;
  }
`;

export const PersonalInfo = styled.div`
  margin: 15px 0 0 0;

  ${media('md')} {
    margin-left: 24px;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div:first-child {
    margin-right: 34px;
  }

  ${media('md')} {
    flex-direction: row;
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.global.color.primary};
  margin-right: 15px;
`;

export const ContactInfoItem = styled.a`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  color: inherit;
  text-decoration: none;

  & + & {
    margin-top: 15px;
    margin-left: 0;
  }

  ${media('md')} {
    & + & {
      margin-top: 0;
      margin-left: 34px;
    }
  }
`;
