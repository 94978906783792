import { media, styled } from '@nx-kit/styling';
import { Checkbox } from '@nx-kit/checkbox';

export const Wrapper = styled.div`
  accent-color: ${({ theme }) => theme.global.color.primary};
  display: flex;
  font-size: ${({ theme }) => theme.global.fontSize.medium}px;
  margin-top: 17px;
  ${media('md')} {
    margin-top: 15px;
  }
  > * {
    align-self: flex-start;
  }
`;

export const InputCheckbox = styled(Checkbox)`
  border: 2px solid ${({ theme }) => theme.global.color.gray500};
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 12px;
`;

export const StyledLink = styled.a`
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.global.color.primary};
`;
