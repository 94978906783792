import {
  Accordion as KitAccordion,
  AccordionProps as KitAccordionProps,
  AccordionItemProps,
} from '@nx-kit/accordion';

export const AccordionItem = ({ children, ...props }: AccordionItemProps) => (
  <KitAccordion.Item {...props}>
    <div>{children}</div>
  </KitAccordion.Item>
);

const Accordion = ({
  children,
  allowMultipleExpanded = false,
  allowZeroExpanded = true,
  skin = 'default',
  ...props
}: KitAccordionProps) => {
  return (
    <KitAccordion
      allowMultipleExpanded={allowMultipleExpanded}
      allowZeroExpanded={allowZeroExpanded}
      skin={skin}
      {...props}
    >
      {children}
    </KitAccordion>
  );
};

export default Accordion;
