import useTranslation from 'next-translate/useTranslation';
import { InputRadioLabel } from 'components/organisms/FormsSlice/Forms/Forms';
import { RadioInputProps } from './RadioInput.types';
import { StyledInputRadio } from './RadioInput.styles';

export const RadioInput: React.FC<RadioInputProps> = ({
  id,
  name,
  register,
  translation,
  value,
  validationOptions,
  onClick,
}) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div>
        <StyledInputRadio
          id={id}
          name={name}
          type="radio"
          value={value}
          {...register(name, validationOptions)}
          onClick={onClick}
        />
        <InputRadioLabel htmlFor={id}>{t(translation)}</InputRadioLabel>
      </div>
    </>
  );
};
