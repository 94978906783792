import { styled, media } from '@nx-kit/styling';
import { RichText } from 'components/atoms/RichText';
import { FooterOpeningHoursProps } from 'components/organisms/Footer/Footer.types';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;

  ${media('sm')} {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${media('md')} {
    width: 284px;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const RichTextStyled = styled(RichText)`
  color: white;
`;

export const FooterOpeningHours: React.FC<FooterOpeningHoursProps> = ({ primary }) => {
  return (
    <Wrapper>
      <RichTextStyled rich_text={primary['opening-hours-text']} />
    </Wrapper>
  );
};
