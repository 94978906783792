import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import { DefaultForm } from 'components/molecules/DefaultForm';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { GutscheinFormInputTypes, FormParentProps } from './GutscheinForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { TextInput } from 'components/atoms/TextInput';
import { RadioInput } from 'components/atoms/RadioInput';
import { Heading } from 'components/atoms/Heading';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { media, styled } from '@nx-kit/styling';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import { DomainContext } from 'contexts/domain/DomainContext';
import { SalutationEnum } from 'helpers/forms/forms.types';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const Wrapper = styled.div`
  ${media('sm')} {
    display: flex;
  }
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }

    ${media('sm')} {
      padding-top: 0;
    }
  }
`;

export const GutscheinForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<GutscheinFormInputTypes>();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: GutscheinFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormColumn span={12}>
                <CheckboxInput
                  name="giftVoucher"
                  register={register}
                  translation="gutscheinForm.labels.giftVoucher"
                  errorMessageRequired="gutscheinForm.errors.giftVoucherRequired"
                  hasError={errors.giftVoucher !== undefined}
                  validationOptions={validationOptions.giftVoucher}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessagePattern="gutscheinForm.errors.voucherAmountInvalid"
                  errorMessageRequired="gutscheinForm.errors.voucherAmountRequired"
                  hasError={errors.voucherAmount !== undefined}
                  label="gutscheinForm.labels.voucherAmount"
                  name="voucherAmount"
                  placeholder="gutscheinForm.placeholders.voucherAmount"
                  register={register}
                  validationOptions={validationOptions.voucherAmount}
                />
              </FormColumn>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('gutscheinForm.labels.voucherRecipient')}
                </Heading>
                <Wrapper>
                  <RadioInput
                    id="salutationFemale"
                    name="voucherSalutation"
                    register={register}
                    translation="gutscheinForm.labels.salutation.female"
                    value={SalutationEnum.female}
                  />
                  <RadioInput
                    id="salutationMale"
                    name="voucherSalutation"
                    register={register}
                    translation="gutscheinForm.labels.salutation.male"
                    value={SalutationEnum.male}
                  />
                </Wrapper>
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="gutscheinForm.labels.firstName"
                  name="voucherFirstName"
                  placeholder="gutscheinForm.placeholders.firstName"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="gutscheinForm.labels.lastName"
                  name="voucherLastName"
                  placeholder="gutscheinForm.placeholders.lastName"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="gutscheinForm.labels.voucherStreet"
                  name="voucherStreet"
                  placeholder="gutscheinForm.placeholders.voucherStreet"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="gutscheinForm.labels.voucherZipCode"
                  name="voucherZipCode"
                  placeholder="gutscheinForm.placeholders.voucherZipCode"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="gutscheinForm.labels.voucherCity"
                  name="voucherCity"
                  placeholder="gutscheinForm.placeholders.voucherCity"
                  register={register}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  label="gutscheinForm.labels.voucherCountry"
                  name="voucherCountry"
                  placeholder="gutscheinForm.placeholders.voucherCountry"
                  register={register}
                />
              </FormColumn>
              <DefaultForm
                errors={errors}
                register={register}
                alternativHeadline={t('gutscheinForm.labels.customerHeadline')}
                isSubmitted={isSubmitted}
              />
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('contactForm.labels.yourMessage')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('message')}
                  id="message"
                  placeholder={t('contactForm.placeholders.message')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input type="hidden" value="14-GutscheinSchenken" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
