import { getHost } from 'helpers/host';
import isClient from 'helpers/isClient';
import { ResponseCourseItem } from 'pages/api/courseApi.types';

export interface FetchProps {
  domain: string;
  query: string;
}

export interface ApiFetchCourseResponse {
  data: ResponseCourseItem[];
}

export const fetchCourses = async ({
  domain,
  query,
}: FetchProps): Promise<ApiFetchCourseResponse> => {
  try {
    const url = `${getHost(domain)}/api/courseApi${query}`;

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'cache-control': 'no-cache',
      },
    };

    if (!isClient()) {
      // @ts-ignore
      options.headers.Authorization =
        'Basic ' +
        Buffer.from('ballenberg' + ':' + process.env.BASIC_AUTH_PASSWORD).toString('base64');
    }

    const response = await fetch(url, options);
    return await response.json();
  } catch (error) {
    throw { name: 'APiPrismicError', error };
  }
};
