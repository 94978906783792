export enum SalutationEnum {
  family = 'Familie',
  female = 'Frau',
  male = 'Herr',
}

export enum ProbateEnum {
  probateDocu = 'Dokumentation zusenden',
  probateContact = 'persönliches Gespräch',
}

export enum CheckBoxLinkTypEnum {
  agb = 'AGB',
  newsletter = 'Newsletter',
}
