import { media, styled } from '@nx-kit/styling';

export const Wrapper = styled.div`
  ${media('sm')} {
    display: flex;
  }
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }

    ${media('sm')} {
      padding-top: 0;
    }
  }
`;
