import { media, styled } from '@nx-kit/styling';

export const WrapperRBNumberOfEntries = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    align-self: flex-end;
    width: 100%;
    ${media('md')} {
      width: 45%;
    }
  }
`;

export const WrapperRBSalutations = styled.div`
  ${media('sm')} {
    display: flex;
  }
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }

    ${media('sm')} {
      padding-top: 0;
    }
  }
`;

export const DateGroupStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;

  > * {
    width: 100%;
    ${media('md')} {
      width: 45%;
    }
  }

  div div button {
    height: 57px;
  }
`;

export const WrapperRBArrivalPlace = styled.div`
  ${media('sm')} {
    display: flex;
  }
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }

    ${media('sm')} {
      padding-top: 0;
    }
  }
`;

export const WrapperRBPayment = styled.div`
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }
  }
`;
