import { media, styled } from '@nx-kit/styling';

export const ColorWrapper = styled.div`
  > * {
    background-color: ${({ theme }) => theme.global.color.white};
    padding: 50px 60px;
    border-radius: 8px;
  }
`;

export const WrapperCourseDetails = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    &:first-child {
      width: 30%;
    }

    &:last-child {
      width: 70%;
      padding-left: 40px;
    }
  }
`;

export const Wrapper = styled.div`
  ${media('sm')} {
    display: flex;
  }
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }

    ${media('sm')} {
      padding-top: 0;
    }
  }
`;

export const WrapperPhoneNumber = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    align-self: flex-end;
    width: 100%;
    ${media('md')} {
      width: 20%;
    }
    &:last-child {
      ${media('md')} {
        width: 40%;
      }
    }
  }
`;

export const CourseInfo = styled.div`
  ${media('sm')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  > * {
    margin-bottom: 8px;

    ${media('sm')} {
      &:nth-of-type(odd) {
        width: 25%;
      }

      &:nth-of-type(even) {
        width: 75%;
        padding-left: 40px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ImageStyled = styled.div`
  display: block;
  justify-self: stretch;
  align-self: flex-start;

  img {
    border-radius: 8px;
    aspect-ratio: 1 / 1;
  }
`;
