import { styled } from '@nx-kit/styling';
import Picture from 'components/atoms/Picture/Picture';
import { Heading } from 'components/atoms/Heading';
import { ImageStyled, StyledText, StyledIcon } from './TagesplanSliderItem.styles';
import { TagesplanSliderItemProps } from './TagesplanSliderItem.types';
import { scheduleSliderItem } from 'themes/components/slider';

const Wrapper = styled.div`
  ${scheduleSliderItem}
`;

const TagesplanSliderItem = ({
  link,
  image,
  text,
  subtext,
  className,
}: TagesplanSliderItemProps) => {
  if (!link) {
    return null;
  }
  return (
    <Wrapper className={className}>
      <ImageStyled>
        {image && <Picture noMinHeight image={image} objectFit size="half" />}
      </ImageStyled>
      <Heading className="text" skin="500" elementType="span" styles={{ marginBottom: 5 }}>
        {text}
      </Heading>
      <StyledText elementType="div" styles={{ color: 'gray900' }}>
        {subtext && <StyledIcon name="CLOCK" />}
        {subtext}
      </StyledText>
    </Wrapper>
  );
};

export default TagesplanSliderItem;
