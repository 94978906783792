import { gql } from '@apollo/client';

export const allContactPersonsQuery = gql`
  query Contact_persons($contactPersonUids: [String!], $language: String) {
    allContact_persons(where: { language: $language }, uid_in: $contactPersonUids) {
      edges {
        node {
          language
          order
          image
          first_name
          last_name
          job_description
          phone_number
          email_address
          _linkType
        }
      }
    }
  }
`;
