import { newsletterSubmitHandler } from './Forms/NewsletterForm/Newsletter.helper';

export interface GenericSubmitHandlerProps {
  data: any;
}
export interface FetchSendFormResponse {
  data: any;
}

export const genericSubmitHandler = async ({
  data,
}: GenericSubmitHandlerProps): Promise<FetchSendFormResponse> => {
  const formData = new FormData();

  if (data.uploadPicture1) {
    formData.append('uploadPicture1', data.uploadPicture1[0]);
  }
  if (data.uploadPicture2) {
    formData.append('uploadPicture2', data.uploadPicture2[0]);
  }
  if (data.uploadPicture3) {
    formData.append('uploadPicture3', data.uploadPicture3[0]);
  }

  Object.keys(data).forEach((dataKey) => {
    if (dataKey !== ('uploadPicture1' || 'uploadPicture2' || 'uploadPicture3')) {
      formData.append(dataKey, data[dataKey]);
    }
  });

  try {
    const options = {
      method: 'POST',
      body: formData,
    };
    const responseNodemailer = await fetch('/api/nodemailer', options);
    if (data.newsletterSubscribe) {
      await newsletterSubmitHandler({ data });
    }
    return {
      data: responseNodemailer,
    };
  } catch (error) {
    throw error;
  }
};
