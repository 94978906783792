import { styled } from '@nx-kit/styling';
import { Text } from '@nx-kit/text';
import Icon from 'components/atoms/Icon/Icon';

export const ImageStyled = styled.div`
  min-height: 184px;
  height: 184px;
  width: 100%;
  margin-bottom: 12px;
  img {
    border-radius: 8px;
  }
`;

export const StyledText = styled(Text)`
  display: flex;
`;
export const StyledIcon = styled(Icon)`
  font-size: 25px;
  color: ${({ theme }) => theme.global.color.primary};
  margin-right: 8px;
`;
