import { styled } from '@nx-kit/styling';
import { Swiper } from 'swiper/react';

export const SwiperWrapper = styled(Swiper)`
  .swiper-slide {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    margin: 0;
  }
`;
