import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
  StyledLabel,
} from 'components/organisms/FormsSlice/Forms/Forms';
import {
  TischreservationenIndividualFormInputTypes,
  FormParentProps,
} from './TischreservationenSingleForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { DefaultForm } from 'components/molecules/DefaultForm';
import { TextInput } from 'components/atoms/TextInput';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { RadioInput } from 'components/atoms/RadioInput';
import { Heading } from 'components/atoms/Heading';
import { SelectInput } from 'components/atoms/SelectInput';
import { FormDatePicker } from 'components/atoms/FormDatePicker';
import {
  DateGroupStyled,
  TimeInput,
  WrapperRBPayment,
} from './TischreservationenSingleForm.styles';
import { DomainContext } from 'contexts/domain/DomainContext';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const defaultValues = {
  payment: 'Bar',
};

export const TischreservationenIndividualForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<TischreservationenIndividualFormInputTypes>({ defaultValues });
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: TischreservationenIndividualFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      console.log(data);
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  const optionsReasonVisit = [
    { value: 'Mittagessen', label: t('tableReservationForm.labels.reasonVisit.option1') },
    { value: 'Apéro', label: t('tableReservationForm.labels.reasonVisit.option2') },
    { value: 'Znüni/Zvieri', label: t('tableReservationForm.labels.reasonVisit.option3') },
    {
      value: 'Abendessen (ab 30 Personen)',
      label: t('tableReservationForm.labels.reasonVisit.option4'),
    },
  ];

  const optionsDesiredLocation = [
    {
      value: 'Wirtshaus «Alter Bären»',
      label: t('tableReservationForm.labels.desiredLocation.option1'),
    },
    { value: 'Gasthaus «Degen»', label: t('tableReservationForm.labels.desiredLocation.option2') },
    {
      value: 'Gasthof «Wilerhorn»',
      label: t('tableReservationForm.labels.desiredLocation.option3'),
    },
    { value: 'Osteria Novazzano', label: t('tableReservationForm.labels.desiredLocation.option4') },
    {
      value: 'Im Ballenberg-Gelände',
      label: t('tableReservationForm.labels.desiredLocation.option5'),
    },
  ];

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <FormColumn span={12}>
                <HeadlineLabel>{t('tableReservationForm.labels.generalInfo')}</HeadlineLabel>
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  hasError={errors.nameGroup !== undefined}
                  label="tableReservationForm.labels.nameGroup"
                  name="nameGroup"
                  placeholder="tableReservationForm.placeholders.nameGroup"
                  register={register}
                />
              </FormColumn>
              <DefaultForm errors={errors} register={register} isSubmitted={isSubmitted} />
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('tableReservationForm.labels.detailVisit')}
              </Heading>
              <DateGroupStyled>
                <FormColumn span={12}>
                  <StyledLabel htmlFor={'visitDate'}>
                    {t('tableReservationForm.labels.visitDate')}
                  </StyledLabel>
                  <FormDatePicker
                    name="visitDate"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setValue('visitDate', date!);
                    }}
                    placeholder="tableReservationForm.placeholders.visitDate"
                    validationOptions={validationOptions.visitDate}
                    errorMessageRequired="tableReservationForm.errors.visitDateRequired"
                    hasError={errors.visitDate !== undefined}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TimeInput
                    errorMessageRequired="tableReservationForm.errors.visitTimeRequired"
                    hasError={errors.visitTime !== undefined}
                    label="tableReservationForm.labels.visitTime"
                    name="visitTime"
                    placeholder="tableReservationForm.placeholders.visitTime"
                    register={register}
                    validationOptions={validationOptions.visitTime}
                  />
                </FormColumn>
              </DateGroupStyled>
              <FormColumn span={12}>
                <SelectInput
                  options={optionsReasonVisit}
                  label="tableReservationForm.labels.reasonVisitTitle"
                  placeholder="tableReservationForm.placeholders.selectReasonVisit"
                  name="reasonVisit"
                  errorMessageRequired="tableReservationForm.errors.reasonVisitRequired"
                  hasError={errors.reasonVisit !== undefined}
                  register={register}
                  validationOptions={validationOptions.reasonVisit}
                />
              </FormColumn>
              <FormColumn span={12}>
                <SelectInput
                  options={optionsDesiredLocation}
                  label="tableReservationForm.labels.desiredLocationTitle"
                  placeholder="tableReservationForm.placeholders.desiredLocation"
                  name="desiredLocation"
                  errorMessageRequired="tableReservationForm.errors.desiredLocationRequired"
                  hasError={errors.desiredLocation !== undefined}
                  register={register}
                  validationOptions={validationOptions.desiredLocation}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="tableReservationForm.errors.adultRequired"
                  hasError={errors.adult !== undefined}
                  label="tableReservationForm.labels.adult"
                  name="adult"
                  placeholder="tableReservationForm.placeholders.adult"
                  register={register}
                  validationOptions={validationOptions.adult}
                />
              </FormColumn>
              <FormColumn span={12}>
                <TextInput
                  errorMessageRequired="tableReservationForm.errors.childrenRequired"
                  hasError={errors.children !== undefined}
                  label="tableReservationForm.labels.children"
                  name="children"
                  placeholder="tableReservationForm.placeholders.children"
                  register={register}
                  validationOptions={validationOptions.children}
                />
              </FormColumn>
              <FormColumn span={12}>
                <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                  {t('tableReservationForm.labels.paymentTitle')}
                </Heading>
                <WrapperRBPayment>
                  <RadioInput
                    id="paymentCash"
                    name="payment"
                    register={register}
                    translation="tableReservationForm.labels.payment.cash"
                    value="Bar"
                  />
                  <RadioInput
                    id="paymentCard"
                    name="payment"
                    register={register}
                    translation="tableReservationForm.labels.payment.card"
                    value="Kreditkarte/Postcard"
                  />
                  <RadioInput
                    id="paymentInvoice"
                    name="payment"
                    register={register}
                    translation="tableReservationForm.labels.payment.invoice"
                    value="Rechnung"
                  />
                </WrapperRBPayment>
              </FormColumn>
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('tableReservationForm.labels.yourMessage')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('message')}
                  id="message"
                  placeholder={t('tableReservationForm.placeholders.yourMessage')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input
                type="hidden"
                value="10-TischreservationenIndividual"
                {...register('category')}
              />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
