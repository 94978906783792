import { Event_ItemConnectionEdge } from 'types/generated/graphql';
import { EventSliderItemProps } from './EventSliderItem.types';
import { getFormattedDateAndTime } from 'helpers/date';

export const mapEdgeItemToEventBoxItem = (
  locale: string,
  edgeItem: Event_ItemConnectionEdge
): EventSliderItemProps => {
  const node = edgeItem.node;

  let link = null;

  if (node._linkType && node._meta.uid) {
    link = {
      link_type: node._linkType,
      uid: node._meta.uid,
      lang: locale,
    };
  }

  const dateItems = node.body?.[0]?.fields || [];

  const { date, dateStr, timeStr } = getFormattedDateAndTime(
    dateItems?.[0]?.event_start,
    locale,
    dateItems.length > 0
  );

  return {
    text: node?.display_name[0]?.text || '',
    image: node?.thumbnail,
    _dateToSort: date,
    date: dateStr,
    time: timeStr,
    link,
  };
};

export const mapMultipleItems = (locale: string, originalItem: any): any => {
  let link: { link_type: string; uid: string; lang: string } | null = null;

  if (originalItem.node._linkType && originalItem.node._meta.uid) {
    link = {
      link_type: originalItem.node._linkType,
      uid: originalItem.node._meta.uid,
      lang: locale,
    };
  }

  if (originalItem && originalItem.node.body && originalItem.node.body.length > 1) {
    return originalItem.node.body.map((entry: { fields: any[] }) => {
      const dateItems = entry.fields?.[0];

      const { date, dateStr, timeStr } = getFormattedDateAndTime(
        dateItems?.event_start,
        locale,
        dateItems
      );

      return {
        text: originalItem.node.display_name[0]?.text || '',
        image: originalItem.node.thumbnail,
        _dateToSort: date,
        date: dateStr,
        time: timeStr,
        link,
      };
    });
  }
};

export const mapTagesplanEdgeItemToEventBoxItem = (
  locale: string,
  edgeItem: Event_ItemConnectionEdge
): EventSliderItemProps => {
  const node = edgeItem.node;

  let link = null;

  if (node._linkType && node._meta.uid) {
    link = {
      link_type: node._linkType,
      uid: node._meta.uid,
      lang: locale,
    };
  }

  const dateItems = node.body?.[0]?.fields || [];

  const { dateStr, timeStr } = getFormattedDateAndTime(
    dateItems?.[0]?.event_start,
    locale,
    dateItems.length > 0
  );

  return {
    text: node?.display_name[0]?.text || '',
    image: node?.thumbnail,
    date: dateStr,
    time: timeStr,
    link,
  };
};
