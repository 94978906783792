import { styled } from '@nx-kit/styling';
import Button from 'components/atoms/Button/Button';
import { Heading } from 'components/atoms/Heading';

export const HeadingStyled = styled(Heading)`
  margin-left: 20px;
`;

export const SuccessText = styled.div`
  margin-bottom: 30px;
`;

export const TextAndIcons = styled.div`
  display: flex;
`;

export const ButtonStyled = styled(Button)`
  width: auto;
  height: 40px;
  justify-content: center;
`;
