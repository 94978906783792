import { useRef } from 'react';
import { rgba } from 'polished';
import { styled } from '@nx-kit/styling';
import Column from 'components/layout/Grid/Column';
import Container from 'components/layout/Grid/Container';
import Icon from 'components/atoms/Icon/Icon';
import Picture from 'components/atoms/Picture/Picture';
import { RichText } from 'components/atoms/RichText';
import Slider, { ItemRef } from 'components/molecules/Slider/Slider';
import { ImageSliderProps } from './ImageSlider.types';

const StyledPicture = styled(Picture)`
  &&& {
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
`;

const SliderContainer = styled.div`
  position: relative;
`;

const SliderNavButtonPrev = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => rgba(theme.global.color.primary, 0.5)};
  border-radius: 20px;
  position: absolute;
  z-index: ${({ theme }) => theme.global.zIndex.sliderNavButtons};
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20px;
  right: inherit;
  transition: background-color ease-in 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.global.color.primary};
  }

  &.sliderButton_disabled {
    opacity: 0.3;
    &:hover {
      background-color: ${({ theme }) => rgba(theme.global.color.primary, 0.5)};
    }
  }
`;

const StyledRichText = styled(RichText)`
  font-size: ${({ theme }) => theme.global.fontSize.small}px;
`;

const SliderNavButtonNext = styled(SliderNavButtonPrev)`
  left: inherit;
  right: 20px;
`;

const NextIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  margin-left: 2px;
  color: ${({ theme }) => theme.global.color.white};
`;

const PrevIcon = styled(NextIcon)`
  margin-left: -2px;
  transform: rotate(180deg);
`;

export const ImageSlider: React.FC<ImageSliderProps> = ({ className, primary, items }) => {
  const isBig = primary.size;

  const sliderRef = useRef(null);

  return (
    <Container className={className}>
      <Column start={{ xs: 1, lg: isBig ? 1 : 3 }} span={{ xs: 12, lg: isBig ? 12 : 8 }}>
        {items.length === 1 && 'url' in items[0]?.image && (
          <>
            <StyledPicture image={items[0].image} noMinHeight={true} />
            {items[0].image_caption.length > 0 && (
              <StyledRichText rich_text={items[0].image_caption} />
            )}
          </>
        )}
        {items.length > 1 && (
          <SliderContainer>
            <Slider itemRef={sliderRef as ItemRef} slidesPerView={1} navigation={false}>
              {items.map((item) => {
                if ('url' in item.image) {
                  return (
                    <div key={item.image.url}>
                      <StyledPicture image={item.image} noMinHeight={true} />
                      {item.image_caption.length > 0 && (
                        <StyledRichText rich_text={item.image_caption} />
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </Slider>

            <SliderNavButtonPrev
              onClick={() => {
                if (!sliderRef.current) return;
                // @ts-ignore
                sliderRef.current.swiper.slidePrev(500);
              }}
            >
              <PrevIcon name="ARROW_BOLD" />
            </SliderNavButtonPrev>
            <SliderNavButtonNext
              onClick={() => {
                if (!sliderRef.current) return;
                // @ts-ignore
                sliderRef.current.swiper.slideNext(500);
              }}
            >
              <NextIcon name="ARROW_BOLD" />
            </SliderNavButtonNext>
          </SliderContainer>
        )}
      </Column>
    </Container>
  );
};
