import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  FormError,
  InputSubmit,
  StyledLabel,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { KursanmeldungFormInputTypes, FormParentProps } from './KursanmeldungForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { TextInput } from 'components/atoms/TextInput';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { RadioInput } from 'components/atoms/RadioInput';
import { Heading } from 'components/atoms/Heading';
import { courseRegistrationSubmitHandler } from './Kursanmeldung.helper';
import { SelectInput } from 'components/atoms/SelectInput';
import { useRouter } from 'next/router';
import { DomainContext } from 'contexts/domain/DomainContext';
import { fetchCourses } from 'prismic/api/courses';
import { useQuery } from '@tanstack/react-query';
import { ResponseCourseItem } from 'pages/api/courseApi.types';
import Picture from 'components/atoms/Picture/Picture';
import {
  ColorWrapper,
  CourseInfo,
  ImageStyled,
  Wrapper,
  WrapperCourseDetails,
  WrapperPhoneNumber,
} from './KursanmeldungForm.styles';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import { CheckBoxLinkTypEnum } from 'helpers/forms/forms.types';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const defaultValues = {
  landIdCourseRegistration: 0,
};

export const KursanmeldungForm: React.FC<FormParentProps> = ({
  introRichText,
  titleSuccessRegistration,
  textSuccessRegistration,
  titleRegistrationWithWaitingList,
  textRegistrationWithWaitingList,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<KursanmeldungFormInputTypes>({ defaultValues });
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);
  const urlAGB = process.env.NEXT_PUBLIC_URL_AGB_BALLENBERGKURSE_DE;

  const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(true);

  const submitHandler = async (data: KursanmeldungFormInputTypes) => {
    try {
      const response = await courseRegistrationSubmitHandler({ data });
      setShowForm(false);
      if (response.data.IsOnWarteliste) {
        setRegistrationSuccessful(false);
      }
      if (!response.successfulRegistration) {
        if (response.courseRegistrationError) {
          setShowForm(true);
          toast.error(response.error.Message);
        } else {
          console.error(response.error.message);
          setShowForm(true);
          toast.error(t('forms.responses.400'));
        }
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const router = useRouter();
  const { query } = router;
  const singleQueryCourseNr = Array.isArray(query.course) ? query.course[0] : query.course;
  const courseNr = singleQueryCourseNr ? singleQueryCourseNr : '0';

  let spracheId = 0;

  if (lang === 'de') {
    spracheId = 1;
  } else if (lang === 'fr') {
    spracheId = 2;
  } else if (lang === 'it') {
    spracheId = 3;
  } else {
    spracheId = 4;
  }

  const fetchQuery = async () => {
    let queryArray: string[] = [];
    let queryString = '';

    queryArray.push(`anlassnr=${courseNr}`);

    queryString = '?' + queryArray.join('&');
    return await fetchCourses({ domain, query: queryString });
  };

  const { data, error, isLoading } = useQuery(['kurseHome'], fetchQuery);

  let courseDetails: ResponseCourseItem[] = [];

  const getError = (error: unknown): string => {
    console.error('[KursAnmeldung]', error);
    return t('apiErrors.errorDefaultMessage');
  };

  if (data?.data) {
    data.data.forEach((kursItem) => {
      courseDetails.push(kursItem);
    });
  }

  if (courseDetails.length === 0) {
    return null;
  }

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
    setRegistrationSuccessful(true);
  };

  const optionsCountry = [
    { value: '1', label: t('courseRegistrationForm.labels.optionsCountry.option1') },
    { value: '2', label: t('courseRegistrationForm.labels.optionsCountry.option2') },
    { value: '3', label: t('courseRegistrationForm.labels.optionsCountry.option3') },
    { value: '4', label: t('courseRegistrationForm.labels.optionsCountry.option4') },
    { value: '0', label: t('courseRegistrationForm.labels.optionsCountry.option0') },
  ];

  return (
    <>
      {!showForm && (
        <ColorWrapper>
          <Column span={12}>
            <FormColumn span={12}>
              <FormSuccessNotification
                onClickReturn={onClickReturn}
                titleNotification={
                  registrationSuccessful
                    ? titleSuccessRegistration
                    : titleRegistrationWithWaitingList
                }
                textNotification={
                  registrationSuccessful ? textSuccessRegistration : textRegistrationWithWaitingList
                }
              />
            </FormColumn>
          </Column>
        </ColorWrapper>
      )}
      {showForm && (
        <>
          <ColorWrapper>
            {!isIntroRichTextEmpty && (
              <FormColumn span={12}>
                <RichText rich_text={introRichText} />
              </FormColumn>
            )}
            <div style={{ marginBottom: 30 }}>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('courseRegistrationForm.labels.courseDetails')}
              </Heading>
              <WrapperCourseDetails>
                <>
                  {error && <div>{getError(error)}</div>}
                  {isLoading && <Loading />}
                  <ImageStyled>
                    {courseDetails[0].image && (
                      <Picture noMinHeight image={courseDetails[0].image} objectFit />
                    )}
                  </ImageStyled>
                  <CourseInfo>
                    <div style={{ fontWeight: 'bold' }}>
                      {t('courseRegistrationForm.labels.courseNr')}
                    </div>
                    <div>{`${courseDetails[0]._anlassNr}`}</div>
                    <div style={{ fontWeight: 'bold' }}>
                      {t('courseRegistrationForm.labels.courseTitle')}
                    </div>
                    <div>{`${courseDetails[0].title}`}</div>
                    <div style={{ fontWeight: 'bold' }}>
                      {t('courseRegistrationForm.labels.courseDate')}
                    </div>
                    <div>{courseDetails[0].date}</div>
                    <div style={{ fontWeight: 'bold' }}>
                      {t('courseRegistrationForm.labels.courseTime')}
                    </div>
                    <div>{courseDetails[0].time}</div>
                    <div style={{ fontWeight: 'bold' }}>
                      {t('courseRegistrationForm.labels.coursePrice')}
                    </div>
                    <div>{courseDetails[0].price_format}</div>
                    <div style={{ fontWeight: 'bold' }}>
                      {t('courseRegistrationForm.labels.courseMaterialCosts')}
                    </div>
                    <div>{courseDetails[0].info}</div>
                  </CourseInfo>
                </>
              </WrapperCourseDetails>
            </div>
            <Column span={12}>
              <form onSubmit={handleSubmit(submitHandler)}>
                <FormColumn span={12}>
                  <Heading
                    skin={getHeadingSkin('heading3')}
                    elementType={getHeadingTag('heading3')}
                  >
                    {t('contactForm.labels.yourInformation')}
                  </Heading>
                  <Wrapper>
                    <RadioInput
                      id="salutationFemale"
                      name="anredeIdCourseRegistration"
                      register={register}
                      translation="courseRegistrationForm.labels.salutation.2"
                      value="2"
                    />
                    <RadioInput
                      id="salutationMale"
                      name="anredeIdCourseRegistration"
                      register={register}
                      translation="courseRegistrationForm.labels.salutation.1"
                      value="1"
                      validationOptions={validationOptions.anredeIdCourseRegistration}
                    />
                  </Wrapper>
                  {errors.anredeIdCourseRegistration && isSubmitted && (
                    <FormError className="anredeIdCourseRegistrationError">
                      {t('contactForm.errors.salutationRequired')}
                    </FormError>
                  )}
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessagePattern="courseRegistrationForm.errors.firstNameCourseRegistrationInvalid"
                    errorMessageRequired="contactForm.errors.firstNameRequired"
                    hasError={errors.Vorname !== undefined}
                    label="contactForm.labels.firstName"
                    name="Vorname"
                    placeholder="contactForm.placeholders.firstName"
                    register={register}
                    validationOptions={validationOptions.firstNameCourseRegistration}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessagePattern="courseRegistrationForm.errors.lastNameCourseRegistrationInvalid"
                    errorMessageRequired="contactForm.errors.lastNameRequired"
                    hasError={errors.Nachname !== undefined}
                    label="contactForm.labels.lastName"
                    name="Nachname"
                    placeholder="contactForm.placeholders.lastName"
                    register={register}
                    validationOptions={validationOptions.lastNameCourseRegistration}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessagePattern="courseRegistrationForm.errors.streetCourseRegistrationInvalid"
                    errorMessageRequired="courseRegistrationForm.errors.streetCourseRegistrationRequired"
                    hasError={errors.Strasse !== undefined}
                    label="courseRegistrationForm.labels.street"
                    name="Strasse"
                    placeholder="contactForm.placeholders.street"
                    register={register}
                    validationOptions={validationOptions.streetCourseRegistration}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessagePattern="courseRegistrationForm.errors.zipCodeCourseRegistrationInvalid"
                    errorMessageRequired="courseRegistrationForm.errors.zipCodeCourseRegistrationRequired"
                    hasError={errors.PLZ !== undefined}
                    label="courseRegistrationForm.labels.zipCode"
                    name="PLZ"
                    placeholder="contactForm.placeholders.zipCode"
                    register={register}
                    validationOptions={validationOptions.zipCodeCourseRegistration}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <TextInput
                    errorMessagePattern="courseRegistrationForm.errors.cityCourseRegistrationInvalid"
                    errorMessageRequired="courseRegistrationForm.errors.cityCourseRegistrationRequired"
                    hasError={errors.Ort !== undefined}
                    label="courseRegistrationForm.labels.city"
                    name="Ort"
                    placeholder="contactForm.placeholders.city"
                    register={register}
                    validationOptions={validationOptions.cityCourseRegistration}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <SelectInput
                    options={optionsCountry}
                    label="contactForm.labels.country"
                    placeholder="contactForm.placeholders.country"
                    name="landIdCourseRegistration"
                    register={register}
                    errorMessageRequired="courseRegistrationForm.errors.landIdCourseRegistrationRequired"
                    hasError={errors.landIdCourseRegistration !== undefined}
                    validationOptions={validationOptions.landIdCourseRegistration}
                  />
                </FormColumn>
                <WrapperPhoneNumber>
                  <FormColumn span={12}>
                    <TextInput
                      label="courseRegistrationForm.labels.phoneCountry"
                      name="TelefonLand"
                      placeholder="courseRegistrationForm.placeholders.phoneCountry"
                      register={register}
                    />
                  </FormColumn>
                  <FormColumn span={12}>
                    <TextInput
                      label="courseRegistrationForm.labels.phoneAreaCode"
                      name="TelefonVorwahl"
                      placeholder="courseRegistrationForm.placeholders.phoneAreaCode"
                      register={register}
                    />
                  </FormColumn>
                  <FormColumn span={12}>
                    <TextInput
                      label="courseRegistrationForm.labels.phoneMain"
                      name="Telefon"
                      placeholder="courseRegistrationForm.placeholders.phoneMain"
                      register={register}
                    />
                  </FormColumn>
                </WrapperPhoneNumber>
                <FormColumn span={12}>
                  <TextInput
                    errorMessagePattern="contactForm.errors.eMailInvalid"
                    errorMessageRequired="contactForm.errors.eMailRequired"
                    hasError={errors.eMail !== undefined}
                    label="contactForm.labels.eMail"
                    name="eMail"
                    placeholder="contactForm.placeholders.eMail"
                    register={register}
                    validationOptions={validationOptions.eMail}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <StyledLabel htmlFor={'Zusatzangebot1'}>
                    {t('courseRegistrationForm.labels.headlinePatron')}
                  </StyledLabel>
                  <CheckboxInput
                    name="Zusatzangebot1"
                    register={register}
                    translation="courseRegistrationForm.labels.patron"
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <CheckboxInput
                    name="cancellationInsurance"
                    register={register}
                    translation="courseRegistrationForm.labels.cancellationInsurance"
                    errorMessageRequired="courseRegistrationForm.errors.cancellationInsuranceRequired"
                    hasError={errors.cancellationInsurance !== undefined}
                    validationOptions={validationOptions.cancellationInsurance}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <CheckboxInput
                    name="agb"
                    register={register}
                    translation="courseRegistrationForm.labels.agb"
                    errorMessageRequired="courseRegistrationForm.errors.agbRequired"
                    hasError={errors.agb !== undefined}
                    validationOptions={validationOptions.agb}
                    url={urlAGB}
                    linkTyp={CheckBoxLinkTypEnum.agb}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  <CheckboxInput
                    name="newsletterSubscribe"
                    register={register}
                    translation="contactForm.labels.newsletterSubscribe"
                    url={urlNewsletter}
                  />
                </FormColumn>
                <FormColumn span={12}>
                  {!isSubmitting && (
                    <InputSubmit
                      disabled={isSubmitting}
                      type="submit"
                      value={t('contactForm.labels.submit')}
                    />
                  )}
                  {isSubmitting && <Loading />}
                </FormColumn>
                <FormColumn span={12}>
                  <Copytext textstring={t('forms.requiredFields')} />
                </FormColumn>
                <input type="hidden" value={courseDetails[0]._anlassId} {...register('AnlassId')} />
                <input type="hidden" value={2} {...register('AdresseTyp')} />
                <input type="hidden" value={spracheId} {...register('SpracheId')} />
                <input type="hidden" value={courseDetails[0].title} {...register('courseTitle')} />
                <input type="hidden" value={courseDetails[0].date} {...register('courseDate')} />
              </form>
            </Column>
          </ColorWrapper>
        </>
      )}
    </>
  );
};
