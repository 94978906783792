import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import { DefaultForm } from 'components/molecules/DefaultForm';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
} from 'components/organisms/FormsSlice/Forms/Forms';
import {
  HandwerkspatenschaftFormInputTypes,
  FormParentProps,
} from './HandwerkspatenschaftForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { Heading } from 'components/atoms/Heading';
import { RadioInput } from 'components/atoms/RadioInput';
import { DomainContext } from 'contexts/domain/DomainContext';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';
import { TextInput } from 'components/atoms/TextInput';
import {
  WrapperMember,
  WrapperMemberAmount,
  WrapperMemberHandcraft,
} from './HandwerkspatenschaftForm.styles';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';

export const HandwerkspatenschaftForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<HandwerkspatenschaftFormInputTypes>();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: HandwerkspatenschaftFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('handwerkspatenschaftForm.labels.handwerke')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMemberHandcraft>
                  <div>
                    <CheckboxInput
                      name="handwerk1"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk1"
                    />
                    <CheckboxInput
                      name="handwerk2"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk2"
                    />
                    <CheckboxInput
                      name="handwerk3"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk3"
                    />
                    <CheckboxInput
                      name="handwerk4"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk4"
                    />
                    <CheckboxInput
                      name="handwerk6"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk6"
                    />
                  </div>
                  <div>
                    <CheckboxInput
                      name="handwerk7"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk7"
                    />
                    <CheckboxInput
                      name="handwerk8"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk8"
                    />
                    <CheckboxInput
                      name="handwerk9"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk9"
                    />
                    <CheckboxInput
                      name="handwerk11"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk11"
                    />
                    <CheckboxInput
                      name="handwerk12"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk12"
                    />
                  </div>
                  <div>
                    <CheckboxInput
                      name="handwerk10"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk10"
                    />
                    <CheckboxInput
                      name="handwerk13"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk13"
                    />
                    <CheckboxInput
                      name="handwerk14"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk14"
                    />
                    <CheckboxInput
                      name="handwerk5"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk5"
                    />
                    <CheckboxInput
                      name="handwerk15"
                      register={register}
                      translation="handwerkspatenschaftForm.labels.handwerk15"
                    />
                  </div>
                </WrapperMemberHandcraft>
              </FormColumn>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('handwerkspatenschaftForm.labels.betragprohandwerke')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMemberAmount>
                  <RadioInput
                    id="betragprohandwerk1"
                    name="betragprohandwerk"
                    register={register}
                    translation="handwerkspatenschaftForm.labels.betragprohandwerke1"
                    value="CHF 10.00/Monat | 120.00/Jahr"
                  />
                  <RadioInput
                    id="betragprohandwerk2"
                    name="betragprohandwerk"
                    register={register}
                    translation="handwerkspatenschaftForm.labels.betragprohandwerke2"
                    value="CHF 20.00/Monat | 240.00/Jahr"
                  />
                  <RadioInput
                    id="betragprohandwerk3"
                    name="betragprohandwerk"
                    register={register}
                    translation="handwerkspatenschaftForm.labels.betragprohandwerke3"
                    value="CHF 50.00/Monat | 600.00/Jahr"
                  />
                  <RadioInput
                    id="betragprohandwerk4"
                    name="betragprohandwerk"
                    register={register}
                    translation="handwerkspatenschaftForm.labels.betragprohandwerke4"
                    value="freiwaehlbar"
                  />
                  <TextInput
                    label=""
                    name="amount"
                    fieldDescription="handwerkspatenschaftForm.labels.amountinput"
                    placeholder="handwerkspatenschaftForm.placeholders.amount"
                    register={register}
                    errorMessagePattern="handwerkspatenschaftForm.errors.amountInvalid"
                    hasError={errors.amount !== undefined}
                    validationOptions={validationOptions.amount}
                  />
                </WrapperMemberAmount>
              </FormColumn>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('patenschaftenForm.labels.visibleSponsorTitle')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="visibleSponsorChoice"
                    name="visibleSponsor"
                    register={register}
                    translation="patenschaftenForm.labels.visibleSponsorChoice"
                    value="visibleSponsorChoice"
                  />
                </WrapperMember>
              </FormColumn>
              <DefaultForm
                errors={errors}
                register={register}
                isSubmitted={isSubmitted}
                isBusiness={true}
              />
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('contactForm.labels.yourMessage')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('message')}
                  id="message"
                  placeholder={t('contactForm.placeholders.message')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input type="hidden" value="20-Handwerkspatenschaft" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
