import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { genericSubmitHandler } from 'components/organisms/FormsSlice/Forms.helper';
import Copytext from 'components/molecules/Copytext/Copytext';
import { CheckboxInput } from 'components/atoms/CheckboxInput';
import { DefaultForm } from 'components/molecules/DefaultForm';
import Column from 'components/layout/Grid/Column';
import {
  FormColumn,
  HeadlineLabel,
  InputSubmit,
  InputTextArea,
} from 'components/organisms/FormsSlice/Forms/Forms';
import { PatenschaftenFormInputTypes, FormParentProps } from './PatenschaftenForm.types';
import { toast } from 'react-toastify';
import { Loading } from 'components/atoms/Loading';
import { FormSuccessNotification } from 'components/molecules/FormSuccessNotification';
import { RichText } from 'components/atoms/RichText';
import { isRichTextEmpty } from 'prismic/helpers/isRichTextEmpty';
import { styled } from '@nx-kit/styling';
import { Heading } from 'components/atoms/Heading';
import { RadioInput } from 'components/atoms/RadioInput';
import { DomainContext } from 'contexts/domain/DomainContext';
import getNewsletterURL from 'helpers/forms/getNewsletterURL';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

const WrapperMember = styled.div`
  > * {
    display: flex;
    padding-top: 14px;

    &:first-child {
      padding-top: 0;
    }
  }
`;

export const PatenschaftenForm: React.FC<FormParentProps> = ({
  introRichText,
  titleNotification,
  textNotification,
}) => {
  const { t, lang } = useTranslation('common');
  const [showForm, setShowForm] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<PatenschaftenFormInputTypes>();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const isIntroRichTextEmpty = isRichTextEmpty(introRichText);
  const urlNewsletter = getNewsletterURL(domain, lang);

  const submitHandler = async (data: PatenschaftenFormInputTypes) => {
    try {
      const response = await genericSubmitHandler({ data });
      setShowForm(false);
      if (!response.data.ok) {
        setShowForm(true);
        toast.error(t('forms.responses.400'));
      }
    } catch (error) {
      setShowForm(true);
    }
  };

  const onClickReturn = (): void => {
    reset();
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <Column span={12}>
          <FormColumn span={12}>
            <FormSuccessNotification
              onClickReturn={onClickReturn}
              titleNotification={titleNotification}
              textNotification={textNotification}
            />
          </FormColumn>
        </Column>
      )}
      {showForm && (
        <>
          {!isIntroRichTextEmpty && (
            <FormColumn span={12}>
              <RichText rich_text={introRichText} />
            </FormColumn>
          )}
          <Column span={12}>
            <form onSubmit={handleSubmit(submitHandler)}>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('patenschaftenForm.labels.animalSponsorshipsTitle')}
              </Heading>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('patenschaftenForm.labels.sponsorship10Franken.title')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="goose"
                    name="sponsorship10Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship10Franken.goose"
                    value="Gans"
                  />
                  <RadioInput
                    id="runningDuck"
                    name="sponsorship10Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship10Franken.runningDuck"
                    value="Laufente"
                  />
                </WrapperMember>
              </FormColumn>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('patenschaftenForm.labels.sponsorship20Franken.title')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="chickenFamily"
                    name="sponsorship20Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship20Franken.chickenFamily"
                    value="Hühnerfamilie"
                  />
                  <RadioInput
                    id="rabbitFamily"
                    name="sponsorship20Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship20Franken.rabbitFamily"
                    value="Kaninchenfamilie"
                  />
                </WrapperMember>
              </FormColumn>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('patenschaftenForm.labels.sponsorship25Franken.title')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="goat"
                    name="sponsorship25Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship25Franken.goat"
                    value="Ziege"
                  />
                  <RadioInput
                    id="sheep"
                    name="sponsorship25Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship25Franken.sheep"
                    value="Schaf"
                  />
                </WrapperMember>
              </FormColumn>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('patenschaftenForm.labels.sponsorship35Franken.title')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="domesticPig"
                    name="sponsorship35Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship35Franken.domesticPig"
                    value="Hausschwein"
                  />
                  <RadioInput
                    id="woolPig"
                    name="sponsorship35Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship35Franken.woolPig"
                    value="Wollschwein"
                  />
                </WrapperMember>
              </FormColumn>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('patenschaftenForm.labels.sponsorship75Franken.title')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="donkey"
                    name="sponsorship75Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship75Franken.donkey"
                    value="Esel"
                  />
                  <RadioInput
                    id="mule"
                    name="sponsorship75Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship75Franken.mule"
                    value="Maultier"
                  />
                </WrapperMember>
              </FormColumn>
              <Heading skin={500} elementType="h4" styles={{ fontSize: 16 }}>
                {t('patenschaftenForm.labels.sponsorship100Franken.title')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="cow"
                    name="sponsorship100Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship100Franken.cow"
                    value="Kuh"
                  />
                  <RadioInput
                    id="ox"
                    name="sponsorship100Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship100Franken.ox"
                    value="Ochse"
                  />
                  <RadioInput
                    id="horse"
                    name="sponsorship100Franken"
                    register={register}
                    translation="patenschaftenForm.labels.sponsorship100Franken.horse"
                    value="Pferd"
                  />
                </WrapperMember>
              </FormColumn>
              <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
                {t('patenschaftenForm.labels.visibleSponsorTitle')}
              </Heading>
              <FormColumn span={12}>
                <WrapperMember>
                  <RadioInput
                    id="visibleSponsorChoice"
                    name="visibleSponsor"
                    register={register}
                    translation="patenschaftenForm.labels.visibleSponsorChoice"
                    value="visibleSponsorChoice"
                  />
                </WrapperMember>
              </FormColumn>
              <DefaultForm
                errors={errors}
                register={register}
                isSubmitted={isSubmitted}
                isBusiness={true}
              />
              <FormColumn span={12}>
                <HeadlineLabel htmlFor="message">
                  {t('contactForm.labels.yourMessage')}
                </HeadlineLabel>
                <InputTextArea
                  {...register('message')}
                  id="message"
                  placeholder={t('contactForm.placeholders.message')}
                />
              </FormColumn>
              <FormColumn span={12}>
                <CheckboxInput
                  name="newsletterSubscribe"
                  register={register}
                  translation="contactForm.labels.newsletterSubscribe"
                  url={urlNewsletter}
                />
              </FormColumn>
              <FormColumn span={12}>
                {!isSubmitting && (
                  <InputSubmit
                    disabled={isSubmitting}
                    type="submit"
                    value={t('contactForm.labels.submit')}
                  />
                )}
                {isSubmitting && <Loading />}
              </FormColumn>
              <FormColumn span={12}>
                <Copytext textstring={t('forms.requiredFields')} />
              </FormColumn>
              <input type="hidden" value="08-Patenschaften" {...register('category')} />
            </form>
          </Column>
        </>
      )}
    </>
  );
};
