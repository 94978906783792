import { createElement, Fragment } from 'react';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { styled } from '@nx-kit/styling';
import SLICES_MAP from 'prismic/slices';
import CmsElmtWrapper from 'components/organisms/CmsElmtWrapper/CmsElmtWrapper';

export interface SliceItem {
  slice_type: string;
  items: any;
  primary: any;
}

interface ComponentsRendererProps {
  slices: SliceItem[];
  withoutWrapper?: boolean;
}

interface UndefinedSliceProps {
  sliceName: string;
}

const UndefinedSliceContainer = styled.div`
  max-width: 100vw;
  pre {
    white-space: pre-wrap;
  }
`;

const UndefinedSlice: React.FC<UndefinedSliceProps> = ({ sliceName, ...rest }) => (
  <UndefinedSliceContainer>
    <div style={{ background: 'lightcyan', padding: '35px' }}>
      &quot;{sliceName}&quot; is not defined yet but is added as slice inside prismic. Please add
      the new slice inside SLICES_MAP
    </div>
    <div style={{ background: 'lightcyan', padding: '35px' }}>
      Data: <pre>{JSON.stringify(rest, null, 2)}</pre>
    </div>
  </UndefinedSliceContainer>
);

const ComponentsRenderer: React.FC<ComponentsRendererProps> = ({
  withoutWrapper = false,
  slices,
}) => (
  <>
    {slices.map((slice, idx) => {
      const content = createElement(
        get(SLICES_MAP, slice.slice_type, UndefinedSlice),
        {
          primary: slice.primary,
          items: slice.items,
          sliceIndex: idx,
          sliceName: slice.slice_type,
        },
        null
      );

      if (withoutWrapper) {
        return <Fragment key={uniqueId()}>{content}</Fragment>;
      }
      return (
        <CmsElmtWrapper
          key={uniqueId()}
          id={slice.primary.anchor_id ?? undefined}
          sliceName={slice.slice_type}
          nextSlice={slices[idx + 1]?.slice_type ?? undefined}
        >
          {content}
        </CmsElmtWrapper>
      );
    })}
  </>
);

export default ComponentsRenderer;
