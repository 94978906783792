import { styled } from '@nx-kit/styling';
import Container from 'components/layout/Grid/Container';
import Column from 'components/layout/Grid/Column';
import { RichText } from 'components/atoms/RichText';
import { FactBoxProps } from './FactBox.types';

const Fact = styled(Column)<{ bgColor: 'light' | 'colored' }>`
  border-radius: 8px;
  padding: 35px 40px;
  margin-bottom: 16px;
  background-color: ${({ bgColor, theme }) =>
    bgColor === 'colored' ? theme.global.color.primary : theme.global.color.gray50};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  ul,
  ol,
  li {
    color: ${({ bgColor, theme }) =>
      bgColor === 'colored' ? theme.global.color.white : 'inherit'};
  }
  ul li:before {
    background-color: ${({ bgColor, theme }) =>
      bgColor === 'colored' ? theme.global.color.white : theme.global.color.primary};
  }
`;

export const FactBox: React.FC<FactBoxProps> = ({ className, items }) => {
  const { length } = items;

  const calculateStartPosition = (index: number): number => {
    if (length % 2 === 1 && index + 1 === length) {
      return 3;
    }
    if (index % 2 === 0) {
      return 3;
    } else {
      return 7;
    }
  };

  const calculateElementLength = (index: number): number => {
    if (length % 2 === 1 && index + 1 === length) {
      return 8;
    }
    return 4;
  };

  if (length > 0) {
    return (
      <Container className={className}>
        {items.map((fact, index) => {
          const id = fact.fact_box_content[0].text;

          if (fact.fact_box_content.length > 0 && !undefined) {
            return (
              <Fact
                key={`${id}-${index}`}
                start={{ xs: 1, lg: calculateStartPosition(index) }}
                span={{ xs: 12, lg: calculateElementLength(index) }}
                bgColor={fact.theme}
              >
                <RichText rich_text={fact.fact_box_content} />
              </Fact>
            );
          }
          return null;
        })}
      </Container>
    );
  }
  return null;
};
