import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useLinkResolver } from 'prismic/helpers/useLinkResolver';
import { fetchCourses } from 'prismic/api/courses';
import getHeadingTag from 'helpers/getHeadingTag';
import getHeadingSkin from 'helpers/getHeadingSkin';
import { BreakpointContext } from 'contexts/breakpoint/BreakpointContext';
import { DomainContext } from 'contexts/domain/DomainContext';
import { KursSliderItemProps } from 'components/molecules/KursSliderItem/KursSliderItem.types';
import { mapKursSliderItem } from 'components/molecules/KursSliderItem/KursSliderItem.helper';
import KursSliderItem from 'components/molecules/KursSliderItem/KursSliderItem';
import Slider from 'components/molecules/Slider/Slider';
import Column from 'components/layout/Grid/Column';
import { Loading } from 'components/atoms/Loading';
import Link from 'components/atoms/Link/Link';
import {
  Wrapper,
  HeadlineCol,
  HeadingStyled,
  ColumnLinkStyled,
  StyledMobileLink,
} from './KurseSammlung.styles';
import { KurseSammlungProps } from './KurseSammlung.types';

export const KurseSammlung: React.FC<KurseSammlungProps> = ({ className, primary, items }) => {
  const { t } = useTranslation('common');
  const { locale } = useLinkResolver();
  const bp = useContext(BreakpointContext);
  const isMobile = bp === 'xs' || bp === 'sm';
  const {
    state: { domain },
  } = useContext(DomainContext);

  const { title, link = null, link_text = '' } = primary;
  const hasHeadline = title?.[0]?.text;

  let sliderItems: KursSliderItemProps[] = [];

  const fetchQuery = async () => {
    let queryArray: string[] = [];
    let queryString = '';

    items.forEach((cmsItem) => {
      queryArray.push(`anlassnr=${cmsItem.anlassnr}`);
    });

    queryString = '?' + queryArray.join('&');
    return await fetchCourses({ domain, query: queryString });
  };

  const { data, error, isLoading } = useQuery(['kurseHome'], fetchQuery);

  const getError = (error: unknown): string => {
    console.error('[KurseSammlung]', error);
    return t('apiErrors.errorDefaultMessage');
  };

  if (data?.data) {
    data.data.forEach((kursItem) => {
      const item = mapKursSliderItem(locale, kursItem);
      sliderItems.push(item);
    });
  }

  if (sliderItems.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <Wrapper>
        {hasHeadline && (
          <HeadlineCol span={12}>
            {title?.[0]?.text && (
              <HeadingStyled
                skin={getHeadingSkin(title[0].type)}
                elementType={getHeadingTag(title[0].type)}
              >
                {title[0]?.text}
              </HeadingStyled>
            )}
            {link && !isMobile && (
              <Link skin="tertiaryBig" url={link}>
                {link_text || link.url}
              </Link>
            )}
          </HeadlineCol>
        )}
        <Column span={12}>
          {error && <div>{getError(error)}</div>}
          {isLoading && <Loading />}

          <Slider
            slidesPerView={{
              xs: 1.05,
              sm: 1.5,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
              xxxl: 3,
            }}
            showOverflow
          >
            {sliderItems.map((item, index) => (
              <KursSliderItem key={`kurs-item-${index}`} data={item} />
            ))}
          </Slider>
        </Column>
        <ColumnLinkStyled span={12}>
          {link && isMobile && (
            <StyledMobileLink skin="tertiaryBig" url={link}>
              {link_text || link.url}
            </StyledMobileLink>
          )}
        </ColumnLinkStyled>
      </Wrapper>
    </div>
  );
};
