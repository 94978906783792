export interface GenericSubmitHandlerProps {
  data: any;
}
export interface FetchSendFormResponse {
  data: any;
}

export const newsletterSubmitHandler = async ({
  data,
}: GenericSubmitHandlerProps): Promise<FetchSendFormResponse> => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    const response = await fetch('/api/mailchimp', options);
    return {
      data: response,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
