import useTranslation from 'next-translate/useTranslation';
import { FormColumn, FormError } from 'components/organisms/FormsSlice/Forms/Forms';
import { validationOptions } from 'components/organisms/FormsSlice/Forms/validationOptions';
import { RadioInput } from 'components/atoms/RadioInput';
import { TextInput } from 'components/atoms/TextInput';
import { Heading } from 'components/atoms/Heading';
import { DefaultFormProps } from './DefaultForm.types';
import { Wrapper } from './DefaultForm.styles';
import { SalutationEnum } from 'helpers/forms/forms.types';
import getHeadingSkin from 'helpers/getHeadingSkin';
import getHeadingTag from 'helpers/getHeadingTag';

export const DefaultForm: React.FC<DefaultFormProps> = ({
  errors,
  register,
  alternativHeadline,
  isSubmitted,
  isBusiness,
  phoneLabel,
}) => {
  const { t, lang } = useTranslation('common');
  const phoneLabelNew = phoneLabel ? phoneLabel : 'contactForm.labels.phone';

  return (
    <>
      <FormColumn span={12}>
        <Heading skin={getHeadingSkin('heading3')} elementType={getHeadingTag('heading3')}>
          {alternativHeadline ? alternativHeadline : t('contactForm.labels.yourInformation')}
        </Heading>
        <Wrapper>
          <RadioInput
            id="salutationFemale"
            name="salutation"
            register={register}
            translation="contactForm.labels.salutation.female"
            value={SalutationEnum.female}
          />
          <RadioInput
            id="salutationMale"
            name="salutation"
            register={register}
            translation="contactForm.labels.salutation.male"
            value={SalutationEnum.male}
            validationOptions={validationOptions.salutation}
          />
        </Wrapper>
        {errors.salutation && isSubmitted && (
          <FormError className="salutationError">
            {t('contactForm.errors.salutationRequired')}
          </FormError>
        )}
      </FormColumn>

      <FormColumn span={12}>
        <TextInput
          errorMessageRequired="contactForm.errors.firstNameRequired"
          hasError={errors.firstName !== undefined}
          label="contactForm.labels.firstName"
          name="firstName"
          placeholder="contactForm.placeholders.firstName"
          register={register}
          validationOptions={validationOptions.firstName}
        />
      </FormColumn>

      <FormColumn span={12}>
        <TextInput
          errorMessageRequired="contactForm.errors.lastNameRequired"
          hasError={errors.lastName !== undefined}
          label="contactForm.labels.lastName"
          name="lastName"
          placeholder="contactForm.placeholders.lastName"
          register={register}
          validationOptions={validationOptions.lastName}
        />
      </FormColumn>

      {isBusiness && (
        <FormColumn span={12}>
          <TextInput
            label="contactForm.labels.company"
            name="company"
            placeholder="contactForm.placeholders.company"
            register={register}
          />
        </FormColumn>
      )}

      <FormColumn span={12}>
        <TextInput
          errorMessageRequired="contactForm.errors.streetRequired"
          hasError={errors.street !== undefined}
          label="contactForm.labels.street"
          name="street"
          placeholder="contactForm.placeholders.street"
          register={register}
          validationOptions={validationOptions.street}
        />
      </FormColumn>

      <FormColumn span={12}>
        <TextInput
          errorMessageRequired="contactForm.errors.zipCodeRequired"
          hasError={errors.zipCode !== undefined}
          label="contactForm.labels.zipCode"
          name="zipCode"
          placeholder="contactForm.placeholders.zipCode"
          register={register}
          validationOptions={validationOptions.zipCode}
        />
      </FormColumn>

      <FormColumn span={12}>
        <TextInput
          errorMessageRequired="contactForm.errors.cityRequired"
          hasError={errors.city !== undefined}
          label="contactForm.labels.city"
          name="city"
          placeholder="contactForm.placeholders.city"
          register={register}
          validationOptions={validationOptions.city}
        />
      </FormColumn>

      <FormColumn span={12}>
        <TextInput
          errorMessageRequired="contactForm.errors.countryRequired"
          hasError={errors.country !== undefined}
          label="contactForm.labels.country"
          name="country"
          placeholder="contactForm.placeholders.country"
          register={register}
          validationOptions={validationOptions.country}
        />
      </FormColumn>

      <FormColumn span={12}>
        <TextInput
          label={phoneLabelNew}
          name="phone"
          placeholder="contactForm.placeholders.phone"
          register={register}
          hasError={errors.phone !== undefined}
          errorMessageRequired="contactForm.errors.phoneRequired"
        />
      </FormColumn>

      <FormColumn span={12}>
        <TextInput
          errorMessagePattern="contactForm.errors.eMailInvalid"
          errorMessageRequired="contactForm.errors.eMailRequired"
          hasError={errors.eMail !== undefined}
          label="contactForm.labels.eMail"
          name="eMail"
          placeholder="contactForm.placeholders.eMail"
          register={register}
          validationOptions={validationOptions.eMail}
        />
      </FormColumn>
      <input type="hidden" value={lang} {...register('locale')} />
    </>
  );
};
