import { useContext } from 'react';
import { media, styled } from '@nx-kit/styling';
import { useQuery } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { DomainContext } from 'contexts/domain/DomainContext';
import getHeadingTag from 'helpers/getHeadingTag';
import { formatDateForQuery } from 'helpers/date';
import { useLinkResolver } from 'prismic/helpers/useLinkResolver';
import { BreakpointContext } from 'contexts/breakpoint/BreakpointContext';
import TagesplanSliderItem from 'components/molecules/TagesplanSliderItem/TagesplanSliderItem';
import { mapEdgeItemToTagesplanBoxItem } from 'components/molecules/TagesplanSliderItem/TagesplanSliderItem.helper';
import { TagesplanSliderItemProps } from 'components/molecules/TagesplanSliderItem/TagesplanSliderItem.types';
import Container from 'components/layout/Grid/Container';
import Slider from 'components/molecules/Slider/Slider';
import { fetchTagesplan } from 'prismic/api/tagesplan';
import Column from 'components/layout/Grid/Column';
import { Heading } from 'components/atoms/Heading';
import { Loading } from 'components/atoms/Loading';
import Link from 'components/atoms/Link/Link';
import { TagesplanSliderProps } from './TagesplanSlider.types';
import getHeadingSkin from 'helpers/getHeadingSkin';

const HeadlineCol = styled(Column)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px;

  ${media('md')} {
    margin: 0 0 20px;
  }
`;

const HeadingStyled = styled(Heading)`
  margin: 0;
`;

const ColumnLinkStyled = styled(Column)`
  margin-top: 15px;
`;

const StyledMobileLink = styled(Link)`
  font-size: ${({ theme }) => theme.global.fontSize.medium}px;
`;

const Wrapper = styled(Container)`
  overflow: hidden;
`;

export const TagesplanSlider = ({ className, primary }: TagesplanSliderProps) => {
  const { t } = useTranslation('common');

  const { headline = null, link = null, link_text = '' } = primary;
  const hasHeadline = headline?.[0]?.text || link;

  const bp = useContext(BreakpointContext);
  const isMobile = bp === 'xs' || bp === 'sm';

  const { locale } = useLinkResolver();
  const {
    state: { domain },
  } = useContext(DomainContext);

  const items: TagesplanSliderItemProps[] = [];

  const fetchTagesplanQuery = async () => {
    const today = formatDateForQuery(new Date());
    const query = `?date=${today}&locale=${locale}`;
    return fetchTagesplan({ domain, query });
  };

  const { data, error, isLoading } = useQuery(['tagesplan'], fetchTagesplanQuery);
  const getError = (error: unknown): string => {
    console.error('[TagesplanSlider]', error);
    return t('apiErrors.errorDefaultMessage');
  };

  if (data?.data?.items) {
    data.data.items.slice(0, 9).forEach((edgeItem) => {
      const item = mapEdgeItemToTagesplanBoxItem(locale, edgeItem);
      items.push(item);
    });
  }

  if (!isLoading && !error && items.length === 0) {
    return <></>;
  }

  return (
    <Wrapper className={className}>
      {hasHeadline && (
        <HeadlineCol span={12}>
          {headline?.[0]?.text && (
            <HeadingStyled
              skin={getHeadingSkin(headline[0].type)}
              elementType={getHeadingTag(headline[0].type)}
            >
              {headline[0].text}
            </HeadingStyled>
          )}
          {link && !isMobile && (
            <Link skin="tertiaryBig" url={link}>
              {link_text || link.url}
            </Link>
          )}
        </HeadlineCol>
      )}
      <Column span={12}>
        {error && <div>{getError(error)}</div>}
        {isLoading && <Loading />}

        <Slider
          slidesPerView={{
            xs: 1.05,
            sm: 1.5,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 4,
            xxxl: 4,
          }}
          showOverflow
        >
          {items.map((item, index) => (
            <TagesplanSliderItem
              key={`schedule-slider-item-${index}`}
              link={link}
              image={item.image}
              text={item.text}
              subtext={item.subtext}
            />
          ))}
        </Slider>
      </Column>
      <ColumnLinkStyled span={12}>
        {link && isMobile && (
          <StyledMobileLink skin="tertiaryBig" url={link}>
            {link_text || link.url}
          </StyledMobileLink>
        )}
      </ColumnLinkStyled>
    </Wrapper>
  );
};
