import { DomainContext } from 'contexts/domain/DomainContext';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import ComponentsRenderer from 'components/templates/partials/ComponentsRenderer';
import { FooterProps } from './Footer.types';
import { ComposedLink } from 'components/atoms/Link/Link';
import {
  FooterRowStyled,
  LinkStyled,
  Logo1Styled,
  Logo2Styled,
  Logo3Styled,
  SecondaryLinksBlockStyled,
  SecondRowStyled,
  Wrapper,
} from './Footer.styles';
import { useContext } from 'react';

export const Footer: React.FC<FooterProps> = ({ className, data }) => {
  const { t } = useTranslation('common');
  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  const linkImpressum = {
    url: t('footerLinks.impressum.url'),
    link_type: 'Web',
    label: t('footerLinks.impressum.label'),
  };

  const linkAgb = {
    url: t('footerLinks.agb.url'),
    link_type: 'Web',
    label: t('footerLinks.agb.label'),
  };

  const linkConditions = {
    url: t('footerLinks.conditions.url'),
    link_type: 'Web',
    label: t('footerLinks.conditions.label'),
  };

  const linkPrivacy = {
    url: t('footerLinks.dataPrivacy.url'),
    link_type: 'Web',
    label: t('footerLinks.dataPrivacy.label'),
  };

  const swissTainableLink = {
    url: t('swisstainable.url'),
  };

  const links = isCourse
    ? [linkImpressum, linkConditions, linkPrivacy]
    : [linkImpressum, linkAgb, linkConditions, linkPrivacy];

  return (
    <Wrapper className={className}>
      <FooterRowStyled>
        <ComponentsRenderer withoutWrapper slices={data} />
      </FooterRowStyled>
      <SecondRowStyled>
        <SecondaryLinksBlockStyled>
          {links.map((item, index) => (
            <LinkStyled key={`footer-links-${index}`} text={item.label} url={item} />
          ))}
        </SecondaryLinksBlockStyled>
        {!isCourse && (
          <>
            <Logo3Styled>
              <ComposedLink href={swissTainableLink.url} isTargetBlank>
                <Image
                  alt="Partner-BAK-Logo"
                  width={70}
                  height={70}
                  src="/footer/swisstainable.svg"
                />
              </ComposedLink>
            </Logo3Styled>
            <Logo1Styled>
              <Image
                alt="Partner-BAK-Logo"
                width={188}
                height={70}
                src="/footer/bak_logo_neg.svg"
              />
            </Logo1Styled>
            <Logo2Styled>
              <Image
                alt="Kanton-Bern-logo"
                width={160}
                height={72}
                src="/footer/kantonbern_logo_neg.svg"
              />
            </Logo2Styled>
          </>
        )}
      </SecondRowStyled>
    </Wrapper>
  );
};
