import { media, styled } from '@nx-kit/styling';
import { teaserDecoration } from 'themes/components/teaser';
import Column from 'components/layout/Grid/Column';
import { Heading } from 'components/atoms/Heading';
import Link from 'components/atoms/Link/Link';

export const ColumnStyled = styled(Column)`
  ${media('xs', 'sm')} {
    & + & {
      margin-top: 30px;
    }
  }
`;

export const TeaserBoxStyled = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
  height: 100%;
  ${teaserDecoration};

  ${media('md')} {
    flex-direction: row;
    gap: 20px;
    > div {
      flex: 50%;
    }
  }
`;

export const HeadingStyled = styled(Heading)`
  margin-bottom: 13px;
`;

export const ImageStyled = styled.div`
  width: 100%;
  height: 201px;
  margin-bottom: 20px;
  & img {
    border-radius: 6px;
  }

  ${media('md')} {
    width: calc(50% - 10px);
    height: 287px;
    margin-bottom: 0;
  }
`;

export const LinkStyled = styled(Link)`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${media('md')} {
    margin-top: 30px;
  }
`;

export const OptionalLink = styled(LinkStyled)`
  padding: 0;
  color: ${({ theme }) => theme.global.color.primary};
`;
