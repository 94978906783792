import { RegisterOptions } from 'react-hook-form/dist/types/validator';

export const validationOptions: { [key: string]: RegisterOptions } = {
  amount: {
    pattern: RegExp('^[0-9]+(\\.[0-9]{1,2})?$'),
  },
  salutation: { required: true },
  firstName: { required: true },
  lastName: { required: true },
  street: { required: true },
  zipCode: { required: true },
  city: { required: true },
  country: { required: true },
  phone: { required: true },
  eMail: {
    required: true,
    pattern: RegExp('^(.+)@(.+)$'),
  },
  nameSchool: { required: true },
  nameGroup: { required: true },
  groupStreet: { required: true },
  schoolStreet: { required: true },
  schoolZipCode: { required: true },
  schoolCity: { required: true },
  groupTyp: { required: true },
  groupZipCode: { required: true },
  groupCity: { required: true },
  reasonVisit: { required: true },
  visitDate: { required: true },
  visitTime: { required: true },
  adult: { required: true },
  desiredLocation: { required: true },
  classAge: { required: true },
  arrivalPlace: { required: true },
  children: { required: true },
  adultMemberPlus: { required: true },
  childOver6: { required: true },
  childUnder6: { required: true },
  railAway: { required: true },
  voucherAmount: {
    required: true,
    pattern: RegExp('^[0-9]+(\\.[0-9]{1,2})?$'),
  },
  voucherStreet: { required: true },
  voucherZipCode: { required: true },
  voucherCity: { required: true },
  numberMuseumPlan: {
    pattern: RegExp('^[0-9]+$'),
  },
  numberImageBrochure: {
    pattern: RegExp('^[0-9]+$'),
  },
  anredeIdCourseRegistration: { required: true },
  firstNameCourseRegistration: {
    required: true,
    maxLength: 20,
  },
  lastNameCourseRegistration: {
    required: true,
    maxLength: 60,
  },
  streetCourseRegistration: {
    required: true,
    maxLength: 60,
  },
  zipCodeCourseRegistration: {
    required: true,
    maxLength: 30,
  },
  cityCourseRegistration: {
    required: true,
    maxLength: 30,
  },
  landIdCourseRegistration: { required: true },
  association: { required: true },
  member: { required: true },
  usage: { required: true },
  meals: { required: true },
  patronage: { required: true },
  probate: { required: true },
  giftVoucher: { required: true },
  agb: { required: true },
  cancellationInsurance: { required: true },
};
