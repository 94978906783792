import useTranslation from 'next-translate/useTranslation';
import { isValidLink } from 'prismic/helpers/link';
import getHeadingTag from 'helpers/getHeadingTag';
import getHeadingSkin from 'helpers/getHeadingSkin';
import Container from 'components/layout/Grid/Container';
import Picture from 'components/atoms/Picture/Picture';
import { RichText } from 'components/atoms/RichText';
import { CompactTeaserProps } from './CompactTeaser.types';
import {
  ColumnStyled,
  TeaserBoxStyled,
  HeadingStyled,
  LinkStyled,
  OptionalLink,
  ImageStyled,
} from './CompactTeaser.styles';

export const CompactTeaser: React.FC<CompactTeaserProps> = ({ className, primary, sliceName }) => {
  const isOneBox = primary.number_columns === '1';
  const { t } = useTranslation('common');
  const linkLabel_box_1 = primary.link_text_box_1 ? primary.link_text_box_1 : t('links.learnMore');
  const linkLabel_box_2 = primary.link_text_box_2 ? primary.link_text_box_2 : t('links.learnMore');

  return (
    <Container className={className} data-slice-name={sliceName}>
      <ColumnStyled start={{ xs: 1, lg: 3 }} span={{ xs: 12, lg: isOneBox ? 8 : 4 }}>
        <TeaserBoxStyled>
          <div>
            <HeadingStyled
              skin={getHeadingSkin('heading3')}
              elementType={getHeadingTag('heading3')}
            >
              {primary.title_box_1}
            </HeadingStyled>
            <RichText rich_text={primary.content_box_1} />
            {isValidLink(primary.link_box_1) && (
              <LinkStyled skin="primary" url={primary.link_box_1} text={linkLabel_box_1} />
            )}
            {isValidLink(primary.optional_link_1) && (
              <OptionalLink
                skin="navLevel3"
                url={primary.optional_link_1}
                text={primary.optional_link_text_1 || primary.optional_link_1.url}
              />
            )}
          </div>
          {isOneBox && primary.image_box_1.dimensions && (
            <ImageStyled>
              <Picture noMinHeight image={primary.image_box_1} objectFit></Picture>
            </ImageStyled>
          )}
        </TeaserBoxStyled>
      </ColumnStyled>

      {!isOneBox && (
        <ColumnStyled span={{ xs: 12, lg: 4 }}>
          <TeaserBoxStyled>
            <div>
              <HeadingStyled
                skin={getHeadingSkin('heading3')}
                elementType={getHeadingTag('heading3')}
              >
                {primary.title_box_2}
              </HeadingStyled>
              <RichText rich_text={primary.content_box_2} />
              {isValidLink(primary.link_box_2) && (
                <LinkStyled skin="primary" url={primary.link_box_2} text={linkLabel_box_2} />
              )}
              {isValidLink(primary.optional_link_2) && (
                <OptionalLink
                  skin="navLevel3"
                  url={primary.optional_link_2}
                  text={primary.optional_link_text_2 || primary.optional_link_2.url}
                />
              )}
            </div>
          </TeaserBoxStyled>
        </ColumnStyled>
      )}
    </Container>
  );
};
