import uniqueId from 'lodash/uniqueId';
import { styled, media } from '@nx-kit/styling';
import { RichText } from 'components/atoms/RichText';
import Link from 'components/atoms/Link/Link';
import { FooterContactProps } from 'components/organisms/Footer/Footer.types';

const LinkStyled = styled(Link)`
  color: ${(props) => props.theme.global.color.white};
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media('md')} {
    width: 308px;
  }

  div + ${LinkStyled} {
    margin-top: 20px;
    ${media('sm')} {
      margin-top: 10px;
    }
  }
`;

const RichTextStyled = styled(RichText)`
  color: ${(props) => props.theme.global.color.white};

  a:link,
  a:visited,
  a:hover {
    color: ${(props) => props.theme.global.color.white};
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const FooterContact: React.FC<FooterContactProps> = ({ primary, items }) => {
  return (
    <Wrapper>
      <RichTextStyled rich_text={primary.addres} />
      {items &&
        items.map((item) => (
          <LinkStyled key={`footer-links_${uniqueId()}`} text={item['contact-link-label']} />
        ))}
    </Wrapper>
  );
};
