import getHeadingTag from 'helpers/getHeadingTag';
import getHeadingSkin from 'helpers/getHeadingSkin';
import Accordion, { AccordionItem } from 'components/molecules/Accordion/Accordion';
import Container from 'components/layout/Grid/Container';
import { RichText } from 'components/atoms/RichText';
import { Heading } from 'components/atoms/Heading';
import Column from 'components/layout/Grid/Column';
import Link from 'components/atoms/Link/Link';
import { AccordionSliceProps } from './AccordionSlice.types';
import { AccordionColumn, AccordionContentContainer, TitleBox } from './AccordionSlice.styles';

export const AccordionSlice: React.FC<AccordionSliceProps> = ({ className, primary, items }) => {
  const { alignment, headline, link, link_text } = primary;

  return (
    <Container className={className}>
      <TitleBox
        start={alignment === 'right' ? 1 : { xs: 1, md: 9 }}
        span={{ xs: 12, md: 4 }}
        row={1}
      >
        {headline.length > 0 && (
          <Heading
            skin={getHeadingSkin(headline?.[0]?.type)}
            elementType={getHeadingTag(headline?.[0]?.type ?? 'h1')}
          >
            {headline?.[0]?.text ?? ''}
          </Heading>
        )}
        {(link.url || link.link_type === 'Document') && (
          <Link skin="tertiaryBig" url={link}>
            {link_text || link.url}
          </Link>
        )}
      </TitleBox>
      <AccordionColumn
        start={alignment === 'right' ? { xs: 1, md: 3 } : 1}
        span={{ xs: 12, md: 10 }}
        row={{ xs: 2, md: 1 }}
      >
        <AccordionContentContainer>
          <Column start={alignment === 'right' ? { xs: 1, md: 4 } : 1} span={{ xs: 12, md: 9 }}>
            <Accordion>
              {items.map((item) => (
                <AccordionItem key={item.accordion_headline} title={item.accordion_headline}>
                  <RichText rich_text={item.accordion_content} />
                </AccordionItem>
              ))}
            </Accordion>
          </Column>
        </AccordionContentContainer>
      </AccordionColumn>
    </Container>
  );
};
