import { media, styled } from '@nx-kit/styling';
import { primary } from 'themes/components/cta';
import Column from 'components/layout/Grid/Column';

export const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.global.color.gray50};
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
`;

export const StyledColumn = styled(Column)`
  background-color: ${({ theme }) => theme.global.color.white};
  border-radius: 8px;
  padding: 50px 60px;
  &.kursanmeldung {
    background-color: ${({ theme }) => theme.global.color.gray50};
    padding: 0;
  }
`;

export const FormColumn = styled(Column)`
  margin-bottom: 30px;
`;

export const HeadlineLabel = styled.label`
  ${({ theme }) => theme.component.heading.skin[500]}
`;

export const StyledLabel = styled.label`
  display: block;
  font-weight: bold;
  font-family: ${({ theme }) => theme.global.font.brandRegular};
  font-size: ${({ theme }) => theme.global.fontSize.small};
`;

export const InputText = styled.input<{ hasError?: boolean }>`
  display: block;
  width: 100%;
  padding: 16px 20px;
  margin: 6px 0;
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.global.color.primary : theme.global.color.gray500)};
  border-radius: 4px;
  ${({ theme }) => theme.global.font.brandRegular};

  &::placeholder {
    color: ${({ theme }) => theme.global.color.gray180};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.global.color.gray900};
    transition: border-color 0.6s ease;
  }
`;

export const InputRadio = styled.input`
  margin-right: 10px;
`;

export const InputRadioLabel = styled.label`
  margin-right: 25px;
`;

export const InputSubmit = styled.input`
  border: none;
  ${primary}
  &:disabled {
    background: ${({ theme }) => theme.global.color.gray500};
  }
  width: 100%;
  display: inline-block;
  text-align: center;
  ${media('md')} {
    width: auto;
  }
`;

export const InputTextArea = styled.textarea<{ hasError?: boolean }>`
  display: block;
  width: 100%;
  padding: 16px 20px;
  margin: 6px 0;
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.global.color.primary : theme.global.color.gray500)};
  border-radius: 4px;
  ${({ theme }) => theme.global.font.brandRegular};

  &::placeholder {
    color: ${({ theme }) => theme.global.color.gray180};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.global.color.gray900};
    transition: border-color 0.6s ease;
  }

  min-height: 82px;
  ${media('sm')} {
    min-height: auto;
  }
`;

export const FormError = styled.p`
  color: ${({ theme }) => theme.global.color.primary};
  font-family: ${({ theme }) => theme.global.font.brandRegular};
  font-size: ${({ theme }) => theme.global.fontSize.small};
`;
