// Replace this by functions of the installed package 'date-fns';
import { format, isAfter, isSameDay } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import frLocale from 'date-fns/locale/fr-CH';
import itLocale from 'date-fns/locale/it-CH';
import enLocale from 'date-fns/locale/en-GB';

export const formatDateForQuery = (date: Date): string => {
  const format = 'yyyy-mm-dd';
  const mm = date.getMonth() + 1; // Months start at 0!
  const dd = date.getDate();

  const day = dd < 10 ? `0${dd}` : String(dd);
  const month = mm < 10 ? `0${mm}` : String(mm);

  return format.replace('mm', month).replace('yyyy', String(date.getFullYear())).replace('dd', day);
};

export const formatDateToDisplay = (date: Date, displayDateFormat = 'dd.MM.yyyy'): string => {
  return format(date, displayDateFormat);
};

// Replace this by functions of the installed package 'date-fns';
export const isValidStringDate = (date: string): boolean => {
  // 2022-07-26 -> true
  // 19.07.2022 -> false
  return !!Date.parse(date);
};

export const formatToPageHeadline = (date: string, locale = 'de-CH'): string => {
  try {
    const d = new Date(date);
    return format(d, 'P', { locale: getDateFnsLocale(locale) });
  } catch (err) {
    console.error(err);
  }

  return '-';
};

export const isDateInRange = ({
  target,
  start,
  end,
}: {
  target: Date;
  start?: Date;
  end?: Date;
}): boolean => {
  let isInRange = true;

  if (start && end && start === end) {
    return isSameDay(target, start);
  }

  if (start) {
    if (!isAfter(target, start) && !isSameDay(target, start)) {
      isInRange = false;
    }
  }
  if (end) {
    if (!isAfter(end, target) && !isSameDay(target, end)) {
      isInRange = false;
    }
  }
  return isInRange;
};

export const formatPrismicToISO = (prismicTimestamp: string) => {
  // prismicTimestamp: '2022-09-01T10:00:00+0000'
  return new Date(prismicTimestamp).toISOString();
};

export function formatDateWithUtc(date: Date, formatStr: string, utcOffsetHrs: number) {
  const baseTzOffset = utcOffsetHrs * 60;
  const tzOffset = date.getTimezoneOffset();
  const d = new Date(date.valueOf() + (baseTzOffset + tzOffset) * 60 * 1000);
  return format(d, formatStr);
}

function getDateFnsLocale(locale: string) {
  switch (locale) {
    case 'de-ch':
      return deLocale;
    case 'fr-ch':
      return frLocale;
    case 'it-ch':
      return itLocale;
    default:
      return enLocale;
  }
}

export function getFormattedDateAndTime(
  timestamp: number | string | Date,
  locale: string,
  condition: boolean
) {
  if (condition) {
    try {
      const date = new Date(timestamp);
      const dateStr = format(date, 'PPP', { locale: getDateFnsLocale(locale) });
      const timeStr = format(date, 'kk:mm');

      return { date, dateStr, timeStr };
    } catch (err) {
      console.error(err);
    }
  }

  return { date: undefined, dateStr: '-', timeStr: '--:--' };
}
