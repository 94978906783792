import { LoadingProps } from './Loading.types';
import { Wrapper, ImageStyled } from './Loading.styles';

export const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <ImageStyled src="/loader/ballenberg_loader_75px.png" className={className} />
    </Wrapper>
  );
};
