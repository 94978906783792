import useTranslation from 'next-translate/useTranslation';
import { CheckboxInputProps } from './CheckboxInput.types';
import { SyntheticEvent, useState } from 'react';
import { InputCheckbox, StyledLink, Wrapper } from './CheckboxInput.styles';
import { FormError } from 'components/organisms/FormsSlice/Forms/Forms';
import { CheckBoxLinkTypEnum } from 'helpers/forms/forms.types';

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  name,
  register,
  translation,
  validationOptions,
  errorMessageRequired,
  hasError,
  url,
  linkTyp,
}) => {
  const { t } = useTranslation('common');
  const [checked, setChecked] = useState(false);

  const translationArray = t(translation).split('{0}');
  const linkTitle =
    linkTyp === CheckBoxLinkTypEnum.agb
      ? 'agb.checkboxTitleReplace'
      : 'newsletter.checkboxTitleReplace';
  const linkText =
    linkTyp === CheckBoxLinkTypEnum.agb
      ? 'agb.checkboxTextReplace'
      : 'newsletter.checkboxTextReplace';

  return (
    <>
      <Wrapper>
        <InputCheckbox
          {...register(name, validationOptions)}
          id={name}
          name={name}
          aria-label="checkbox"
          isChecked={checked}
          onChange={(event: SyntheticEvent<HTMLInputElement>) => {
            setChecked(event.currentTarget.checked);
          }}
        />
        {!url && <label htmlFor={name}>{t(translation)}</label>}
        {url && (
          <>
            <label htmlFor={name}>
              {translationArray[0]}
              <StyledLink href={url} title={t(linkTitle)} target={'_blank'} rel="noreferrer">
                {t(linkText)}
              </StyledLink>
              {translationArray[1].length > 0 && translationArray[1]}
            </label>
          </>
        )}
      </Wrapper>
      {hasError && errorMessageRequired && <FormError>{t(errorMessageRequired)}</FormError>}
    </>
  );
};
