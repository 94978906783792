import { PrismicMedia } from 'prismic/types';

const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
  if (bytes == 0) {
    return '0 Byte';
  }
  const sizeIndex = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
  return Math.round(bytes / Math.pow(1024, sizeIndex)) + ' ' + sizes[sizeIndex];
};

const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getLinkInfo = (mediaItem: PrismicMedia): string => {
  const fileExtension = mediaItem.name ? mediaItem.name.split('.').pop() : '';
  const fileExtensionFormated = fileExtension ? capitalize(fileExtension) : '';

  const fileSizeFormated = mediaItem.size ? bytesToSize(parseInt(mediaItem.size, 10)) : '';

  if (fileSizeFormated && fileSizeFormated) {
    return `${fileExtensionFormated}, ${fileSizeFormated}`;
  }
  return '';
};
