import { media, styled } from '@nx-kit/styling';
import Container from 'components/layout/Grid/Container';
import { Heading } from 'components/atoms/Heading';
import Column from 'components/layout/Grid/Column';
import Link from 'components/atoms/Link/Link';

export const Wrapper = styled(Container)`
  overflow: hidden;
  margin-bottom: -30px;
  padding-bottom: 30px;
`;

export const HeadlineCol = styled(Column)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeadingStyled = styled(Heading)`
  margin: 0 0 20px;
  ${media('md')} {
    margin: 0 0 40px;
  }
`;

export const ColumnLinkStyled = styled(Column)`
  margin-top: 15px;
`;

export const StyledMobileLink = styled(Link)`
  font-size: ${({ theme }) => theme.global.fontSize.medium}px;
`;
